import { SidebarButton } from "@/components/SidebarButton.tsx";
import { CreativeOsHeader } from "@/components/custom-components/Header";
import { AppSidebar } from "@/components/custom-components/Sidebar";
import { SidebarInset, SidebarProvider } from "@/components/shadcn/sidebar";
import { ManageAccountV2 } from "@/components/templates/AppHeader/index.tsx";
import { useOnClickOutside } from "@/hooks/useClickOutside.tsx";
import { Outlet, createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect, useRef } from "react";
import { z } from "zod";
import { orderFilter } from "../../../../src/shared/airtable";

type LibraryProps = {
  sideBarOpen?: boolean | undefined;
  sidebarCollapsed?: boolean | undefined;
  orderFilter?: z.infer<typeof orderFilter> | undefined;
  cacheBuster?: number | undefined;
};

export const Route = createFileRoute("/share")({
  component: Share,
  validateSearch: (search: Record<string, unknown>): LibraryProps => {
    return {
      sideBarOpen: search.sideBarOpen as boolean | undefined,
      sidebarCollapsed: search.sidebarCollapsed as boolean | undefined,
      orderFilter: search.orderFilter as
        | z.infer<typeof orderFilter>
        | undefined,
      cacheBuster: search.cacheBuster as number | undefined,
    };
  },
});

function Share() {
  const { sideBarOpen } = Route.useSearch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const handleClickOutside = () => {
    if (sideBarOpen) {
      navigate({
        search: (old) => {
          return {
            ...old,
            sideBarOpen: false,
          };
        },
        replace: true,
        params: (old) => {
          return {
            ...old,
          };
        },
      });
    }
  };
  useOnClickOutside(ref, handleClickOutside);

  useEffect(() => {
    if (!sideBarOpen) {
      navigate({
        search: (old) => {
          return {
            ...old,
            sidebarCollapsed: false,
          };
        },
        replace: true,
        params: (old) => {
          return {
            ...old,
          };
        },
      });
    }
  }, [sideBarOpen, navigate]);

  return (
    <>
      <SidebarProvider>
        <AppSidebar />
        <SidebarInset>
          <header className="px-3 lg:px-5 sticky top-0  z-10  flex flex-col py-5 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-auto bg-background">
            <div className="lg:hidden flex w-full h-12 bg-themebackground dark:bg-themebackgrounddark items-center justify-between">
              <>
                <img
                  alt={"CreativeOS"}
                  src={"/images/logo-full-dark_mode.png"}
                  className={"h-5 dark:block hidden"}
                />
                <img
                  alt={"CreativeOS"}
                  src={"/images/logo-full-light_mode.png"}
                  className={"h-5 dark:hidden block"}
                />
              </>
              <div className="items-center flex gap-3">
                <ManageAccountV2 showName={false} />
                <SidebarButton />
              </div>
            </div>
            <CreativeOsHeader />
          </header>
          <div className="px-3 lg:px-5 w-full">
            <Outlet />
          </div>
        </SidebarInset>
      </SidebarProvider>
    </>
  );
}
