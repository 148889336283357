import { Loader } from '@/components/custom-components/Loader';
import { Button } from '@/components/shadcn/button.tsx';
import { DialogTitle } from '@/components/shadcn/dialog.tsx';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/shadcn/form.tsx';
import { Input } from '@/components/shadcn/input.tsx';
import { Label } from '@/components/shadcn/label.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shadcn/select.tsx';
import { Tabs, TabsList, TabsTrigger } from '@/components/shadcn/tabs.tsx';
import useMonitorSubscription from '@/hooks/useMonitorSubscription';
import showToastNotification from '@/hooks/useShowToast.tsx';
import { handleCheckoutStart, handlePurchase } from '@/lib/gtag';
import { cn } from '@/lib/utils.ts';
import countriesJson from '@/utils/data/country-state.json';
import { featureFlagKeys } from '@/utils/data/featureFlags';
import {
  COUPON_DETAILS,
  FREE_PLAN,
  PRO_PLAN,
  PlanDetail,
  STANDARD_PLAN,
  getCurrencySymbol,
} from '@/utils/data/plans.ts';
import { trpc } from '@/utils/trpc.ts';
import {
  CardCVV,
  CardComponent,
  CardExpiry,
  CardNumber,
} from '@chargebee/chargebee-js-react-wrapper';
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CreditCard,
  DangerTriangleSolid,
  Lock,
  XCircle,
} from '@mynaui/icons-react';
import { useNavigate } from '@tanstack/react-router';
import { TRPCError } from '@trpc/server';
import { cva } from 'class-variance-authority';
import { Circle } from 'lucide-react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

export const nextDueDate = new Date(
  new Date().setDate(new Date().getDate() + 3)
).toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export const firstChargeDate = new Date(
  new Date().setDate(new Date().getDate())
).toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

// Interface for a single state
export interface State {
  code: string; // State code (e.g., "BDS", "BGL")
  name: string; // Name of the state
  subdivision: string | string[] | null; // Subdivision, nullable
}

// Interface for a single country
export interface Country {
  code2: string; // Two-letter country code (e.g., "AF")
  code3: string; // Three-letter country code (e.g., "AFG")
  name: string; // Name of the country (e.g., "Afghanistan")
  capital: string; // Capital city (e.g., "Kabul")
  region: string; // Region (e.g., "Asia")
  subregion: string; // Subregion (e.g., "Southern Asia")
  states: State[]; // Array of states
}

// Preprocess data into a lookup map
export const countriesByCode2 = new Map<string, Country>();
countriesJson.forEach((country) => {
  countriesByCode2.set(country.code2, country);
});

// Get states by country code
export const getStatesByCode2 = (code2: string) => {
  const country = countriesByCode2.get(code2);
  return country ? country.states : [];
};

// Get all countries with just `name` and `code2`
export const getAllCountries = () => {
  return Array.from(countriesByCode2.values())
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ name, code2 }) => ({
      name,
      code2,
    }));
};

export const capitalizeCardType = (str: string) =>
  str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());

export const inputStyles = cva(
  'mt-1 flex h-9 w-full rounded-md border border-input bg-transparent px-3 pt-2 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50'
);

export type AppliedPromoDetail = {
  id: string;
  name: string;
  discountType: string;
  discountPercentage?: number;
  status: string;
  period?: number;
  periodUnit?: string;
  discountAmount?: number;
  durationType: string;
  currencyCode?: string;
};

export const CheckoutUpgradeSubscription = ({
  selectedPlan,
  userHasPlan,
}: {
  userHasPlan: boolean;
  selectedPlan: PlanDetail;
}) => {
  const annualFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.annualPlans);

  const selectedPlanIsPro =
    selectedPlan && selectedPlan.plan_id === PRO_PLAN.plan_id;

  const selectedPlanIsFree =
    selectedPlan && selectedPlan.plan_id === FREE_PLAN.plan_id;

  const cardRef = useRef<ChargebeeComponents | null>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [addPromoOpen, setAddPromoOpen] = useState<boolean>(false);

  const [addCardOpen, setAddCardOpen] = useState<boolean>(false);

  const [upgradeSuccessShow, setUpgradeSuccessShow] = useState<boolean>(false);

  const [appliedPromo, setAppliedPromo] = useState<
    AppliedPromoDetail | undefined
  >(undefined);

  const [period, setPeriod] = useState<'monthly' | 'annually'>('monthly');

  const defaultPrice =
    period === 'monthly'
      ? parseInt(selectedPlan?.price.replace('$', ''), 10)
      : parseInt(selectedPlan?.annual_price.replace('$', ''), 10);

  const [dueTodayAmount, setDueTodayAmount] = useState<number>(defaultPrice);

  const navigate = useNavigate();

  const countries = getAllCountries();

  const [checkoutSuccessful, setCheckoutSuccessful] = useState<boolean>(false);

  const { subscriptionSuccessful } = useMonitorSubscription(checkoutSuccessful);

  const { data: userCardDetails, isLoading: isLoadingUserCardDetails } =
    trpc.getUserCardDetails.useQuery(undefined);

  const handlePeriodChange = (newPeriod: 'monthly' | 'annually') => {
    setPeriod(newPeriod);
  };

  useEffect(() => {
    if (selectedPlan) {
      handleCheckoutStart([
        {
          item_id: selectedPlan?.plan_id,
          item_name: selectedPlan?.name,
          price: parseInt(selectedPlan?.price.replace('$', ''), 10),
          quantity: 1,
        },
      ]);
    }
  }, []);

  const checkoutFormSchema = z.object({
    chargebeeToken: z.string({ required_error: 'Chargebee Token is required' }),
    coupon: z.string(),
    plan: z.string({ required_error: 'Plan ID is required' }),
    firstName: z.string({ required_error: 'First name is required' }).min(2),
    lastName: z.string({ required_error: 'Last name is required' }).min(2),
    billingAddress: z.object({
      country:
        !addCardOpen && userCardDetails
          ? z.string().optional()
          : z
              .string({ required_error: 'Country is required' })
              .describe(
                'country in ISO Code https://www.iso.org/iso-3166-country-codes.html'
              )
              .min(2),
      zip:
        !addCardOpen && userCardDetails
          ? z.string().optional()
          : z.string({ required_error: 'Zip code is required' }).min(2),
      city:
        !addCardOpen && userCardDetails
          ? z.string().optional()
          : z.string({ required_error: 'City is required' }).min(2),
      state:
        !addCardOpen && userCardDetails
          ? z.string().optional()
          : z.string({ required_error: 'State/Region is required' }).min(2),
      line1:
        !addCardOpen && userCardDetails
          ? z.string().optional()
          : z.string({ required_error: 'Line 1 is required' }).min(2),
      line2: z.string().optional(),
    }),
  });

  const { mutate: openPortalSessionUrl, isPending: fetchingPortalSessionUrl } =
    trpc.getPortalSessionUrl.useMutation({
      onSuccess: async (data) => {
        if (data) {
          window.location.href = data;
        }
      },
    });

  type CheckoutFormValues = z.infer<typeof checkoutFormSchema>;

  const defaultValues: Partial<CheckoutFormValues> = {
    coupon: '',
    chargebeeToken: '',
    plan: '',
  };

  const form = useForm<CheckoutFormValues>({
    resolver: zodResolver(checkoutFormSchema),
    defaultValues,
  });

  const upgradeUserPlan = async () => {
    const planId =
      selectedPlanIsPro && userHasPlan
        ? period === 'monthly'
          ? PRO_PLAN.plan_id
          : PRO_PLAN.yearly_plan_id
        : !selectedPlanIsPro && userHasPlan
          ? period === 'monthly'
            ? STANDARD_PLAN.plan_id
            : STANDARD_PLAN.yearly_plan_id
          : selectedPlan
            ? selectedPlan.no_trial_plan_id
            : undefined;

    const planToSubmit =
      annualFlagEnabled && planId
        ? [planId]
        : selectedPlanIsPro && userHasPlan
          ? [PRO_PLAN.plan_id]
          : selectedPlan
            ? [selectedPlan.no_trial_plan_id]
            : [];

    try {
      if (isPending) return;
      upgradeUserNewPlan({
        plans: planToSubmit,
        coupons: form.getValues('coupon')
          ? ([form.getValues('coupon')].filter(Boolean) as string[])
          : undefined,
        chargebeeToken: form.getValues('chargebeeToken'),
        billingAddress: form.getValues('billingAddress'),
      });
    } catch (e) {
      setIsSubmitting(false);
      if (e instanceof Error) {
        if (e.message.includes('already present')) {
          toast.error('This account already exists.');
          setTimeout(() => {
            navigate({ to: '/feeds/templates' });
          }, 2000);
        } else {
          toast.error(e.message); // Show the original error for other cases
        }
      } else {
        toast.error('An unknown error occurred');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  // Initiate upgrade subscription based on availability of card
  const subscribeToPlan = () => {
    if (userCardDetails && !addCardOpen) {
      upgradeUserPlan();
    } else if (cardRef.current) {
      cardRef.current
        .tokenize({
          addressLine1: form.getValues('billingAddress.line1'),
          addressLine2: form.getValues('billingAddress.line2'),
          city: form.getValues('billingAddress.city'),
          firstName: form.getValues('firstName'),
          lastName: form.getValues('lastName'),
          state: form.getValues('billingAddress.state'),
          zip: form.getValues('billingAddress.zip'),
          countryCode: form.getValues('billingAddress.country'),
        })
        .then((data: { token: string }) => {
          form.setValue('chargebeeToken', data.token);
        })
        .then(() => {
          setTimeout(upgradeUserPlan, 500);
        })
        .catch((e: TRPCError) => {
          setIsSubmitting(false);
          toast.error(e.message);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      setIsSubmitting(false);
    }
  };

  const updateDueAmount = (data?: COUPON_DETAILS) => {
    const discountAmount = (data?.discountAmount ?? 0) / 100; // Convert to actual value
    const discountPercentage = data?.discountPercentage ?? 0;

    if (proratedDueAmount) {
      setDueTodayAmount(
        proratedDueAmount -
          discountAmount -
          (discountPercentage / 100) * proratedDueAmount
      );
    } else {
      setDueTodayAmount(
        defaultPrice -
          discountAmount -
          (discountPercentage / 100) * defaultPrice
      );
    }
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    if (
      form.getValues('coupon') &&
      form.getValues('coupon')?.length > 0 &&
      !appliedPromo
    ) {
      try {
        // We test the coupon first to confirm it works before procedding to create subscription
        return refetchCoupon({ throwOnError: true })
          .then(({ data }) => {
            updateDueAmount(data);

            subscribeToPlan();
          })
          .catch((e) => {
            if (e instanceof Error) {
              if (e.message === "Sorry, we couldn't find that resource")
                toast.error('This coupon code is invalid!');
              else toast.error(e.message);
            } else {
              toast.error('An unknown error occurred');
            }
          });
      } catch (e) {
        setIsSubmitting(false);
        if (e instanceof Error) {
          if (e.message === "Sorry, we couldn't find that resource")
            toast.error('This coupon code is invalid!');
          else toast.error(e.message);
        } else {
          toast.error('An unknown error occurred');
        }
      } finally {
        setIsSubmitting(false);
      }
    } else {
      subscribeToPlan();
    }
  };

  const applyPromo = async () => {
    try {
      const response = await refetchCoupon({ throwOnError: true });
      if (response && response.data) {
        updateDueAmount(response.data);
        setAddPromoOpen(false);
        setAppliedPromo(response.data);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "Sorry, we couldn't find that resource") {
          form.setValue('coupon', '');
          toast.error('This coupon code is invalid!');
        } else toast.error(e.message);
      } else {
        toast.error('An unknown error occurred');
      }
    }
  };

  const onReady = (event: SyntheticEvent) => {
    const element = event.currentTarget as HTMLElement;
    if (element) element.focus();
  };

  const queryToCall = userHasPlan
    ? trpc.upgradeUserWithActivePlan
    : trpc.upgradeUserWithNoPlan;

  const { isPending, mutate: upgradeUserNewPlan } = queryToCall.useMutation({
    onSuccess: (response) => {
      if (response && response.success) {
        handlePurchase(
          `${new Date()}`,
          parseInt(selectedPlan?.price.replace('$', ''), 10),
          [
            {
              item_id: selectedPlan?.plan_id,
              item_name: selectedPlan?.name,
              price: parseInt(selectedPlan?.price.replace('$', ''), 10),
              quantity: 1,
            },
          ]
        );
        setCheckoutSuccessful(true);
      } else {
        setIsSubmitting(false);
      }
    },
    onError: (error) => {
      setIsSubmitting(false);
      if (error instanceof Error) {
        if (error.message.includes('already present')) {
          toast.error('This account already exists.');
          setTimeout(() => {
            navigate({ to: '/feeds/templates' });
          }, 2000);
        } else {
          toast.error(error.message); // Show the original error for other cases
        }
      } else {
        toast.error('An unknown error occurred');
      }
    },
  });

  useEffect(() => {
    if (subscriptionSuccessful) {
      setIsSubmitting(false);
      setUpgradeSuccessShow(true);
      showToastNotification('success', {
        message: 'Your subscription has been upgraded successfully!',
      });
    }
  }, [subscriptionSuccessful]);

  const { data: standardToProProration } =
    trpc.getStandardToProProration.useQuery(undefined, {
      enabled: userHasPlan,
    });

  const proratedDueAmount = standardToProProration
    ? standardToProProration?.proratedProPlan - standardToProProration?.credits
    : 0;

  const {
    isLoading: isLoadingCoupon,
    isRefetching: isRefetchingCoupon,
    refetch: refetchCoupon,
  } = trpc.getCouponDetails.useQuery(
    {
      couponId: !form.getFieldState('coupon').invalid
        ? form.getValues('coupon')
        : '',
    },
    { enabled: false }
  );

  useEffect(() => {
    if (userCardDetails && !isLoadingUserCardDetails) {
      setAddCardOpen(false);
    }
  }, [userCardDetails, isLoadingUserCardDetails]);

  if (isLoadingUserCardDetails) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Loader />
      </div>
    );
  }

  if (upgradeSuccessShow) {
    return <UpgradeSuccessModal type={'upgrade'} />;
  }

  return (
    <Form {...form}>
      <form
        // if the user is using existing details, just submit,
        // but if new card details is being added,
        // validate with react-hook-form
        onSubmit={
          userCardDetails && !addCardOpen
            ? onSubmit
            : form.handleSubmit(onSubmit)
        }
      >
        <div className={'flex flex-col gap-6'}>
          <DialogTitle>
            <span className={'text-xl lg:text-2xl text-left font-semibold'}>
              {selectedPlanIsPro
                ? 'Upgrade To Creative Pro'
                : 'Subscribe to CreativeOS'}
            </span>
          </DialogTitle>
          <div>
            <div
              className={
                'flex flex-col md:flex-row md:justify-between gap-3 items-center'
              }
            >
              <p className={'font-bold'}>Subscription</p>
              {annualFlagEnabled ? (
                <Tabs defaultValue={period}>
                  <TabsList className={'rounded-full'}>
                    <TabsTrigger
                      className={'rounded-full'}
                      value={'monthly'}
                      onClick={() => handlePeriodChange('monthly')}
                    >
                      Pay Monthly
                    </TabsTrigger>
                    <TabsTrigger
                      className={'rounded-full flex gap-1.5'}
                      value={'annually'}
                      onClick={() => handlePeriodChange('annually')}
                    >
                      <span>Pay Yearly</span>{' '}
                      <span className={'text-themedestructive'}>Save 50%</span>
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              ) : null}
            </div>
            <div className={'flex flex-col'}>
              <div
                className={'flex flex-col gap-3 py-3 border-b border-dashed'}
              >
                <div>
                  <div className={'flex gap-3 justify-between text-sm'}>
                    <span className={'flex gap-1 items-center'}>
                      <img src={'/images/icon_no_bg.png'} width={16} height={16} alt={''} />
                      <span>CreativeOS {selectedPlan.name}</span>
                    </span>
                    <span className={'font-semibold'}>
                      {period === 'monthly'
                        ? selectedPlan.price
                        : selectedPlan.annual_price}{' '}
                      / {period === 'monthly' ? 'month' : 'year'}
                    </span>
                  </div>
                  <div
                    className={
                      'flex gap-3 justify-between text-xs font-light text-thememutedforeground'
                    }
                  >
                    <span>
                      {period === 'monthly' ? 'Monthly' : 'Annual'}{' '}
                      Subscription, cancel anytime.
                    </span>
                    <span>{period === 'monthly' ? null : '$42 / mo avg.'}</span>
                  </div>
                </div>

                <div>
                  {addPromoOpen ? (
                    <div className={'flex gap-1.5'}>
                      <FormField
                        control={form.control}
                        name="coupon"
                        render={({ field }) => (
                          <FormItem className={'flex-1'}>
                            <FormControl>
                              <Input
                                type={'text'}
                                placeholder={'Add Promo Code'}
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <Button
                        onClick={() => {
                          applyPromo();
                        }}
                        variant={'secondary'}
                        size={'sm'}
                        type={'button'}
                        disabled={
                          isLoadingCoupon ||
                          isRefetchingCoupon ||
                          form.watch('coupon') === ''
                        }
                        loading={isLoadingCoupon || isRefetchingCoupon}
                      >
                        {isLoadingCoupon || isRefetchingCoupon
                          ? 'Checking...'
                          : 'Apply'}
                      </Button>
                    </div>
                  ) : appliedPromo ? (
                    <div>
                      <div className={'flex gap-3 justify-between text-sm'}>
                        <span className={'flex gap-1 items-center'}>
                          <img src={'/label.png'} alt={''} />
                          <span>{appliedPromo.name}</span>
                          <span
                            onClick={() => {
                              setAddPromoOpen(false);
                              setAppliedPromo(undefined);
                              setDueTodayAmount(
                                proratedDueAmount ?? defaultPrice
                              );
                              form.setValue('coupon', '');
                            }}
                            className={
                              'text-thememutedforeground cursor-pointer'
                            }
                          >
                            <XCircle className={'w-4 h-4'} />
                          </span>
                        </span>
                        <span className={'font-semibold'}>
                          -
                          {appliedPromo.discountType === 'fixed_amount' &&
                          appliedPromo.currencyCode
                            ? getCurrencySymbol(appliedPromo.currencyCode)
                            : ''}
                          {appliedPromo.discountAmount
                            ? `${(appliedPromo.discountAmount / 100).toFixed(2)}`
                            : `${appliedPromo.discountPercentage}%`}{' '}
                          {appliedPromo.durationType === 'limited_period'
                            ? `/ ${appliedPromo.periodUnit}`
                            : appliedPromo.durationType}
                        </span>
                      </div>
                      <div
                        className={
                          'flex gap-3 justify-between text-xs font-light text-thememutedforeground capitalize'
                        }
                      >
                        <span>
                          {appliedPromo.discountType === 'fixed_amount' &&
                          appliedPromo.currencyCode
                            ? getCurrencySymbol(appliedPromo.currencyCode)
                            : ''}
                          {appliedPromo.discountAmount
                            ? `${(appliedPromo.discountAmount / 100).toFixed(2)}`
                            : `${appliedPromo.discountPercentage}%`}
                          {' OFF'}{' '}
                          {appliedPromo?.periodUnit === 'month'
                            ? 'monthly'
                            : appliedPromo?.periodUnit === 'year'
                              ? 'yearly'
                              : ''}{' '}
                          {' Price '}
                          {appliedPromo.periodUnit
                            ? `for ${appliedPromo.period} ${appliedPromo.periodUnit}`
                            : appliedPromo.durationType}
                        </span>
                        <span>
                          {appliedPromo.durationType === 'limited_period' &&
                            `For ${appliedPromo.period} ${appliedPromo.periodUnit}`}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <span
                      onClick={() => setAddPromoOpen(true)}
                      className={
                        'text-sm underline text-themedestructive font-medium cursor-pointer'
                      }
                    >
                      Add Promo Code
                    </span>
                  )}
                </div>

                {selectedPlanIsPro && standardToProProration ? (
                  <div className={'flex flex-col gap-4'}>
                    <div>
                      <div className={'flex justify-between gap-3'}>
                        <p className={'text-sm'}>Credits</p>
                        <p className={'text-sm font-semibold text-right'}>
                          -${standardToProProration.credits}
                        </p>
                      </div>
                    </div>
                    <div className={'flex justify-between gap-3'}>
                      <p className={'text-sm'}>Subtotal</p>
                      <p className={'text-sm font-semibold text-right'}>
                        ${standardToProProration.proratedProPlan}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={'flex justify-between gap-3'}>
                    <p className={'text-sm'}>First Charge Date</p>
                    <p className={'text-sm font-semibold text-right'}>
                      {firstChargeDate}
                    </p>
                  </div>
                )}
              </div>

              <div className={'flex justify-between gap-3 py-2'}>
                <p className={'text-sm'}>Due Today</p>
                <p className={'text-sm font-semibold text-right'}>
                  {selectedPlanIsFree ? '$0' : `$${dueTodayAmount}`}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className={'flex flex-col gap-6'}>
              <div className={'flex justify-between gap-3 items-center'}>
                <p className={'font-bold'}>Payment Details</p>
                {fetchingPortalSessionUrl ? (
                  <span className="font-medium text-sm leading-5 text-thememutedforeground tracking-wide	">
                    Loading...
                  </span>
                ) : addCardOpen ? (
                  <span
                    className={'underline font-medium text-sm cursor-pointer'}
                    onClick={() => setAddCardOpen(false)}
                  >
                    Cancel
                  </span>
                ) : (
                  <span
                    className={'underline font-medium text-sm cursor-pointer'}
                    // onClick={() => setAddCardOpen(true)}
                    onClick={() => openPortalSessionUrl()}
                  >
                    Add Card
                  </span>
                )}
              </div>
              {!addCardOpen && userCardDetails ? (
                <div>
                  <div
                    className={`flex items-center justify-between rounded-lg border-2 ${userCardDetails.status === 'expired' ? 'border-maroon-500 text-thememutedforeground' : 'border-themedestructive'} p-4`}
                  >
                    <div className="flex items-center space-x-2">
                      {userCardDetails.status === 'expired' ? (
                        <span>
                          <DangerTriangleSolid />
                        </span>
                      ) : (
                        <span
                          className={cn(
                            'flex items-center justify-center aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                            'border-themedestructive text-themedestructive'
                          )}
                        >
                          <Circle className="h-2.5 w-2.5 fill-current text-current" />
                        </span>
                      )}
                      <span className={'font-medium'}>
                        {userCardDetails.card_type &&
                          capitalizeCardType(userCardDetails.card_type)}{' '}
                        ending in {userCardDetails.last4}
                      </span>
                    </div>
                    <span>
                      <CreditCard />
                    </span>
                  </div>
                  <div>
                    {userCardDetails.status === 'expired' && (
                      <span className={'text-xs font-light'}>
                        This card is expired. Please update this cards details
                        or add another
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div className={'flex flex-col gap-6'}>
                  <div className={'grid grid-cols-2 gap-3'}>
                    <FormField
                      control={form.control}
                      name="firstName"
                      render={({ field }) => (
                        <FormItem>
                          <Label>
                            First Name <span className={'text-red-500'}>*</span>
                          </Label>
                          <FormControl>
                            <Input placeholder="First Name" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="lastName"
                      render={({ field }) => (
                        <FormItem>
                          <Label>
                            Last Name <span className={'text-red-500'}>*</span>
                          </Label>
                          <FormControl>
                            <Input placeholder="Last Name" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <CardComponent
                    className="field"
                    ref={cardRef}
                    onReady={onReady}
                  >
                    <div>
                      <Label>
                        Card Number <span className={'text-red-500'}>*</span>
                      </Label>
                      <CardNumber
                        placeholder="4111 1111 1111 1111"
                        className={cn(inputStyles())}
                      />
                    </div>
                    <div className={'grid lg:grid-cols-2 gap-3 mt-4'}>
                      <div>
                        <Label>
                          Expiration Date{' '}
                          <span className={'text-red-500'}>*</span>
                        </Label>
                        <CardExpiry
                          placeholder="MM / YY"
                          className={cn(inputStyles())}
                        />
                      </div>
                      <div>
                        <Label>
                          CVV <span className={'text-red-500'}>*</span>
                        </Label>
                        <CardCVV
                          placeholder="CVV"
                          className={cn(inputStyles())}
                        />
                      </div>
                    </div>
                  </CardComponent>
                  <div>
                    <p className={'font-medium'}>Billing Details</p>
                  </div>
                  <div className={'flex flex-col gap-3'}>
                    <FormField
                      control={form.control}
                      name="billingAddress.country"
                      render={({ field }) => (
                        <FormItem>
                          <Label>
                            Country <span className={'text-red-500'}>*</span>
                          </Label>
                          <FormControl>
                            <Select
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select country" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {countries &&
                                  countries.map((item) => (
                                    <SelectItem
                                      key={item.name}
                                      value={item.code2}
                                    >
                                      {item.name}
                                    </SelectItem>
                                  ))}
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="billingAddress.line1"
                      render={({ field }) => (
                        <FormItem>
                          <Label>
                            Address <span className={'text-red-500'}>*</span>
                          </Label>
                          <FormControl>
                            <Input placeholder="Line 1" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="billingAddress.line2"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input placeholder="Line 2" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="billingAddress.city"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input placeholder="City" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <div className={'grid grid-cols-2 gap-3'}>
                      {countries &&
                      form.getValues('billingAddress.country') &&
                      getStatesByCode2(
                        form.watch('billingAddress.country') ?? ''
                      ).length < 1 ? (
                        <FormField
                          control={form.control}
                          name="billingAddress.state"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Input placeholder="State" {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      ) : (
                        <FormField
                          control={form.control}
                          name="billingAddress.state"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Select
                                  onValueChange={field.onChange}
                                  defaultValue={field.value}
                                  disabled={
                                    !form.getValues('billingAddress.country')
                                  }
                                >
                                  <FormControl>
                                    <SelectTrigger>
                                      <SelectValue placeholder="State" />
                                    </SelectTrigger>
                                  </FormControl>
                                  <SelectContent>
                                    {countries &&
                                      form.getValues(
                                        'billingAddress.country'
                                      ) &&
                                      getStatesByCode2(
                                        form.watch('billingAddress.country') ??
                                          ''
                                      ).map((item) => (
                                        <SelectItem
                                          key={item.name}
                                          value={item.name}
                                        >
                                          {item.name}
                                        </SelectItem>
                                      ))}
                                  </SelectContent>
                                </Select>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}
                      <FormField
                        control={form.control}
                        name="billingAddress.zip"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input placeholder="Zip code" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={'mt-6'}>
              <Button
                disabled={
                  isSubmitting ||
                  isPending ||
                  (!addCardOpen && userCardDetails?.status === 'expired')
                }
                type={'button'}
                onClick={
                  userCardDetails && !addCardOpen
                    ? onSubmit
                    : form.handleSubmit(onSubmit)
                }
                className="w-full"
                loading={isSubmitting || isPending}
              >
                {isSubmitting || isPending ? 'Processing...' : 'Pay Now'}
              </Button>
              <div>
                <span
                  className={
                    'flex justify-center items-center gap-2 text-center mt-1.5 text-sm'
                  }
                >
                  <Lock className={'w-5'} />
                  <span>Secure Checkout by Chargebee</span>
                </span>
              </div>
            </div>
            {selectedPlanIsPro && standardToProProration && (
              <div className={'mt-6'}>
                <p className={'text-xs font-light text-thememutedforeground'}>
                  {`This month includes credits because you have an existing CreativeOS membership. You will be charged $${selectedPlanIsFree ? '0' : dueTodayAmount} per month starting ${firstChargeDate}. You can cancel your subscription at any time.`}
                </p>
              </div>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};

export function UpgradeSuccessModal({
  type,
  newPlanName,
}: {
  type: 'upgrade' | 'downgrade';
  newPlanName?: string;
}) {
  const [enableClose, setEnableClose] = useState<boolean>(false);

  useEffect(() => {
    const enableCloseHandler = () => {
      setTimeout(() => {
        setEnableClose(true);
      }, 5000);
    };
    enableCloseHandler();
  }, []);

  return (
    <div>
      <div className={'flex flex-col items-center gap-2'}>
        <img
          alt={'CreativeOS'}
          src={'/images/logo-full-dark_mode.png'}
          className={'h-7  dark:block hidden'}
        />
        <img
          alt={'CreativeOS'}
          src={'/images/logo-full-light_mode.png'}
          className={'h-7 dark:hidden block'}
        />
        <h4
          className={'text-2xl text-center text-themeforeground font-semibold '}
        >
          {type === 'downgrade' ? 'Downgrade Scheduled' : 'You’re all set!'}
        </h4>
        <p className={'text-thememutedforeground text-lg text-center'}>
          {type === 'downgrade' ? `Your subscription will be downgraded to ${newPlanName} starting from your next renewal.` : 'We’ve received your payment, go start creating!'}
        </p>
      </div>
      <div className={'mt-6'}>
        <Button
          onClick={() => {
            window.location.href = '/feeds/templates';
          }}
          type={'button'}
          className="w-full"
          disabled={!enableClose}
        >
          {'Start Creating'}
        </Button>
      </div>
    </div>
  );
}
