import { defaultUseQueryRefetchOptions } from "@/_shared/constants";
import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import { trpc } from "@/utils/trpc";
import { ArrowRight } from "@mynaui/icons-react";
import { Link, useNavigate } from "@tanstack/react-router";
import AccessControl from "./AccessControl";
import SearchDialogAdTemplateImage from "./SearchDialogAdTemplateImage";
import { LockTagLargeNoBg, LockTagSmall } from "./custom-components/LockTag";
import { Badge } from "./shadcn/badge";
import { ScrollArea, ScrollBar } from "./shadcn/scroll-area";
import { Skeleton } from "./shadcn/skeleton";
import { Tooltip, TooltipContent, TooltipTrigger } from "./shadcn/tooltip";

const SearchDialogPopularTab = ({
  searchItem,
}: {
  searchItem: (text: string) => void;
}) => {
  const tagsArray = [
    "Testimonial",
    "Benefits",
    "Comparison",
    "Collage",
    "Media & PR",
    "Social",
    "Expert",
    "Holiday",
    "New",
  ];

  const copyTextsArray = [
    "Free",
    "Shop",
    "New",
    "Healthy",
    "Sale",
    "Save",
    "Discount",
    "Shipping",
    "Fall/Winter",
    "Featuring",
  ];

  const navigate = useNavigate();

  // Templates
  const { data: popularTemplates, isLoading: templatesIsLoading } =
    trpc.filterTemplatesByCollections.useQuery({
      sortingOptions: "Popular",
      Tags: undefined,
      limit: 10,
      Ready: true,
      collectionIds: [],
    });

  const { data: brands, isLoading: brandsIsLoading } = trpc.getBrands.useQuery(
    undefined,
    defaultUseQueryRefetchOptions
  );

  const { data: templateCollections } = trpc.getAllCollections.useQuery(
    {},
    defaultUseQueryRefetchOptions
  );

  return (
    <div className="space-y-1.5 h-full pb-10">
      <div className="space-y-1.5">
        <h6 className="text-gray-500 font-medium text-sm">Popular Templates</h6>
        {templatesIsLoading ? (
          <div className="flex space-x-2.5">
            {[...Array(3)].map((_, index) => (
              <Skeleton
                key={index}
                className="w-[11.4063rem] h-[11.4063rem] rounded-2xl"
              />
            ))}
          </div>
        ) : (
          <ScrollArea className="whitespace-nowrap space-y-2.5 mr-4">
            <div className="flex space-x-2.5">
              {popularTemplates?.ATRecords.map((item) => (
                <SearchDialogAdTemplateImage
                  key={item["Creative ID"]}
                  item={{
                    ...item,
                    form: "",
                    "Example Image": [],
                    "Template Image": [],
                    Status: "",
                    AdDbId: null,
                    Expert: undefined,
                    Tags: undefined,
                    Liked: item.Liked ?? false,
                    "Canva Template LInk": item["Canva Template LInk"] ?? "",
                  }}
                />
              ))}
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        )}
        <div className="flex justify-end pr-5">
          <AccessControl
            feature={CreativeOSFeatures.SEARCH_AD_TEMPLATE_NAV_BUTTON}
            FeatureUnlocked={
              <Link
                className="text-slate-500"
                to="/feeds/templates"
                search={{ orderFilter: "Popular" }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={"1rem"} />
                </span>
              </Link>
            }
            FeatureLocked={
              <span className="flex items-center gap-2 text-sm opacity-50">
                <span>See all</span> <LockTagSmall />
              </span>
            }
          />
        </div>
      </div>
      {(templateCollections ?? []).length > 0 ? (
        <div className="hidden lg:block space-y-1.5">
          <h6 className="text-gray-500 font-medium text-sm">Collections</h6>

          <div className="flex flex-wrap gap-2.5">
            {templateCollections?.slice(0, 8).map((item) => (
              <AccessControl
                feature={CreativeOSFeatures.CREATIVEOS_COLLECTIONS}
                FeatureUnlocked={
                  <Badge
                    variant={"outline"}
                    key={item.Title}
                    className="cursor-pointer lg:hvr-grow"
                    onClick={() =>
                      navigate({
                        to: "/feeds/collections/$collectionID",
                        params: { collectionID: item.atID },
                      })
                    }
                  >
                    {item.Title}
                  </Badge>
                }
                FeatureLocked={
                  <Badge
                    variant={"outline"}
                    key={item.Title}
                    className="cursor-pointer lg:hvr-grow items-center gap-2 opacity-50"
                  >
                    <span>{item.Title}</span>
                    <LockTagSmall />
                  </Badge>
                }
              />
            ))}
          </div>
          <div className="flex w-full justify-end pr-5">
            <AccessControl
              feature={CreativeOSFeatures.CREATIVEOS_COLLECTIONS}
              FeatureUnlocked={
                <Link
                  className="text-slate-500"
                  to="/feeds/collections/creativeos"
                >
                  <span className="flex items-center gap-2 text-sm">
                    <span>See all</span> <ArrowRight size={"1rem"} />
                  </span>
                </Link>
              }
              FeatureLocked={
                <span className="flex items-center gap-2 text-sm opacity-50">
                  <span>See all</span> <LockTagSmall />
                </span>
              }
            />
          </div>
        </div>
      ) : null}
      {(brands ?? []).length > 0 ? (
        <div className="hidden lg:block space-y-1.5">
          <div className="flex justify-between pr-5">
            <h6 className="text-gray-500 font-medium text-sm">Brands</h6>
          </div>
          {brandsIsLoading ? (
            <div className="flex w-max space-x-2.5 p-4">
              {[...Array(7)].map((_, index) => (
                <Skeleton key={index} className="w-16 h-16 rounded-full" />
              ))}
            </div>
          ) : (
            <ScrollArea className="whitespace-nowrap space-y-2.5">
              <div className="flex w-max space-x-2.5 px-4 pb-4 mt-2">
                {brands
                  ?.sort((a, b) => b.totalActiveAdCount - a.totalActiveAdCount)
                  .slice(0, 10)
                  .map((item) => (
                    <Tooltip>
                      <TooltipTrigger>
                        <AccessControl
                          feature={CreativeOSFeatures.SHOW_SEARCH_BRANDS_BUTTON}
                          FeatureUnlocked={
                            <Link
                              to={`/feeds/brands/$brandID`}
                              params={{ brandID: item.brandId }}
                              key={item.brandId}
                            >
                              <img
                                src={item.brandImage ?? undefined}
                                className="w-10 h-10 bg-slate-100 rounded-full object-cover hvr-float"
                                key={item.brandId}
                                alt={item.brandName}
                              />
                            </Link>
                          }
                          FeatureLocked={
                            <div className="relative">
                              <img
                                src={item.brandImage ?? undefined}
                                className="w-10 h-10 bg-slate-100 rounded-full object-cover blur-sm"
                                key={item.brandId}
                                alt={item.brandName}
                              />
                              <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-black bg-opacity-50 rounded-full p-0.5">
                                  <LockTagLargeNoBg />
                                </div>
                              </div>
                            </div>
                          }
                        />
                        <TooltipContent className="max-w-sm">
                          <p>{item.brandName}</p>
                        </TooltipContent>
                      </TooltipTrigger>
                    </Tooltip>
                  ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}

          <div className="flex w-full justify-end pr-5">
            <AccessControl
              feature={CreativeOSFeatures.SHOW_SEARCH_BRANDS_BUTTON}
              FeatureUnlocked={
                <Link className="text-slate-500" to="/feeds/brands">
                  <span className="flex items-center gap-2 text-sm">
                    <span>See all</span> <ArrowRight size={"1rem"} />
                  </span>
                </Link>
              }
              FeatureLocked={
                <span className="flex items-center gap-2 text-sm opacity-50">
                  <span>See all</span> <LockTagSmall />
                </span>
              }
            />
          </div>
        </div>
      ) : null}
      <div className="space-y-1.5 pr-3">
        <h6 className="text-gray-500 font-medium text-sm">Tags</h6>

        <div className="flex flex-wrap gap-2.5">
          {tagsArray &&
            tagsArray.map((item) => (
              <Badge
                variant={"outline"}
                key={item}
                className="cursor-pointer lg:hvr-grow"
                onClick={() => searchItem(item)}
              >
                {item}
              </Badge>
            ))}
        </div>
      </div>
      <div className="pt-4 space-y-1.5 pr-3">
        <h6 className="text-gray-500 font-medium text-sm">Text in Template</h6>

        <div className="flex flex-wrap gap-2.5">
          {copyTextsArray?.slice(0, 8).map((item) => (
            <Badge
              variant={"outline"}
              key={item}
              className="cursor-pointer lg:hvr-grow"
              onClick={() => searchItem(item)}
            >
              {item}
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchDialogPopularTab;
