import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import { Button, buttonVariants } from "@/components/shadcn/button.tsx";
import showToastNotification from "@/hooks/useShowToast";
import useUserPermissions from "@/hooks/useUserPermissions";
import { cn } from "@/lib/utils";
import { trpc } from "@/utils/trpc";
import {
  CalendarUp,
  CheckCircleSolid,
  ExternalLink,
  Layout,
  Like,
  Lock,
  Presentation,
} from "@mynaui/icons-react";
import { useState } from "react";
import AccessControl from "./AccessControl";
import { Stack, Text } from "./custom-components";
import { Badge } from "./shadcn/badge";
import {
  GetLandingAdTemplate,
  LandingFeedProps,
} from "./templates/LandingAdCard";

interface Props {
  data: LandingFeedProps;
  mediaComponent?: JSX.Element;
}

export default function LanderInspirationDetailsBody({
  data,
  mediaComponent,
}: Props) {
  const [adRequested, setAdRequested] = useState<boolean>(
    data.hasUserRequestedTemplate ?? false
  );
  const { mutateAsync: requestLanderTemplate, isPending } =
    trpc.requestLanderTemplate.useMutation();
  const utils = trpc.useUtils();
  const {
    userLimits
  } = useUserPermissions();
  return (
    <div className="px-3 flex flex-col lg:px-8 py-3 pt-0 lg:py-8 lg:pt-4 gap-8 lg:gap-6">
      <div className="px-0 gap-2  flex flex-col">
        <div className="flex gap-2 items-center">
          <Text weight={"semibold"} size={"lg"} className="leading-7 w-fit">
            {data.brandName} Lander
          </Text>
          {data?.template && (
            <Badge variant={"secondary"} className="px-2.5 py-1 flex gap-1">
              <Layout className="w-4 h-4 min-w-4" /> {"Template"}
            </Badge>
          )}
        </div>
        <div
          className={
            "flex items-center gap-2 lg:gap-4 text-thememutedforeground text-sm"
          }
        >
          <div className={"flex items-center gap-1"}>
            <span>
              <Like className={"w-4.5"} />
            </span>
            <span>{data?.requestCount || 0} Requests</span>
          </div>
          <div className={"flex items-center gap-1"}>
            <span>
              <Presentation className={"w-4.5"} />
            </span>
            <span>Inspiration</span>
          </div>
        </div>
      </div>
      {mediaComponent}
      {data?.template ? (
        <GetLandingAdTemplate
          buttonVersion
          type={"landing-page"}
          Ad={{
            Screenshot: data.template["Landing Page Screenshot"],
            ID: data.template["LP ID"],
            type: "landing-page",
            created: data.template.Created,
            isSaved: data.template.isSaved,
            imageUrl: data.template.imageUrl,
          }}
        />
      ) : (
        <Stack className="gap-3">
          <AccessControl
          feature={CreativeOSFeatures.LANDER_INSPIRATION_REQUEST}
            FeatureUnlocked={
              <Button
                variant={adRequested ? "default" : "destructivePurple"}
                onClick={()=>{

                  setAdRequested(true);
                  requestLanderTemplate({
                    landerID: data.landerId,
                  }).then(() => {
                    showToastNotification("success", {
                      message: `${Math.max( (userLimits?.request.lander ?? 0)- 1,0)} Template Requests left!`,
                      description: `You have ${Math.max( (userLimits?.request.lander ?? 0)- 1, 0)} template requests left for the month.`,
                    });
                    utils.permissions.invalidate();
                    utils.getUserLimits.invalidate();
                    utils.getLander.invalidate({
                      landerId: data.landerId,
                    });
                  });
                }
                }
                className={`w-full`}
                loading={isPending}
              >
                { adRequested ? (
                  <CheckCircleSolid className="w-5 h-5 mr-2" />
                ) : null}
                {adRequested ? "Requested" : "Request Template"}
              </Button>
            }
            FeatureLocked={
              <Button
                className={`w-full opacity-50`}
                variant={adRequested ? "default" : "destructivePurple"}
                loading={isPending}
              >
                <Lock className="w-5 h-5 mr-2" />

                {adRequested ? "Requested" : "Request Template"}
              </Button>
            }
          />
          {data.landerUrl && (
            <a
              href={data.landerUrl}
              target={"_blank"}
              className={cn(buttonVariants({ variant: "secondary" }))}
            >
              View Landing Page
            </a>
          )}
        </Stack>
      )}
      <Stack className={"flex flex-col gap-4 text-sm"}>
        <Text weight={"medium"} size="sm" className="leading-5">
          Ad Details
        </Text>
        {data.landerUrl && (
          <div className={"flex justify-between items-center gap-4"}>
            <Text
              weight={"thin"}
              size="sm"
              className="leading-5 text-thememutedforeground w-24 min-w-24"
            >
              Landing Page
            </Text>
            <a
              href={data.landerUrl ?? ""}
              target={"_blank"}
              className="flex items-center max-w-full overflow-hidden text-themedestructive"
            >
              <span
                className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap`}
              >
                {data.landerUrl}
              </span>
              <ExternalLink className="w-4 h-4 min-w-4" />
            </a>
          </div>
        )}
        {data.createdAt && !isNaN(new Date(data.createdAt).getTime()) && (
          <div className={"flex justify-between items-center gap-4"}>
            <Text
              weight={"thin"}
              size="sm"
              className="leading-5 text-thememutedforeground w-24 min-w-24"
            >
              Published
            </Text>
            <div className="flex items-center gap-1 max-w-full">
              <CalendarUp className="w-4 h-4 min-w-4" />

              <span
                className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap`}
              >
                {new Date(data.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </div>
          </div>
        )}
      </Stack>
    </div>
  );
}
