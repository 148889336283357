import { PLANS, PLAN_TYPES } from "./types";

export const airtableTableNames = {
  adTemplateRequests: "Ad Template Requests",
  emailTemplateRequests: "Email Template Requests",
  landingPageTemplateRequests: "Landing Page Template Requests",
  adTemplates: "Ad Templates",
  emailTemplates: "Email Templates",
  landingPageTemplates: "Landing Pages",
};

export const cacheKeys = {
  records: "airtable:records",
  recordsFigmaCopyAndPaste: (id: number | string) =>
    `airtable:records:figma-copy-and-paste:${id}`,
  landingPages: "airtable:landingPages",
  landingPageCategories: "airtable:landingPageCategories",
  landingPagesFigmaCopyAndPaste: (id: string) =>
    `airtable:landing-pages:figma-copy-and-paste:${id}`,
  emails: "airtable:emails",
  emailCategories: "airtable:emailsCategories",
  emailsFigmaCopyAndPaste: (id: string) =>
    `airtable:emails:figma-copy-and-paste:${id}`,
  collections: "airtable:collections",
  inspirationCategories: "airtable:inspirationCategories",
  inspirationCategoryBrandsMap: "airtable:inspirationCategoryBrandsMap",
  emailFeedBrands: "airtable:emailBrands",
  experts: "airtable:experts",
  brandsCategoriesMap: "airtable:brandsCategoriesMap",
  templatesDescriptiveTags: "template:descriptiveTags",
  templatesEmotiveTags: "template:emotiveTags",
  templatesCompositionTags: "template:compositionTags",
} as const;

export const appConstants = {
  airtableBase: "appJJPoQ2mp0UKwlI",
};

export const plansCost = {
  PRO: 99,
  STANDARD: 49,
};

export const sentryEnvs = ["production"];

export const r2AirtablePrefix = {
  ads: "at/ads",
  emails: "at/emails",
  landingPages: "at/landing-pages",
  experts: "at/experts",
} as const;

export const exampleDescriptiveTags = [
  "business",
  "teamwork",
  "realism",
  "startup",
  "collaboration",
  "formal",
  "strategy",
  "innovation",
  "Testimonial",
  "Benefits",
  "Comparison",
  "Collage",
  "Media & PR",
  "Social",
  "Expert",
  "Holiday",
  "New",
  "Social Proof",
  "Offer",
  "Product Suite",
  "Welcome",
  "Abandoned Cart",
  "Testimonials/Reviews",
  "Post Purchase",
  "DevTest",
  "Bundle",
  "Explainer",
  "New Product",
  "Quiz",
  "Listicle",
  "Tips & Hacks",
  "Influencer",
  "Us vs Them",
  "Advertorial",
];

export const exampleEmotiveTags = [
  "happy",
  "energetic",
  "corporate",
  "motivated",
  "focused",
  "determined",
  "serious",
  "ambitious",
];

export const exampleCompositionTags = [
  "people",
  "branding",
  "workspace",
  "whiteboard",
  "conference_room",
  "presentation",
];

export const REQUEST_PRIORITY_POINTS: Record<PLAN_TYPES, number> = {
  "PRO FULL PAY": 7,
  "PRO DISCOUNTED": 6,
  "STANDARD FULL PAY": 5,
  "STANDARD DISCOUNTED": 4,
  "PRO TRIAL": 3,
  "STANDARD TRIAL": 2,
  "ROVIE REQUESTS": 1,
};

export const MEILISEARCH_INDEXES = {
  AD_TEMPLATES: "ad_templates",
  EMAIL_TEMPLATES: "email_templates",
  LANDING_PAGE_TEMPLATES: "landing_page_templates",
  COLLECTIONS: "collections",
  BRANDS: "brands",
  EXPERTS: "experts",
  EMAIL_INSPIRATIONS: "email_inspirations",
  LANDER_INSPIRATIONS: "lander_inspirations",
  AD_INSPIRATIONS: "ad_inspirations",
} as const;

export const FREE_PLAN_ID = "creative-os-free-plan";
export const IGNITE_PLAN_ID = "pln_creative-os-standard-hoq20qmr";
export const IGNITE_PLAN_NO_TRIAL = "creativeos-standard-no-trial";
export const SPARK_PLAN_ID = "creativeos-spark-plan-usd-monthly";
export const SPARK_PLAN_NO_TRIAL = "spark-plan-no-trial-usd-Monthly";
export const PRO_PLAN_ID = "pln_creative-os-pro-hdpx0qjo";
export const PRO_PLAN_NO_TRIAL = "creativeos-pro-no-trial";

export const requestLimit: Record<
  PLANS,
  {
    ads: number;
    emails: number;
    landers: number;
  }
> = {
  [FREE_PLAN_ID]: {
    ads: 0,
    emails: 0,
    landers: 0,
  },
  [SPARK_PLAN_ID]: {
    ads: 1,
    emails: 0,
    landers: 0,
  },
  [SPARK_PLAN_NO_TRIAL]: {
    ads: 1,
    emails: 0,
    landers: 0,
  },
  [IGNITE_PLAN_ID]: {
    ads: 5,
    emails: 3,
    landers: 1,
  },
  [IGNITE_PLAN_NO_TRIAL]: {
    ads: 5,
    emails: 3,
    landers: 1,
  },
  [PRO_PLAN_ID]: {
    ads: Number.MAX_SAFE_INTEGER,
    emails: Number.MAX_SAFE_INTEGER,
    landers: Number.MAX_SAFE_INTEGER,
  },
  [PRO_PLAN_NO_TRIAL]: {
    ads: Number.MAX_SAFE_INTEGER,
    emails: Number.MAX_SAFE_INTEGER,
    landers: Number.MAX_SAFE_INTEGER,
  },
};

export const creativeToolKitLimit = {
  [FREE_PLAN_ID]: 0,
  [SPARK_PLAN_ID]: 3,
  [SPARK_PLAN_NO_TRIAL]: 3,
  [IGNITE_PLAN_ID]: 10,
  [IGNITE_PLAN_NO_TRIAL]: 10,
  [PRO_PLAN_ID]: Number.MAX_SAFE_INTEGER,
  [PRO_PLAN_NO_TRIAL]: Number.MAX_SAFE_INTEGER,
};

export const userCOllectionsLimit = {
  [FREE_PLAN_ID]: 1,
  [SPARK_PLAN_ID]: 3,
  [SPARK_PLAN_NO_TRIAL]: 3,
  [IGNITE_PLAN_ID]: 10,
  [IGNITE_PLAN_NO_TRIAL]: 10,
  [PRO_PLAN_ID]: Number.MAX_SAFE_INTEGER,
  [PRO_PLAN_NO_TRIAL]: Number.MAX_SAFE_INTEGER,
};
