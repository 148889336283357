import { useState } from 'react';
import { EmailFeedContent } from '../../../../src/server/services/emails/definitions';
import EmailInspirationDetails from './EmailInspirationDetails';

const SearchDialogEmailInspirationImage = ({
  item,
  isEqualWidth,
}: {
  item: EmailFeedContent;
  isEqualWidth?: boolean;
}) => {
  const [detailsShow, setDetailsShow] = useState<boolean>(false);

  return (
    <div className={`bg-slate-100 rounded-2xl`} key={item.id}>
      {detailsShow && (
        <EmailInspirationDetails
          open={detailsShow}
          onOpenChange={() => setDetailsShow(false)}
          data={item}
          IsPublic={false}
        />
      )}

      <div className={`${isEqualWidth ? 'w-full h-[7.25rem]' : 'min-w-[10.7rem] w-[10.7rem] h-[10.7rem]'} relative overflow-hidden rounded-md`}>
        <img
          src={
            item.imageUrl ??
            'https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs'
          }
          alt={`${item.brandName ?? 'CreativeOs'} Image`}
          onClick={() => setDetailsShow(true)}
          loading="eager"
          className={`${isEqualWidth ? 'w-full h-[7.25rem]' : 'min-w-[10.7rem] w-[10.7rem] h-[10.7rem]'} rounded-2xl object-cover object-center scale-[200%] origin-top cursor-pointer lg:hvr-grow`}
        />
      </div>
    </div>
  );
};

export default SearchDialogEmailInspirationImage;
