import {
  CreativeOSFeatures,
  CreativeOSPages,
  PlanType,
  subscriptionPlans,
} from "@/_shared/subscriptionPlans";
import { PRO_PLAN } from "@/utils/data/plans";
import { trpc } from "@/utils/trpc";
import React from "react";

const useUserPermissions = () => {
  const {
    data: permissions,
    isLoading,
    error,

  } = trpc.permissions.useQuery(null, {
    staleTime: 1000 * 60 * 2, // Cache data for 2 minutes
  });
  const { data: userLimits, isLoading: isGettingUserLimits , isRefetching: isRefetchingUserLimits} =
    trpc.getUserLimits.useQuery(undefined, {
      refetchOnWindowFocus: false,
    });

  const currentPlanConfigs =
    (permissions?.usersPlans as PlanType[])?.map(
      (planId: PlanType) => subscriptionPlans[planId]
    ) ?? [];

  const userHasAccessToPage = React.useMemo(
    () =>
      (page?: CreativeOSPages): boolean => {
        if (!page) return true;
        return currentPlanConfigs.some(
          (config) =>
            config.accessiblePages &&
            Object.keys(config.accessiblePages).includes(page)
        );
      },
    [currentPlanConfigs]
  );

  const userHasAccessToFeature = React.useMemo(
    () =>
      (feature?: CreativeOSFeatures): boolean => {
        if (!feature) return true;
        return (
          currentPlanConfigs.some((config) =>
            config.features.includes(feature)
          ) &&
          (feature == CreativeOSFeatures.AD_INSPIRATION_REQUEST
            ? !!userLimits && userLimits.request.ad > 0
            : feature == CreativeOSFeatures.EMAIL_INSPIRATION_REQUEST
              ? !!userLimits && userLimits.request.email > 0
              : feature == CreativeOSFeatures.LANDER_INSPIRATION_REQUEST
                ? !!userLimits && userLimits.request.lander > 0
                : feature == CreativeOSFeatures.USE_CREATIVE_TOOLKIT
                  ? !!userLimits && userLimits.creativeToolkit > 0
                : feature == CreativeOSFeatures.ADD_NEW_COLLECTION
                  ? !!userLimits && userLimits.userCollections > 0
                : true)
        );
      },
    [currentPlanConfigs]
  );

  return {
    userLimits,
    isGettingUserLimits,
    isRefetchingUserLimits,
    isProUser: permissions ? permissions.usersPlans.includes(PRO_PLAN.no_trial_plan_id) ||
    permissions.usersPlans.includes(PRO_PLAN.plan_id)
        : false,
    permissions,
    isLoading,
    error,
    currentPlanConfigs,
    userHasAccessToPage,
    userHasAccessToFeature,
  };
};

export default useUserPermissions;
