import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import { Link } from "@tanstack/react-router";
import AccessControl from "./AccessControl";
import { Stack, Text } from "./custom-components";
import { LockTagLargeNoBg } from "./custom-components/LockTag";

const SearchDialogBrandImage = ({
  brandId,
  totalAdCount,
  totalEmailCount,
  totalLanderCount,
  brandName,
  brandImage,
}: {
  brandId: string;
  brandName: string;
  brandImage?: string | null;
  totalAdCount: number;
  totalEmailCount: number;
  totalLanderCount: number;
}) => {
  return (
    <AccessControl
      feature={CreativeOSFeatures.BRANDS_AD_INSPIRATION_NAV_BUTTON}
      FeatureUnlocked={
        <Link
          to={`/feeds/brands/$brandID`}
          params={{ brandID: brandId }}
          className={`rounded-2xl cursor-pointer`}
          key={brandId}
        >
          <div className="flex gap-3 items-center justify-between w-full ">
            <div className="flex gap-2 items-center">
              <img
                src={
                  brandImage ??
                  "https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs"
                }
                alt={`${name ?? "CreativeOs"} Image`}
                className="min-w-[4rem] w-[4rem] h-[4rem] rounded-full object-cover cursor-pointer"
              />
              <div className="flex gap-1 items-center flex-wrap">
                <Text
                  size={"xs"}
                  weight={"medium"}
                  className="text-themeforeground w-fit !mr-3"
                >
                  {brandName}
                </Text>
              </div>
            </div>

            <div className="flex items-center gap-1 mr-5">
              <Stack className="items-end">
                {totalAdCount == 0 ? null : (
                  <Text
                    size={"xs"}
                    weight={"thin"}
                    className="text-gray-400 w-fit"
                  >
                    {totalAdCount}
                  </Text>
                )}
                {totalEmailCount == 0 ? null : (
                  <Text
                    size={"xs"}
                    weight={"thin"}
                    className="text-gray-400 w-fit"
                  >
                    {totalEmailCount}
                  </Text>
                )}
                {totalLanderCount == 0 ? null : (
                  <Text
                    size={"xs"}
                    weight={"thin"}
                    className="text-gray-400 w-fit"
                  >
                    {totalLanderCount}
                  </Text>
                )}
              </Stack>
              <Stack>
                {totalAdCount == 0 ? null : (
                  <Text
                    size={"xs"}
                    weight={"thin"}
                    className="text-gray-400 text-start w-9"
                  >
                    Ads
                  </Text>
                )}
                {totalEmailCount == 0 ? null : (
                  <Text
                    size={"xs"}
                    weight={"thin"}
                    className="text-gray-400 w-9 text-start"
                  >
                    Emails
                  </Text>
                )}
                {totalLanderCount == 0 ? null : (
                  <Text
                    size={"xs"}
                    weight={"thin"}
                    className="text-gray-400 w-9 text-start"
                  >
                    Landers
                  </Text>
                )}
              </Stack>
            </div>
          </div>
        </Link>
      }
      FeatureLocked={
        <div className="flex gap-3 items-center justify-between w-full rounded-2xl">
          <div className="pl-2 flex gap-2 items-center">
            <div className="relative">
              <img
                src={
                  brandImage ??
                  "https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs"
                }
                alt={`${name ?? "CreativeOs"} Image`}
                className="min-w-[4rem] w-[4rem] h-[4rem] rounded-full object-cover cursor-pointer  blur-sm"
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="bg-black bg-opacity-50 rounded-full p-0.5">
                  <LockTagLargeNoBg />
                </div>
              </div>
            </div>
            <div className="flex gap-1 items-center flex-wrap">
              <Text
                size={"xs"}
                weight={"medium"}
                className="text-themeforeground w-fit !mr-3"
              >
                {brandName}
              </Text>
            </div>
          </div>

          <div className="flex items-center gap-1 mr-5">
            <Stack className="items-end">
              {totalAdCount == 0 ? null : (
                <Text
                  size={"xs"}
                  weight={"thin"}
                  className="text-gray-400 w-fit"
                >
                  {totalAdCount}
                </Text>
              )}
              {totalEmailCount == 0 ? null : (
                <Text
                  size={"xs"}
                  weight={"thin"}
                  className="text-gray-400 w-fit"
                >
                  {totalEmailCount}
                </Text>
              )}
              {totalLanderCount == 0 ? null : (
                <Text
                  size={"xs"}
                  weight={"thin"}
                  className="text-gray-400 w-fit"
                >
                  {totalLanderCount}
                </Text>
              )}
            </Stack>
            <Stack>
              {totalAdCount == 0 ? null : (
                <Text
                  size={"xs"}
                  weight={"thin"}
                  className="text-gray-400 text-start w-9"
                >
                  Ads
                </Text>
              )}
              {totalEmailCount == 0 ? null : (
                <Text
                  size={"xs"}
                  weight={"thin"}
                  className="text-gray-400 w-9 text-start"
                >
                  Emails
                </Text>
              )}
              {totalLanderCount == 0 ? null : (
                <Text
                  size={"xs"}
                  weight={"thin"}
                  className="text-gray-400 w-9 text-start"
                >
                  Landers
                </Text>
              )}
            </Stack>
          </div>
        </div>
      }
    />
  );
};

export default SearchDialogBrandImage;
