import { trpc } from '@/utils/trpc';
import { useNavigate } from '@tanstack/react-router';

export default function useLogout() {
  const { mutateAsync: logoutUser, isPending: loggingOut } =
    trpc.logoutUser.useMutation();

  const navigate = useNavigate();

  function logout() {
    logoutUser().then(() => {
      if (typeof localStorage !== 'undefined') {
        localStorage.clear();
      }
      navigator?.serviceWorker?.getRegistrations().then((registrations) => {
        return Promise.all(
          registrations.map((registration) => {
            return registration.unregister();
          })
        );
      });
      caches?.keys().then((keyList) => {
        return Promise.all(
          keyList.map((key) => {
            return caches.delete(key);
          })
        );
      });
      navigate({ to: '/login' });
    });
  }

  return {
    logout,
    loggingOut,
  };
}
