import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  httpBatchLink,
  splitLink,
  unstable_httpSubscriptionLink,
} from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import "hover.css";
import { useEffect, useState } from "react";
import type { AppRouter } from "../../../src/server";
import "./App.css";

import memberstackDOM from "@memberstack/dom";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import "./lib/amplitude";

export const memberstack = memberstackDOM.init({
  publicKey: "pk_971c0de956b797603818",
});

import { TooltipProvider } from "@/components/shadcn/tooltip.tsx";
import { usePostHog } from "posthog-js/react";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";

export const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export const trpc = createTRPCReact<AppRouter>();

const publicPages: Array<string> = ['/share/templates', "/share/inspiration"];

export const fetchWithRefresh = async (
  url: RequestInfo | URL,
  options?: RequestInit
) => {
  let response = await fetch(url, {
    ...options,
    credentials: "include",
  });

  if (response.status === 401) {

    const currentPath = window.location.pathname;

    const isPublicPage = publicPages.some((page) =>
      currentPath.startsWith(page)
    );

    // Check if the user is logged in
    const userString = localStorage.getItem("_wos_user");
    const user = userString ? JSON.parse(userString) : null;

    if (!user) {
      // If user is not logged in, return original response
      return response;
    }

    // Attempt to refresh the session if user is logged in
    const refreshResponse = await fetch(
      `${import.meta.env.VITE_API_URL}refreshSession`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (refreshResponse.ok) {
      const body = await refreshResponse.json();
      if (body?.result?.data?.code === "UNAUTHORIZED") {
        // If refresh fails, redirect to login unless it's a semi-public page
        if (!isPublicPage) {
          localStorage.clear();
          document.body.classList.toggle("dark", false);
          window.location.href = "/login";
        }
        throw new Error("Failed to refresh token, please log in again");
      }

      // Retry the original request after refreshing the token
      response = await fetch(url, {
        ...options,
        credentials: "include",
      });
    } else {
      // Handle failed refresh (e.g., redirect to login)
      if (!isPublicPage) {
        localStorage.clear();
        document.body.classList.toggle("dark", false);
        window.location.href = "/login";
      }
      throw new Error("Failed to refresh token, please log in again");
    }
  }

  return response;
};

// Function to show/hide the reCAPTCHA badge
export const toggleCaptchaBadge = (show: boolean) => {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? "visible" : "hidden";
  }
};

export function App() {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        splitLink({
          condition(op) {
            return op.type === "subscription"; // Direct subscriptions to SSE
          },
          true: unstable_httpSubscriptionLink({
            url: import.meta.env.VITE_API_URL as string, // SSE URL
            eventSourceOptions() {
              return {
                withCredentials: true, // <---
              };
            },
          }),
          false: httpBatchLink({
            url: import.meta.env.VITE_API_URL as string, // Normal API requests
            fetch: fetchWithRefresh, // Ensure proper token refresh
          }),
        }),
      ],
    })
  );
  const posthog = usePostHog();

  const userString = localStorage?.getItem("_wos_user");
  const user = userString ? JSON.parse(userString) : null;

  useEffect(() => {
    window.Chargebee.init({
      site: import.meta.env.VITE_CHARGEBEE_SITE,
      publishableKey: import.meta.env.VITE_CHARGEBEE_PUBLISHABLE_KEY,
    });

    // Refresh session every 4 minutes
    const interval = setInterval(
      async () => {
        try {
          await fetch(`${import.meta.env.VITE_API_URL}refreshSession`, {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          });
        } catch (error) {
          console.error("Failed to refresh session:", error);
        }
      },
      12 * 60 * 1000
    );

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (user && user.email && user.id) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.id, {
        email: user.email,
      });
    }
  }, [posthog, user]);

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <TooltipProvider>
          <RouterProvider router={router} />
        </TooltipProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
