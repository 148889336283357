import {
  CreativeOSFeatures,
  CreativeOSPages,
} from "@/_shared/subscriptionPlans";
import useUserPermissions from "@/hooks/useUserPermissions";
import { trpc } from "@/utils/trpc";
import { ArrowRight, Search } from "@mynaui/icons-react";
import { Link } from "@tanstack/react-router";
import AccessControl from "./AccessControl";
import SearchDialogAdInspirationImage from "./SearchDialogAdInspirationImage";
import SearchDialogAdTemplateImage from "./SearchDialogAdTemplateImage";
import SearchDialogBrandImage from "./SearchDialogBrandImage";
import SearchDialogEmailInspirationImage from "./SearchDialogEmailInspirationImage";
import SearchDialogEmailLanderTemplateImage from "./SearchDialogEmailTemplateImage";
import SearchDialogExpertImage from "./SearchDialogExpertImage";
import SearchDialogLanderInspirationImage from "./SearchDialogLanderInspirationImage";
import { Stack, Text } from "./custom-components";
import { Loader } from "./custom-components/Loader";
import { LockTagSmall } from "./custom-components/LockTag";
import { ScrollArea } from "./shadcn/scroll-area";

const TypingSearchView = ({
  searchTerm,
  debouncedSearchTerm,
}: {
  searchTerm: string;
  debouncedSearchTerm: string;
}) => {
  const { data, isLoading } = trpc.globalSearch.useQuery(
    {
      query: searchTerm,
      limit: 4,
    },
    {
      enabled: searchTerm.length > 0,
    }
  );
  const [
    ad_templates,
    email_templates,
    lander_templates,
    brands,
    experts,
    email_inspirations,
    ad_inspirations,
    lander_inspirations,
  ] = data || [];
  const { currentPlanConfigs } = useUserPermissions();

  return (
    <ScrollArea
      type="always"
      className="w-full flex-1 max-h-[70svh] lg:max-h-[85svh] pb-0"
    >
      {debouncedSearchTerm !== searchTerm || isLoading ? (
        <Stack className="h-full gap-3 w-full flex flex-col justify-center items-center">
          <Loader />
        </Stack>
      ) : (data ?? []).every(
          (category) => (category?.data ?? []).length === 0
        ) ? (
        <Stack className="gap-3 w-full py-60 items-center">
          <Search className="h-16 w-16 text-gray-300" aria-hidden="true" />
          <Text
            weight={"normal"}
            className="text-gray-300 text-5xl text-center w-full"
          >
            No results found
          </Text>
        </Stack>
      ) : (
        <Stack className="gap-3 w-full mb-10">
          {(ad_templates?.data ?? []).length > 0 ? (
            <Text size={"lg"} weight={"semibold"}>
              Ad Templates
            </Text>
          ) : null}
          <div className="flex items-center gap-2 overflow-x-auto overflow-y-hidden">
            {ad_templates?.data.map((item) => (
              <SearchDialogAdTemplateImage
                key={item["Creative ID"]}
                item={{
                  ...item,
                  form: "",
                  "Example Image": [],
                  "Template Image": [],
                  Status: "",
                  AdDbId: null,
                  Expert: undefined,
                  Tags: undefined,
                  Liked: item.Liked ?? false,
                  "Canva Template LInk": item["Canva Template LInk"] ?? "",
                }}
              />
            ))}
          </div>
          {(ad_templates?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <AccessControl
                feature={CreativeOSFeatures.SEARCH_AD_TEMPLATE_NAV_BUTTON}
                FeatureUnlocked={
                  <Link
                    className="text-slate-500"
                    to="/feeds/search/templates/ads"
                    search={{ searchTerm, cacheBuster: Date.now() }}
                  >
                    <span className="flex items-center gap-2 text-sm">
                      <span>See all</span> <ArrowRight size={"1rem"} />
                    </span>
                  </Link>
                }
                FeatureLocked={
                  <span className="flex items-center gap-2 text-sm opacity-50">
                    <span>See all</span> <LockTagSmall />
                  </span>
                }
              />
            </div>
          ) : null}
          {(email_templates?.data ?? []).length > 0 ? (
            <Text size={"lg"} weight={"semibold"}>
              Email Templates
            </Text>
          ) : null}
          <div className="flex items-center gap-2 overflow-x-auto overflow-y-hidden">
            {email_templates?.data.slice(
                0,
                [
                  ...currentPlanConfigs
                    .map(
                      (config) =>
                        config.accessiblePages?.[
                          CreativeOSPages.SEARCH_EMAIL_TEMPLATES
                        ]?.limit
                    )
                    .filter(
                      (limit) => limit !== undefined && limit !== "infinity"
                    ),
                  (lander_templates?.data ?? []).length,
                ][0]
              ).map((item) => (
              <SearchDialogEmailLanderTemplateImage
                key={item["Email ID"]}
                item={{
                  Screenshot: item.imageUrl,
                  ID: item["Email ID"].toString(),
                  created: item.Created,
                  category: item.Category ?? "",
                  isSaved: item.isSaved,
                  imageUrl: item.imageUrl,
                  brandId: item.brandId,
                  brandName: item.brandName,
                  brandImage: item.brandImage,
                }}
                collections={item.Category ? [item.Category] : []}
                type="email"
              />
            ))}
            {email_templates?.data.slice(
                [
                  ...currentPlanConfigs
                    .map(
                      (config) =>
                        config.accessiblePages?.[
                          CreativeOSPages.SEARCH_EMAIL_TEMPLATES
                        ]?.limit
                    )
                    .filter(
                      (limit) => limit !== undefined && limit !== "infinity"
                    ),
                  (lander_templates?.data ?? []).length,
                ][0],
                (lander_templates?.data ?? []).length
              ).map((item) => (
              <SearchDialogEmailLanderTemplateImage
                key={item["Email ID"]}
                feature={CreativeOSFeatures.LOCKED}
                item={{
                  Screenshot: item.imageUrl,
                  ID: item["Email ID"].toString(),
                  created: item.Created,
                  category: item.Category ?? "",
                  isSaved: item.isSaved,
                  imageUrl: item.imageUrl,
                  brandId: item.brandId,
                  brandName: item.brandName,
                  brandImage: item.brandImage,
                }}
                collections={item.Category ? [item.Category] : []}
                type="email"
              />
            ))}
          </div>

          {(email_templates?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <AccessControl
                feature={CreativeOSFeatures.SEARCH_EMAIL_TEMPLATE_NAV_BUTTON}
                FeatureUnlocked={
                  <Link
                    className="text-slate-500"
                    to="/feeds/search/templates/emails"
                    search={{ searchTerm, cacheBuster: Date.now() }}
                  >
                    <span className="flex items-center gap-2 text-sm">
                      <span>See all</span> <ArrowRight size={"1rem"} />
                    </span>
                  </Link>
                }
                FeatureLocked={
                  <span className="flex items-center gap-2 text-sm opacity-50">
                    <span>See all</span> <LockTagSmall />
                  </span>
                }
              />
            </div>
          ) : null}
          {(lander_templates?.data ?? []).length > 0 ? (
            <Text size={"lg"} weight={"semibold"}>
              Lander Templates
            </Text>
          ) : null}
          <div className="flex items-center gap-2 overflow-x-auto overflow-y-hidden">
            {lander_templates?.data
              .slice(
                0,
                [
                  ...currentPlanConfigs
                    .map(
                      (config) =>
                        config.accessiblePages?.[
                          CreativeOSPages.SEARCH_LANDER_TEMPLATES
                        ]?.limit
                    )
                    .filter(
                      (limit) => limit !== undefined && limit !== "infinity"
                    ),
                  (lander_templates?.data ?? []).length,
                ][0]
              )
              .map((item) => (
                <SearchDialogEmailLanderTemplateImage
                  key={item["LP ID"]}
                  item={{
                    Screenshot: item.imageUrl,
                    ID: item["LP ID"].toString(),
                    created: item.Created,
                    category: item.Category ?? "",
                    isSaved: item.isSaved,
                    imageUrl: item.imageUrl,
                    brandId: item.brandId,
                    brandName: item.brandName,
                    brandImage: item.brandImage,
                  }}
                  collections={item.Category ? [item.Category] : []}
                  type="landing-page"
                />
              ))}
            {lander_templates?.data
              .slice(
                [
                  ...currentPlanConfigs
                    .map(
                      (config) =>
                        config.accessiblePages?.[
                          CreativeOSPages.SEARCH_LANDER_TEMPLATES
                        ]?.limit
                    )
                    .filter(
                      (limit) => limit !== undefined && limit !== "infinity"
                    ),
                  (lander_templates?.data ?? []).length,
                ][0],
                (lander_templates?.data ?? []).length
              )
              .map((item) => (
                <SearchDialogEmailLanderTemplateImage
                  key={item["LP ID"]}
                  feature={CreativeOSFeatures.LOCKED}
                  item={{
                    Screenshot: item.imageUrl,
                    ID: item["LP ID"].toString(),
                    created: item.Created,
                    category: item.Category ?? "",
                    isSaved: item.isSaved,
                    imageUrl: item.imageUrl,
                    brandId: item.brandId,
                    brandName: item.brandName,
                    brandImage: item.brandImage,
                  }}
                  collections={item.Category ? [item.Category] : []}
                  type="landing-page"
                />
              ))}
          </div>

          {(lander_templates?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <AccessControl
                feature={CreativeOSFeatures.SEARCH_LANDER_TEMPLATE_NAV_BUTTON}
                FeatureUnlocked={
                  <Link
                    className="text-slate-500"
                    to="/feeds/search/templates/landing-pages"
                    search={{ searchTerm }}
                  >
                    <span className="flex items-center gap-2 text-sm">
                      <span>See all</span> <ArrowRight size={"1rem"} />
                    </span>
                  </Link>
                }
                FeatureLocked={
                  <span className="flex items-center gap-2 text-sm opacity-50">
                    <span>See all</span> <LockTagSmall />
                  </span>
                }
              />
            </div>
          ) : null}
          <Stack className="hidden lg:flex lg:flex-col gap-3">
            {(experts?.data ?? []).length > 0 ? (
              <Text size={"lg"} weight={"semibold"}>
                Experts
              </Text>
            ) : null}
            {experts?.data.map((item) => (
              <SearchDialogExpertImage
                key={item.name}
                name={item.name}
                expertImage={item.image}
                speciality={item.speciality}
                totalAdCount={item.totalAdCount}
                totalEmailCount={item.totalEmailCount}
                description={item.description ?? ""}
                atId={item.atId}
              />
            ))}
            {(experts?.data ?? []).length > 0 ? (
              <div className="w-full flex justify-end pr-3">
                <AccessControl
                  feature={CreativeOSFeatures.EXPERTS_SEARCH_NAV_BUTTON}
                  FeatureUnlocked={
                    <Link
                      className="text-slate-500"
                      to="/feeds/search/experts"
                      search={{ searchTerm }}
                    >
                      <span className="flex items-center gap-2 text-sm">
                        <span>See all</span> <ArrowRight size={"1rem"} />
                      </span>
                    </Link>
                  }
                  FeatureLocked={
                    <span className="flex items-center gap-2 text-sm opacity-50">
                      <span>See all</span> <LockTagSmall />
                    </span>
                  }
                />
              </div>
            ) : null}
          </Stack>
          {(ad_inspirations?.data ?? []).length > 0 ? (
            <Text size={"lg"} weight={"semibold"}>
              Ad Inspiration
            </Text>
          ) : null}
          <div className="flex gap-2 items-center overflow-x-auto overflow-y-hidden">
            {ad_inspirations?.data.map((item) => (
              <SearchDialogAdInspirationImage
                key={item.id}
                item={{
                  id: item.id,
                  reach: null,
                  earliestView: "",
                  adRunningDays: 0,
                  imageUrl: item.imageUrl ?? "",
                  videoUrl: item.videoUrl ?? "",
                  brandId: item.brandId ?? null,
                  brandName: item.brandName ?? null,
                  brandImage: item.brandImage ?? null,
                  isSaved: item.isSaved ?? false,
                }}
              />
            ))}
          </div>
          {(ad_inspirations?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <Link
                className="text-slate-500"
                to="/feeds/search/inspiration/ads"
                search={{ searchTerm }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={"1rem"} />
                </span>
              </Link>
            </div>
          ) : null}
          {(email_inspirations?.data ?? []).length > 0 ? (
            <Text size={"lg"} weight={"semibold"}>
              Email Inspiration
            </Text>
          ) : null}

          <div className="flex gap-2 items-center overflow-x-auto overflow-y-hidden">
            {email_inspirations?.data.map((item) => (
              <SearchDialogEmailInspirationImage
                item={{
                  ...item,
                  requestCount: 0,
                  template: null,
                  to: "",
                  imageUrl: item.imageUrl ?? "",
                  subject: item.subject ?? "",
                  homepage: item.homepage ?? "",
                  brandId: item.brandId ?? null,
                  brandName: item.brandName ?? null,
                  brandImage: item.brandImage ?? null,
                }}
              />
            ))}
          </div>
          {(email_inspirations?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <Link
                className="text-slate-500"
                to="/feeds/search/inspiration/emails"
                search={{ searchTerm }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={"1rem"} />
                </span>
              </Link>
            </div>
          ) : null}
          {(lander_inspirations?.data ?? []).length > 0 ? (
            <Text size={"lg"} weight={"semibold"}>
              Lander Inspiration
            </Text>
          ) : null}
          <div className="flex gap-2 items-center overflow-x-auto overflow-y-hidden">
            {lander_inspirations?.data.map((item) => (
              <SearchDialogLanderInspirationImage
                item={{
                  ...item,
                  requestCount: 0,
                  template: null,
                  landerUrl: item.landerUrl ?? "",
                  desktopScreenshot: item.desktopScreenshot ?? "",
                  mobileScreenshot: item.mobileScreenshot ?? "",
                  brandId: item.brandId ?? null,
                  brandName: item.brandName ?? null,
                  brandImage: item.brandImage ?? null,
                }}
              />
            ))}
          </div>
          {(lander_inspirations?.data ?? []).length > 0 ? (
            <div className="w-full flex justify-end pr-3">
              <Link
                className="text-slate-500"
                to="/feeds/search/inspiration/landing-pages"
                search={{ searchTerm }}
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={"1rem"} />
                </span>
              </Link>
            </div>
          ) : null}

          <Stack className="hidden lg:flex lg:flex-col gap-3">
            {(brands?.data ?? []).length > 0 ? (
              <Text size={"lg"} weight={"semibold"}>
                Brands
              </Text>
            ) : null}
            {brands?.data.map((item) => (
              <SearchDialogBrandImage
                key={item.brandId}
                brandName={item.brandName}
                totalAdCount={item.totalAdCount}
                totalEmailCount={item.totalEmailCount}
                totalLanderCount={item.totalLanderCount}
                brandImage={item.brandImage}
                brandId={item.brandId}
              />
            ))}
            {(brands?.data ?? []).length > 0 ? (
              <div className="w-full flex justify-end pr-3">
                <AccessControl
                  feature={CreativeOSFeatures.BRANDS_SEARCH_NAV_BUTTON}
                  FeatureUnlocked={
                    <Link
                      className="text-slate-500"
                      to="/feeds/search/brands"
                      search={{ searchTerm }}
                    >
                      <span className="flex items-center gap-2 text-sm">
                        <span>See all</span> <ArrowRight size={"1rem"} />
                      </span>
                    </Link>
                  }
                  FeatureLocked={
                    <span className="flex items-center gap-2 text-sm opacity-50">
                      <span>See all</span> <LockTagSmall />
                    </span>
                  }
                />
              </div>
            ) : null}
          </Stack>
        </Stack>
      )}
    </ScrollArea>
  );
};

export default TypingSearchView;
