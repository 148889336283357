import { FREE_PLAN_ID, IGNITE_PLAN_ID, IGNITE_PLAN_NO_TRIAL, PRO_PLAN_ID, PRO_PLAN_NO_TRIAL, SPARK_PLAN_ID, SPARK_PLAN_NO_TRIAL } from "../../../../src/shared/constants";

export type PlanType = typeof FREE_PLAN_ID | typeof IGNITE_PLAN_ID | typeof IGNITE_PLAN_NO_TRIAL | typeof SPARK_PLAN_ID | typeof SPARK_PLAN_NO_TRIAL | typeof PRO_PLAN_ID | typeof PRO_PLAN_NO_TRIAL;

export enum CreativeOSPages {
  AD_TEMPLATES = "/feeds/templates",
  EMAIL_TEMPLATES = "/feeds/templates/emails",
  LANDER_TEMPLATES = "/feeds/templates/landing-pages",
  SEARCH_AD_TEMPLATES = "/feeds/search/templates",
  SEARCH_EMAIL_TEMPLATES = "/feeds/search/templates/emails",
  SEARCH_LANDER_TEMPLATES = "/feeds/search/templates/landing-pages",

  AD_INSPIRATIONS = "/feeds/inspiration",
  EMAIL_INSPIRATIONS = "/feeds/inspiration/emails",
  LANDER_INSPIRATIONS = "/feeds/inspiration/landing-pages",
  SEARCH_AD_INSPIRATIONS = "/feeds/search/inspiration",
  SEARCH_EMAIL_INSPIRATIONS = "/feeds/search/inspiration/emails",
  SEARCH_LANDER_INSPIRATIONS = "/feeds/search/inspiration/landing-pages",

  BRANDS = "/feeds/brands",
  SEARCH_BRANDS = "/feeds/search/brands",
  BRANDS_AD_INSPIRATIONS = "/feeds/brands/$id",
  BRANDS_EMAIL_INSPIRATIONS = "/feeds/brands/$id/emails",
  BRANDS_LANDER_INSPIRATIONS = "/feeds/brands/$id/landing-pages",

  CREATIVE_TOOLKIT="/feeds/creative-toolkit",
  CREATIVE_TOOLKIT_COPY_GRADER="/feeds/creative-toolkit/copy-grader",
  CREATIVE_TOOLKIT_AD_TEST_CALCULATOR="/feeds/creative-toolkit/ad-test-calculator",

  LIVE_COLLECTIONS ="/feeds/live-trends/",
  LIVE_EVERGREEN = "/feeds/evergreen",
  LIVE_TESTING_LAB = "/feeds/hidden-gems",
  LIVE_HIDDEN_GEMS = "/feeds/testing-labs",

  ALL_COLLECTIONS="/feeds/collections",
  CREATIVEOS_COLLECTIONS="/feeds/collections/creativeos",
  EXPERT_COLLECTIONS="/feeds/collections/experts",

  COLLECTION_DETAILS='/feeds/collection/$id',
  EXPERT_AD_COLLECTION_DETAILS='/feeds/collections/experts/ad/$ID',
  EXPERT_EMAIL_COLLECTION_DETAILS='/feeds/collections/experts/email/$ID',

  //

  REQUEST_SYSTEM = "Request System",
  BRAND_LIBRARY = "Brand Library",
  
}

export enum CreativeOSFeatures {
  AD_TEMPLATE_NAV_BUTTON = "Ad Template Nav Button",
  EMAIL_TEMPLATE_NAV_BUTTON = "Email Template Nav Button",
  SHOW_AD_TEMPLATE_DETAILS= "Show Ad Template Details",
  SHOW_SEARCH_AD_TEMPLATE_IMAGE = "Show Search Ad Template Image",
  SHOW_SEARCH_EMAIL_TEMPLATE_IMAGE = "Show Search Email Template Image",
  SHOW_SEARCH_LANDER_TEMPLATE_IMAGE = "Show Search Lander Template Image",
  LANDER_TEMPLATE_NAV_BUTTON = "Lander Template Nav Button",
  SEARCH_AD_TEMPLATE_NAV_BUTTON = "Search Ad Template Nav Button",
  SEARCH_EMAIL_TEMPLATE_NAV_BUTTON = "Search Email Template Nav Button",
  SEARCH_LANDER_TEMPLATE_NAV_BUTTON = "Search Lander Template Nav Button",

  AD_INSPIRATION_NAV_BUTTON = "Ad Inspiration Nav Button",
  EMAIL_INSPIRATION_NAV_BUTTON = "Email Inspiration Nav Button",
  LANDER_INSPIRATION_NAV_BUTTON = "Lander Inspiration Nav Button",
  SEARCH_AD_INSPIRATION_NAV_BUTTON = "Search Ad Inspiration Nav Button",
  SEARCH_EMAIL_INSPIRATION_NAV_BUTTON = "Search Email Inspiration Nav Button",
  SEARCH_LANDER_INSPIRATION_NAV_BUTTON = "Search Lander Inspiration Nav Button",

  AD_INSPIRATION_REQUEST = "Ad Inspiration Request",
  EMAIL_INSPIRATION_REQUEST = "Email Inspiration Request",
  LANDER_INSPIRATION_REQUEST = "Lander Inspiration Request",

  SHOW_SEARCH_BRANDS_BUTTON = "Show Search Brands Button",
  BRANDS_AD_INSPIRATION_NAV_BUTTON = "Brands Ad Inspiration Nav Button",
  BRANDS_EMAIL_INSPIRATION_NAV_BUTTON = "Brands Email Inspiration Nav Button",
  BRANDS_LANDER_INSPIRATION_NAV_BUTTON = "Brands Lander Inspiration Nav Button",
  BRANDS_SEARCH_NAV_BUTTON = "Brands Search Nav Button",
  BRANDS_PAGE_NAV_BUTTON = "Brands Page Nav Button",

  LIVE_COLLECTIONS ="Live Collections",
  LIVE_COLLECTIONS_EVERGREEN_NAV_BUTTON = "Live Collection Evergreen Nav Button",
  LIVE_COLLECTIONS_TESTING_LAB_NAV_BUTTON = "Live Collection Testing Lab Nav Button",
  LIVE_COLLECTIONS_HIDDEN_GEMS_NAV_BUTTON = "Live Collection Hidden Gems Nav Button",

  ALL_COLLECTIONS ="All Collections",
  CREATIVEOS_COLLECTIONS = 'CreativeOS Collections',
  EXPERT_COLLECTIONS = 'Expert Collections',
  EXPERT_AD_COLLECTION_DETAILS_NAV_BUTTON='Expert Ad Collection Details Nav Button',
  EXPERT_EMAIL_COLLECTION_DETAILS_NAV_BUTTON='Expert Email Collection Details Nav Button',
  SHOW_SEARCH_EXPERTS_BUTTON = "Show Search Experts Button",
  EXPERTS_SEARCH_NAV_BUTTON = "Experts Search Nav Button",
  ADD_NEW_COLLECTION = "Add New Collection",
  MY_COLLECTIONS = "My Collections",
  USE_CREATIVE_TOOLKIT = "Use Creative Toolkit",

  SAVE="SAVE",
  UPGRADE="UPGRADE",
  LOCKED="LOCKED", // This is here to force lock some features
}

export interface CreativeOSAccessConfig {
  limit: number | "infinity";
}

export interface PlanDetails {
  name: string,
  price: string,
  annual_price: string,
  amount_saved: string,
  amount_saved_per_month?: number | string,
  annual_monthly_price: string,
  plan_id:string,
  yearly_plan_id: string,
  no_trial_plan_id: string,
  description:
  string,
  summary:
  string,
  features: Array<string>,
  nonFeatures?: Array<string>
}

export interface PlanConfig {
  name: string;
  features: CreativeOSFeatures[];
  requestLimits: {ads: number | "unlimited", emails: number | "unlimited", lander: number | "unlimited"};
  myCollectionLimit: number | "unlimited";
  accessiblePages?: Partial<Record<CreativeOSPages, CreativeOSAccessConfig>>;
  planDetails: PlanDetails
}

export const freePlanFeaturesAndPages: PlanConfig={
  name: "Free Plan",
  requestLimits:{ads: 0, emails: 0, lander: 0},
  myCollectionLimit: 1,
  features: [
    //Template Tab Buttons (Normal and Search)

    //Inspiration Tab Buttons (Normal and Search)
    CreativeOSFeatures.AD_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_AD_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.EMAIL_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_EMAIL_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.LANDER_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_LANDER_INSPIRATION_NAV_BUTTON,

    //Brand Tab Buttons (Normal and Search)

    //Collection Features
    CreativeOSFeatures.ADD_NEW_COLLECTION,

    //Live Trends
    CreativeOSFeatures.LIVE_COLLECTIONS
  ],
  accessiblePages: {
    //Template Tab Buttons (Normal and Search)

    //Inspiration Pages (Normal and Search)
    [CreativeOSPages.AD_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_AD_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.EMAIL_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_EMAIL_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.LANDER_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_LANDER_INSPIRATIONS]: { limit: "infinity" },

    //Brands page (Normal and Search)

    //Creative Toolkit Pages
    [CreativeOSPages.CREATIVE_TOOLKIT]: { limit: 3},

    //Collection Pages

    // Live Trends
    [CreativeOSPages.LIVE_COLLECTIONS]: { limit: "infinity" },

  },
  planDetails: {
    name: 'Free Plan',
    price: 'Free',
    annual_price: '$299',
    annual_monthly_price: '$25',
    amount_saved: '$289',
    plan_id: FREE_PLAN_ID,
    yearly_plan_id: FREE_PLAN_ID,
    no_trial_plan_id: FREE_PLAN_ID,
    description: 'The perfect plan to find inspiration. Fast.',
    summary:
      'The perfect plan to start testing ads faster. 3-Day Trial, Cancel Anytime.',
    features: ['Inspiration Library', '1 Collection'],
  }
};
export const sparkPlanFeaturesAndPages: PlanConfig={
  name: "Spark Plan",
  requestLimits:{ads: 1, emails: 0, lander: 0},
  myCollectionLimit: 3,
  features: [
    //Template Tab Buttons (Normal and Search)
    CreativeOSFeatures.AD_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_AD_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SHOW_AD_TEMPLATE_DETAILS,
    CreativeOSFeatures.SHOW_SEARCH_AD_TEMPLATE_IMAGE,
    CreativeOSFeatures.EMAIL_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_EMAIL_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SHOW_SEARCH_EMAIL_TEMPLATE_IMAGE,
    CreativeOSFeatures.LANDER_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_LANDER_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SHOW_SEARCH_LANDER_TEMPLATE_IMAGE,

    //Inspiration Tab Buttons (Normal and Search)
    CreativeOSFeatures.AD_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_AD_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.EMAIL_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_EMAIL_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.LANDER_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_LANDER_INSPIRATION_NAV_BUTTON,

    // Requests
    CreativeOSFeatures.AD_INSPIRATION_REQUEST,

    // CreativeToolkit
    CreativeOSFeatures.USE_CREATIVE_TOOLKIT,

    //Brand Tab Buttons (Normal and Search)
    CreativeOSFeatures.BRANDS_AD_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.BRANDS_EMAIL_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.BRANDS_LANDER_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SHOW_SEARCH_BRANDS_BUTTON,
    CreativeOSFeatures.BRANDS_SEARCH_NAV_BUTTON,
    CreativeOSFeatures.BRANDS_PAGE_NAV_BUTTON,

    //Collection Features
    CreativeOSFeatures.ADD_NEW_COLLECTION,

    //Live Trends
    CreativeOSFeatures.LIVE_COLLECTIONS,
    CreativeOSFeatures.LIVE_COLLECTIONS_EVERGREEN_NAV_BUTTON,
    CreativeOSFeatures.LIVE_COLLECTIONS_TESTING_LAB_NAV_BUTTON,
    CreativeOSFeatures.LIVE_COLLECTIONS_HIDDEN_GEMS_NAV_BUTTON

  ],
  accessiblePages: {
    //Template Tab Buttons (Normal and Search)
    [CreativeOSPages.AD_TEMPLATES]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_AD_TEMPLATES]: { limit: "infinity" },
    [CreativeOSPages.EMAIL_TEMPLATES]: { limit: 1 },
    [CreativeOSPages.SEARCH_EMAIL_TEMPLATES]: { limit: 1 },
    [CreativeOSPages.LANDER_TEMPLATES]: { limit: 1 },
    [CreativeOSPages.SEARCH_LANDER_TEMPLATES]: { limit: 1 },
    
    //Inspiration Pages (Normal and Search)
    [CreativeOSPages.AD_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_AD_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.EMAIL_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_EMAIL_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.LANDER_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_LANDER_INSPIRATIONS]: { limit: "infinity" },

    //Brands page (Normal and Search)
    [CreativeOSPages.BRANDS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_BRANDS]: { limit: "infinity" },
    [CreativeOSPages.BRANDS_AD_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.BRANDS_EMAIL_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.BRANDS_LANDER_INSPIRATIONS]: { limit: "infinity" },

    //Creative Toolkit Pages
    [CreativeOSPages.CREATIVE_TOOLKIT]: { limit: 3},
    [CreativeOSPages.CREATIVE_TOOLKIT_AD_TEST_CALCULATOR]: { limit: 3},
    [CreativeOSPages.CREATIVE_TOOLKIT_COPY_GRADER]: { limit: 3},

    //Collection Pages
    [CreativeOSPages.COLLECTION_DETAILS]: { limit: "infinity" },

    // Live Trends
    [CreativeOSPages.LIVE_COLLECTIONS]: { limit: "infinity" },
    [CreativeOSPages.LIVE_EVERGREEN]: { limit: "infinity" },
    [CreativeOSPages.LIVE_HIDDEN_GEMS]: { limit: "infinity" },
    [CreativeOSPages.LIVE_TESTING_LAB]: { limit: "infinity" },

  },
  planDetails: {
    name: 'Spark Plan',
    price: '$19',
    annual_price: '$299',
    annual_monthly_price: '$25',
    amount_saved: '$289',
    plan_id: SPARK_PLAN_ID,
    yearly_plan_id: SPARK_PLAN_ID,
    no_trial_plan_id: SPARK_PLAN_NO_TRIAL,
    description: 'The perfect plan to find inspiration. Fast.',
    summary:
      'The perfect plan to start testing ads faster. 3-Day Trial, Cancel Anytime.',
    features: ['Inspiration Library', '1 Collection'],
  }
};
export const ignitePlanFeaturesAndPages:PlanConfig={
  name: "Ignite Plan",
  requestLimits:{ads: 5, emails: 3, lander: 1},
  myCollectionLimit: 10,
  features: [
    //Template Tab Buttons (Normal and Search)
    CreativeOSFeatures.AD_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_AD_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SHOW_AD_TEMPLATE_DETAILS,
    CreativeOSFeatures.SHOW_SEARCH_AD_TEMPLATE_IMAGE,
    CreativeOSFeatures.EMAIL_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_EMAIL_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SHOW_SEARCH_EMAIL_TEMPLATE_IMAGE,
    CreativeOSFeatures.LANDER_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_LANDER_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SHOW_SEARCH_LANDER_TEMPLATE_IMAGE,

    //Inspiration Tab Buttons (Normal and Search)
    CreativeOSFeatures.AD_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_AD_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.EMAIL_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_EMAIL_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.LANDER_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_LANDER_INSPIRATION_NAV_BUTTON,

    // Requests
    CreativeOSFeatures.AD_INSPIRATION_REQUEST,
    CreativeOSFeatures.EMAIL_INSPIRATION_REQUEST,
    CreativeOSFeatures.LANDER_INSPIRATION_REQUEST,

    // CreativeToolkit
    CreativeOSFeatures.USE_CREATIVE_TOOLKIT,

    //Brand Tab Buttons (Normal and Search)
    CreativeOSFeatures.BRANDS_AD_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.BRANDS_EMAIL_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.BRANDS_LANDER_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SHOW_SEARCH_BRANDS_BUTTON,
    CreativeOSFeatures.BRANDS_SEARCH_NAV_BUTTON,
    CreativeOSFeatures.BRANDS_PAGE_NAV_BUTTON,


    //Collection Features
    CreativeOSFeatures.ADD_NEW_COLLECTION,
    CreativeOSFeatures.ALL_COLLECTIONS,
    CreativeOSFeatures.CREATIVEOS_COLLECTIONS,
    CreativeOSFeatures.EXPERT_COLLECTIONS,
    CreativeOSFeatures.EXPERT_AD_COLLECTION_DETAILS_NAV_BUTTON,
    CreativeOSFeatures.EXPERT_EMAIL_COLLECTION_DETAILS_NAV_BUTTON,
    CreativeOSFeatures.SHOW_SEARCH_EXPERTS_BUTTON,
    CreativeOSFeatures.EXPERTS_SEARCH_NAV_BUTTON,

    //Live Trends
    CreativeOSFeatures.LIVE_COLLECTIONS,
    CreativeOSFeatures.LIVE_COLLECTIONS_EVERGREEN_NAV_BUTTON,
    CreativeOSFeatures.LIVE_COLLECTIONS_TESTING_LAB_NAV_BUTTON,
    CreativeOSFeatures.LIVE_COLLECTIONS_HIDDEN_GEMS_NAV_BUTTON
  ],
  accessiblePages: {
    //Template Tab Buttons (Normal and Search)
    [CreativeOSPages.AD_TEMPLATES]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_AD_TEMPLATES]: { limit: "infinity" },
    [CreativeOSPages.EMAIL_TEMPLATES]: { limit: 10 },
    [CreativeOSPages.SEARCH_EMAIL_TEMPLATES]: { limit: 10 },
    [CreativeOSPages.LANDER_TEMPLATES]: { limit: 1 },
    [CreativeOSPages.SEARCH_LANDER_TEMPLATES]: { limit: 1 },

    //Inspiration Pages (Normal and Search)
    [CreativeOSPages.AD_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_AD_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.EMAIL_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_EMAIL_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.LANDER_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_LANDER_INSPIRATIONS]: { limit: "infinity" },

    //Brands page (Normal and Search)
    [CreativeOSPages.BRANDS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_BRANDS]: { limit: "infinity" },
    [CreativeOSPages.BRANDS_AD_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.BRANDS_EMAIL_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.BRANDS_LANDER_INSPIRATIONS]: { limit: "infinity" },

    //Creative Toolkit Pages
    [CreativeOSPages.CREATIVE_TOOLKIT]: { limit: "infinity" },
    [CreativeOSPages.CREATIVE_TOOLKIT_AD_TEST_CALCULATOR]: { limit: "infinity" },
    [CreativeOSPages.CREATIVE_TOOLKIT_COPY_GRADER]: { limit: "infinity" },

    //Collection Pages
    [CreativeOSPages.CREATIVEOS_COLLECTIONS]: { limit: "infinity" },
    [CreativeOSPages.EXPERT_COLLECTIONS]: { limit: "infinity" },
    [CreativeOSPages.ALL_COLLECTIONS]: { limit: "infinity" },
    [CreativeOSPages.EXPERT_AD_COLLECTION_DETAILS]: { limit: "infinity" },
    [CreativeOSPages.EXPERT_EMAIL_COLLECTION_DETAILS]: { limit: "infinity" },
    [CreativeOSPages.COLLECTION_DETAILS]: { limit: "infinity" },

     // Live Trends
     [CreativeOSPages.LIVE_COLLECTIONS]: { limit: "infinity" },
     [CreativeOSPages.LIVE_EVERGREEN]: { limit: "infinity" },
     [CreativeOSPages.LIVE_HIDDEN_GEMS]: { limit: "infinity" },
     [CreativeOSPages.LIVE_TESTING_LAB]: { limit: "infinity" },

  },
  planDetails: {
    name: 'Ignite Plan',
    price: '$49',
    annual_price: '$299',
    annual_monthly_price: '$25',
    amount_saved: '$289',
    plan_id: IGNITE_PLAN_ID,
    yearly_plan_id: 'pln_creative-os-standard-hoq20qmr-usd-yearly',
    no_trial_plan_id: IGNITE_PLAN_NO_TRIAL,
    description:
      "Fuel your brand's creative journey with enhanced features and powerful tools.",
    summary:
      'The perfect plan to start testing ads faster. 3-Day Trial, Cancel Anytime.',
    features: [
      '5,500+ Static Ad Templates',
      '10 New Daily Creatives',
      'Inspiration Library',
    ],
    nonFeatures: ['Request New Templates', 'Email and Landing Pages Templates'],
  }
};
export const proPlanFeaturesAndPages: PlanConfig = {
  name: "Pro Plan",
  requestLimits:{ads: "unlimited", emails: "unlimited", lander: "unlimited"},
  myCollectionLimit: "unlimited",
  features: [
    //Template Tab Buttons (Normal and Search)
    CreativeOSFeatures.AD_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_AD_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SHOW_AD_TEMPLATE_DETAILS,
    CreativeOSFeatures.SHOW_SEARCH_AD_TEMPLATE_IMAGE,
    CreativeOSFeatures.EMAIL_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_EMAIL_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SHOW_SEARCH_EMAIL_TEMPLATE_IMAGE,
    CreativeOSFeatures.LANDER_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_LANDER_TEMPLATE_NAV_BUTTON,
    CreativeOSFeatures.SHOW_SEARCH_LANDER_TEMPLATE_IMAGE,

    //Inspiration Tab Buttons (Normal and Search)
    CreativeOSFeatures.AD_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_AD_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.EMAIL_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_EMAIL_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.LANDER_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SEARCH_LANDER_INSPIRATION_NAV_BUTTON,

    // Requests
    CreativeOSFeatures.AD_INSPIRATION_REQUEST,
    CreativeOSFeatures.EMAIL_INSPIRATION_REQUEST,
    CreativeOSFeatures.LANDER_INSPIRATION_REQUEST,

    // CreativeToolkit
    CreativeOSFeatures.USE_CREATIVE_TOOLKIT,

    //Brand Tab Buttons (Normal and Search)
    CreativeOSFeatures.BRANDS_AD_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.BRANDS_EMAIL_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.BRANDS_LANDER_INSPIRATION_NAV_BUTTON,
    CreativeOSFeatures.SHOW_SEARCH_BRANDS_BUTTON,
    CreativeOSFeatures.BRANDS_SEARCH_NAV_BUTTON,
    CreativeOSFeatures.BRANDS_PAGE_NAV_BUTTON,

    //Collection Features
    CreativeOSFeatures.ALL_COLLECTIONS,
    CreativeOSFeatures.CREATIVEOS_COLLECTIONS,
    CreativeOSFeatures.EXPERT_COLLECTIONS,
    CreativeOSFeatures.EXPERT_AD_COLLECTION_DETAILS_NAV_BUTTON,
    CreativeOSFeatures.EXPERT_EMAIL_COLLECTION_DETAILS_NAV_BUTTON,
    CreativeOSFeatures.SHOW_SEARCH_EXPERTS_BUTTON,
    CreativeOSFeatures.EXPERTS_SEARCH_NAV_BUTTON,
    CreativeOSFeatures.ADD_NEW_COLLECTION,

    //Live Trends
    CreativeOSFeatures.LIVE_COLLECTIONS,
    CreativeOSFeatures.LIVE_COLLECTIONS_EVERGREEN_NAV_BUTTON,
    CreativeOSFeatures.LIVE_COLLECTIONS_TESTING_LAB_NAV_BUTTON,
    CreativeOSFeatures.LIVE_COLLECTIONS_HIDDEN_GEMS_NAV_BUTTON
  ],
  accessiblePages: {
    //Template Tab Buttons (Normal and Search)
    [CreativeOSPages.AD_TEMPLATES]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_AD_TEMPLATES]: { limit: "infinity" },
    [CreativeOSPages.EMAIL_TEMPLATES]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_EMAIL_TEMPLATES]: { limit: "infinity" },
    [CreativeOSPages.LANDER_TEMPLATES]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_LANDER_TEMPLATES]: { limit: "infinity" },

    //Inspiration Pages (Normal and Search)
    [CreativeOSPages.AD_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_AD_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.EMAIL_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_EMAIL_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.LANDER_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_LANDER_INSPIRATIONS]: { limit: "infinity" },

    //Brands page (Normal and Search)
    [CreativeOSPages.BRANDS]: { limit: "infinity" },
    [CreativeOSPages.SEARCH_BRANDS]: { limit: "infinity" },
    [CreativeOSPages.BRANDS_AD_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.BRANDS_EMAIL_INSPIRATIONS]: { limit: "infinity" },
    [CreativeOSPages.BRANDS_LANDER_INSPIRATIONS]: { limit: "infinity" },

    //Creative Toolkit Pages
    [CreativeOSPages.CREATIVE_TOOLKIT]: { limit: "infinity" },
    [CreativeOSPages.CREATIVE_TOOLKIT_AD_TEST_CALCULATOR]: { limit: "infinity" },
    [CreativeOSPages.CREATIVE_TOOLKIT_COPY_GRADER]: { limit: "infinity" },

     //Collection Pages
     [CreativeOSPages.CREATIVEOS_COLLECTIONS]: { limit: "infinity" },
     [CreativeOSPages.EXPERT_COLLECTIONS]: { limit: "infinity" },
     [CreativeOSPages.ALL_COLLECTIONS]: { limit: "infinity" },
     [CreativeOSPages.EXPERT_AD_COLLECTION_DETAILS]: { limit: "infinity" },
     [CreativeOSPages.EXPERT_EMAIL_COLLECTION_DETAILS]: { limit: "infinity" },
     [CreativeOSPages.COLLECTION_DETAILS]: { limit: "infinity" },

     // Live Trends
     [CreativeOSPages.LIVE_COLLECTIONS]: { limit: "infinity" },
     [CreativeOSPages.LIVE_EVERGREEN]: { limit: "infinity" },
     [CreativeOSPages.LIVE_HIDDEN_GEMS]: { limit: "infinity" },
     [CreativeOSPages.LIVE_TESTING_LAB]: { limit: "infinity" },
  },
  planDetails: {
    name: 'Pro Plan',
    price: '$99',
    annual_price: '$499',
    amount_saved: '$689',
    amount_saved_per_month: 40,
    annual_monthly_price: '$41',
    plan_id: PRO_PLAN_ID,
    yearly_plan_id: 'pln_creative-os-pro-hdpx0qjo-USD-Yearly',
    no_trial_plan_id: PRO_PLAN_NO_TRIAL,
    description:
      'Take your brands creativity to the next level with premium, advanced tools to maximize potential.',
    summary:
      "Supercharge your brand's creative output. 3-Day Trial, Cancel Anytime.",
    features: [
      'Everything in Ignite',
      'Request New Templates',
      'Email Template Library',
      'Landing Page Templates',
      'Industry Expert Packs',
    ],
  }
}

export const subscriptionPlans: Record<PlanType, PlanConfig> = {
  [FREE_PLAN_ID]: freePlanFeaturesAndPages,
  [SPARK_PLAN_ID]: sparkPlanFeaturesAndPages,
  [SPARK_PLAN_NO_TRIAL]: sparkPlanFeaturesAndPages,
  [IGNITE_PLAN_ID]: ignitePlanFeaturesAndPages,
  [IGNITE_PLAN_NO_TRIAL]: ignitePlanFeaturesAndPages,
  [PRO_PLAN_ID]: proPlanFeaturesAndPages,
  [PRO_PLAN_NO_TRIAL]: proPlanFeaturesAndPages,
};

export function getUniquePlanDetails(includeFreePlan: boolean): PlanDetails[] {
  return Array.from(new Set(Object.values(subscriptionPlans))).map((plan)=>plan.planDetails).filter((plan)=> includeFreePlan ? true : plan.plan_id !== FREE_PLAN_ID);
}
