import React from "react";

type WatermarkImageProps = {
  imageUrl: string;
  watermarkText?: string;
  watermarkImage?: string;
  watermarkWidth?: number;
  watermarkHeight?: number;
};

const WatermarkImage: React.FC<WatermarkImageProps> = ({
  imageUrl,
  watermarkText = "CreativeOS",
  watermarkImage,
  watermarkWidth = 100,
  watermarkHeight = 100,
}) => {
  return (
    <div className="w-full h-full overflow-hidden rounded-md">
      <img
        src={imageUrl}
        alt="Watermarked"
        className="w-full h-full object-cover"
      />
      <div className="absolute w-[700px] inset-[-100px] flex flex-wrap items-center justify-center gap-8 opacity-30 rotate-[-30deg]">
        {Array.from({ length: 30 }).map((_, i) =>
          watermarkImage ? (
            <img
              key={i}
              src={watermarkImage}
              alt="Watermark"
              className="opacity-30"
              style={{ width: watermarkWidth, height: watermarkHeight }}
            />
          ) : (
            <span key={i} className="text-gray-500 text-2xl font-bold">
              {watermarkText}
            </span>
          )
        )}
      </div>
    </div>
  );
};

export default WatermarkImage;
