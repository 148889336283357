import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import { trpc } from "@/utils/trpc";
import { ArrowRight } from "@mynaui/icons-react";
import { Link } from "@tanstack/react-router";
import { useState } from "react";
import AccessControl from "./AccessControl";
import FreeTrialEnding from "./FreeTrialEnding";
import { PermissionData } from "./SearchDialog";
import SearchDialogAdTemplateImage from "./SearchDialogAdTemplateImage";
import SearchDialogEmailLanderTemplateImage from "./SearchDialogEmailTemplateImage";
import { LockTagSmall } from "./custom-components/LockTag";
import { ScrollArea, ScrollBar } from "./shadcn/scroll-area";
import { Skeleton } from "./shadcn/skeleton";

const SearchDialogTemplateSection = ({
  permissions,
}: {
  permissions: PermissionData;
}) => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: randomAdTemplates, isLoading: adTemplatesIsLoading } =
    trpc.posts.useQuery({
      sortingOptions: "Recent",
      Tags: undefined,
      limit: 3,
      Ready: true,
    });

  const { data: randomEmailTemplates, isLoading: emailTemplatesIsLoading } =
    trpc.emails.useQuery({
      sortingOptions: "Recent",
      limit: 3,
    });

  const { data: randomLandersTemplates, isLoading: landerTemplatesIsLoading } =
    trpc.landingPages.useQuery({
      sortingOptions: "Recent",
      limit: 3,
    });

  return (
    <div className="flex flex-col gap-2 pb-6">
      {upgradeDialogOpen && permissions && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissions}
        />
      )}
      <div className="flex flex-col gap-0">
        <h6 className="text-sm">Ads</h6>
        <div className="flex gap-2 h-[12.8063rem]">
          {adTemplatesIsLoading ? (
            <div className="flex space-x-2.5">
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  className="min-w-[11.4063rem] w-[11.4063rem] h-[11.4063rem] rounded-2xl"
                />
              ))}
            </div>
          ) : (
            <ScrollArea className="whitespace-nowrap space-y-2.5">
              <div className="flex space-x-2.5 pr-5">
                {randomAdTemplates?.ATRecords.map((item) => (
                  <SearchDialogAdTemplateImage
                    key={item["Creative ID"]}
                    item={{
                      ...item,
                      form: "",
                      "Example Image": [],
                      "Template Image": [],
                      Status: "",
                      AdDbId: null,
                      Expert: undefined,
                      Tags: undefined,
                      Liked: item.Liked ?? false,
                      "Canva Template LInk": item["Canva Template LInk"] ?? "",
                    }}
                  />
                ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </div>

        <div className="flex justify-end mr-3">
          <AccessControl
            feature={CreativeOSFeatures.AD_TEMPLATE_NAV_BUTTON}
            FeatureUnlocked={
              <Link className="text-slate-500" to="/feeds/templates">
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={"1rem"} />
                </span>
              </Link>
            }
            FeatureLocked={
              <span className="flex items-center gap-2 text-sm opacity-50">
                <span>See all</span> <LockTagSmall />
              </span>
            }
          />
        </div>
      </div>
      <div className="flex flex-col gap-0">
        <h6 className="text-sm">Emails</h6>
        <div className="flex gap-2 h-[12.8063rem]">
          {emailTemplatesIsLoading ? (
            <div className="flex space-x-2.5">
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  className="min-w-[11.4063rem] w-[11.4063rem] h-[11.4063rem] rounded-2xl"
                />
              ))}
            </div>
          ) : (
            <ScrollArea className="whitespace-nowrap space-y-2.5">
              <div className="flex space-x-2.5 pr-5">
                {randomEmailTemplates?.Emails.map((item) => (
                  <SearchDialogEmailLanderTemplateImage
                    key={item["Email ID"]}
                    item={{
                      Screenshot: item["Email Screenshot"],
                      ID: item["Email ID"].toString(),
                      created: item.Created,
                      category: item.Category ?? "",
                      isSaved: item.isSaved,
                      imageUrl: item.imageUrl,
                      brandId: item.brandId,
                      brandName: item.brandName,
                      brandImage: item.brandImage,
                    }}
                    type="email"
                  />
                ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </div>

        <div className="flex justify-end mr-3">
          <div className="flex justify-end mr-3">
            <AccessControl
              feature={CreativeOSFeatures.EMAIL_TEMPLATE_NAV_BUTTON}
              FeatureUnlocked={
                <Link
                  className="text-slate-500"
                  to="/feeds/templates/emails"
                  search={true}
                >
                  <span className="flex items-center gap-2 text-sm">
                    <span>See all</span> <ArrowRight size={"1rem"} />
                  </span>
                </Link>
              }
              FeatureLocked={
                <span className="flex items-center gap-2 text-sm opacity-50">
                  <span>See all</span> <LockTagSmall />
                </span>
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-0">
        <h6 className="text-sm">Landers</h6>
        <div className="flex gap-2 h-[12.8063rem]">
          {landerTemplatesIsLoading ? (
            <div className="flex space-x-2.5">
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  className="min-w-[11.4063rem] w-[11.4063rem] h-[11.4063rem] rounded-2xl"
                />
              ))}
            </div>
          ) : (
            <ScrollArea className="whitespace-nowrap space-y-2.5">
              <div className="flex space-x-2.5 pr-5">
                {randomLandersTemplates?.LandingPages.map((item) => (
                  <SearchDialogEmailLanderTemplateImage
                    key={item["LP ID"]}
                    item={{
                      Screenshot: item["Landing Page Screenshot"],
                      ID: item["LP ID"].toString(),
                      created: item.Created,
                      category: item.Category ?? "",
                      isSaved: item.isSaved,
                      imageUrl: item.imageUrl,
                      brandId: item.brandId,
                      brandName: item.brandName,
                      brandImage: item.brandImage,
                    }}
                    type="landing-page"
                  />
                ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </div>

        <div className="flex justify-end mr-3">
          <AccessControl
            feature={CreativeOSFeatures.LANDER_TEMPLATE_NAV_BUTTON}
            FeatureUnlocked={
              <Link
                className="text-slate-500"
                to="/feeds/templates/landing-pages"
              >
                <span className="flex items-center gap-2 text-sm">
                  <span>See all</span> <ArrowRight size={"1rem"} />
                </span>
              </Link>
            }
            FeatureLocked={
              <span className="flex items-center gap-2 text-sm opacity-50">
                <span>See all</span> <LockTagSmall />
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SearchDialogTemplateSection;
