import OnboardingHeader from '@/components/onboarding/OnboardingHeader.tsx';
import OnboardingSteppers from '@/components/onboarding/OnboardingSteppers.tsx';
import { ProtectedArea } from '@/components/protectedArea.tsx';
import { Badge } from '@/components/shadcn/badge.tsx';
import { Button } from '@/components/shadcn/button.tsx';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/shadcn/form';
import { RadioGroup, RadioGroupItem } from '@/components/shadcn/radio';
import { Tabs, TabsList, TabsTrigger } from '@/components/shadcn/tabs.tsx';
import { featureFlagKeys } from '@/utils/data/featureFlags';
import { PRO_PLAN, SPARK_PLAN, STANDARD_PLAN, plans } from '@/utils/data/plans.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { CheckCircle } from '@mynaui/icons-react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocalStorage } from 'usehooks-ts';
import { z } from 'zod';

export const Route = createFileRoute('/plans')({
  component: Plans,
});

function Plans() {
  const annualFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.annualPlans);

  const navigate = useNavigate();

  const selectPlanFormSchema = z.object({
    plan: z.string({ required_error: 'Select a plan' }),
  });

  type SelectPlanFormValues = z.infer<typeof selectPlanFormSchema>;

  const defaultValues: Partial<SelectPlanFormValues> = {};

  const [period, setPeriod] = useState<'monthly' | 'annually'>('monthly');
  const [, set_cos_plan] = useLocalStorage<string>("cos_plan", "");

  function checkoutPlan(selectedPlan: string) {
    if (selectedPlan) {
      set_cos_plan( selectedPlan); // Store selected plan in localStorage
      setTimeout(() => {
        navigate({ to: '/checkout' });
      }, 500);
    }
  }

  useLayoutEffect(() => {
    document.body.classList.toggle("dark", false);
  },[])
  
  const form = useForm<SelectPlanFormValues>({
    resolver: zodResolver(selectPlanFormSchema),
    defaultValues,
  });

  async function onSubmit() {
    const selectedPlan = form.getValues('plan');
    if (selectedPlan) {
      set_cos_plan( selectedPlan); // Store selected plan in localStorage
      setTimeout(() => {
        navigate({ to: '/checkout' });
      }, 500);
    }
  }

  return (
    <ProtectedArea>
      <div
        className={'bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen'}
      >
        <div>
          <OnboardingHeader />
        </div>
        <div
          className={
            'flex-1 flex flex-col justify-center items-center py-[7.75rem]'
          }
        >
          {annualFlagEnabled ? (
            <div
              className={
                'rounded-lg bg-white p-5 w-11/12 lg:max-w-2xl flex flex-col gap-6 border'
              }
            >
              <div className={'flex flex-col items-start gap-6'}>
                <OnboardingSteppers stepIndex={0} />
                <h4
                  className={
                    'text-2xl text-center text-themeforeground font-semibold'
                  }
                >
                  Select your plan
                </h4>
              </div>

              <div
                className={
                  'flex flex-col md:flex-row justify-between gap-3 items-center'
                }
              >
                <p className={'text-sm'}>
                  <span className={'font-light'}>All plans include a</span>{' '}
                  <span className={'font-semibold'}>3-Day Free Trial.</span>
                </p>
                <Tabs defaultValue={period}>
                  <TabsList className={'rounded-full'}>
                    <TabsTrigger
                      className={'rounded-full'}
                      value={'monthly'}
                      onClick={() => setPeriod('monthly')}
                    >
                      Pay Monthly
                    </TabsTrigger>
                    <TabsTrigger
                      className={'rounded-full flex gap-1.5'}
                      value={'annually'}
                      onClick={() => setPeriod('annually')}
                    >
                      <span>Pay Yearly</span>{' '}
                      <span className={'text-themedestructive'}>Save 50%</span>
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
              <div className={'grid grid-cols-1 lg:grid-cols-2 gap-3'}>
                {STANDARD_PLAN && (
                  <div className={'space-y-3 p-4 border-2 rounded-lg'}>
                    <div>
                      <h3 className={'font-medium'}>{STANDARD_PLAN.name}</h3>
                      <p className={'mt-1'}>
                        <span className={'text-2xl font-semibold'}>
                          {period === 'monthly'
                            ? STANDARD_PLAN.price
                            : STANDARD_PLAN.annual_monthly_price}
                        </span>{' '}
                        / month, billed {period}
                      </p>
                      <p
                        className={
                          'text-sm font-light text-thememutedforeground'
                        }
                      >
                        {period === 'monthly' ? (
                          'Save 50% when billed annually'
                        ) : (
                          <span className={'line-through'}>
                            {STANDARD_PLAN.price} / billed monthly
                          </span>
                        )}
                      </p>
                    </div>
                    <Button
                      size={'sm'}
                      className={'w-full'}
                      onClick={() =>
                        checkoutPlan(
                          period === 'monthly'
                            ? STANDARD_PLAN.plan_id
                            : STANDARD_PLAN.yearly_plan_id,
                        )
                      }
                    >
                      Select Plan
                    </Button>
                    <div>
                      <p
                        className={
                          'text-sm font-light text-thememutedforeground'
                        }
                      >
                        {STANDARD_PLAN.summary}
                      </p>
                      <ul className={'space-y-3 mt-2'}>
                        {STANDARD_PLAN.features.map((feature, index) => (
                          <li
                            key={index}
                            className="flex gap-1 justify-start items-center text-thememutedforeground"
                          >
                            <CheckCircle className="w-4 h-4" />
                            <span className="text-sm font-normal leading-5 ">
                              {feature}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {SPARK_PLAN && (
                  <div className={'space-y-3 p-4 border-2 rounded-lg'}>
                    <div>
                      <h3 className={'font-medium'}>{SPARK_PLAN.name}</h3>
                      <p className={'mt-1'}>
                        <span className={'text-2xl font-semibold'}>
                          {period === 'monthly'
                            ? SPARK_PLAN.price
                            : SPARK_PLAN.annual_monthly_price}
                        </span>{' '}
                        / month, billed {period}
                      </p>
                      <p
                        className={
                          'text-sm font-light text-thememutedforeground'
                        }
                      >
                        {period === 'monthly' ? (
                          'Save 50% when billed annually'
                        ) : (
                          <span className={'line-through'}>
                            {SPARK_PLAN.price} / billed monthly
                          </span>
                        )}
                      </p>
                    </div>
                    <Button
                      size={'sm'}
                      className={'w-full'}
                      onClick={() =>
                        checkoutPlan(
                          period === 'monthly'
                            ? SPARK_PLAN.plan_id
                            : SPARK_PLAN.yearly_plan_id,
                        )
                      }
                    >
                      Select Plan
                    </Button>
                    <div>
                      <p
                        className={
                          'text-sm font-light text-thememutedforeground'
                        }
                      >
                        {SPARK_PLAN.summary}
                      </p>
                      <ul className={'space-y-3 mt-2'}>
                        {SPARK_PLAN.features.map((feature, index) => (
                          <li
                            key={index}
                            className="flex gap-1 justify-start items-center text-thememutedforeground"
                          >
                            <CheckCircle className="w-4 h-4" />
                            <span className="text-sm font-normal leading-5 ">
                              {feature}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {PRO_PLAN && (
                  <div
                    className={
                      'space-y-3 p-4 border-2 border-themedestructive rounded-lg'
                    }
                  >
                    <div>
                      <div className={'flex gap-2 items-center'}>
                        <h3 className={'font-medium'}>{PRO_PLAN.name}</h3>
                        <Badge variant={'secondaryDestructive'}>
                          Recommended
                        </Badge>
                      </div>
                      <p>
                        <span className={'text-2xl font-semibold'}>
                          {period === 'monthly'
                            ? PRO_PLAN.price
                            : PRO_PLAN.annual_monthly_price}
                        </span>{' '}
                        / month, billed {period}
                      </p>
                      <p
                        className={
                          'text-sm font-light text-thememutedforeground'
                        }
                      >
                        {period === 'monthly' ? (
                          'Save 50% when billed annually'
                        ) : (
                          <span className={'line-through'}>
                            {PRO_PLAN.price} / billed monthly
                          </span>
                        )}
                      </p>
                    </div>
                    <Button
                      variant={'destructivePurple'}
                      size={'sm'}
                      className={'w-full'}
                      onClick={() =>
                        checkoutPlan(
                          period === 'monthly'
                            ? PRO_PLAN.plan_id
                            : PRO_PLAN.yearly_plan_id,
                        )
                      }
                    >
                      Select Plan
                    </Button>
                    <div>
                      <p
                        className={
                          'text-sm font-light text-thememutedforeground'
                        }
                      >
                        {PRO_PLAN.summary}
                      </p>
                      <ul className={'space-y-3 mt-2'}>
                        {PRO_PLAN.features.map((feature, index) => (
                          <li
                            key={index}
                            className="flex gap-1 justify-start items-center text-thememutedforeground"
                          >
                            <CheckCircle className="w-4 h-4" />
                            <span className="text-sm font-normal leading-5 ">
                              {feature}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className={
                'rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border'
              }
            >
              <div className={'flex flex-col items-start gap-6'}>
                <OnboardingSteppers stepIndex={0} />
                <h4
                  className={
                    'text-2xl text-center text-themeforeground font-semibold'
                  }
                >
                  Select your plan
                </h4>
              </div>
              <div className={'grid gap-5'}>
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-6"
                  >
                    <FormField
                      control={form.control}
                      name="plan"
                      render={({ field }) => (
                        <RadioGroup
                          onValueChange={field.onChange}
                          value={field.value}
                          className="flex flex-col space-y-1"
                        >
                          {plans &&
                            plans.map((item) => (
                              <FormControl key={item.plan_id}>
                                <FormItem>
                                  <RadioGroupItem
                                    checked={field.value === item.plan_id}
                                    value={item.plan_id}
                                    onChange={() =>
                                      field.onChange(item.plan_id)
                                    }
                                    id={item.name}
                                    plan={item}
                                  >
                                    {item.name}
                                  </RadioGroupItem>
                                </FormItem>
                              </FormControl>
                            ))}
                        </RadioGroup>
                      )}
                    />
                    <div className={'flex justify-end items-center space-x-2'}>
                      <Button
                        type={'submit'}
                        className={'px-8'}
                        variant={'default'}
                        size={'lg'}
                      >
                        {'Next'}
                      </Button>
                    </div>
                  </form>
                </Form>
              </div>
            </div>
          )}
        </div>
      </div>
    </ProtectedArea>
  );
}
