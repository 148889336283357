import { useState } from "react";
import AdInspirationDetails from "./AdInspirationDetails";
import { Ad } from "./templates/DiscoverGridView";

const SearchDialogAdInspirationImage = ({
  item, isEqualWidth
}: {
  item: Ad; isEqualWidth?: boolean
}) => {
  const [detailsShow, setDetailsShow] = useState<boolean>(false);

  return (
    <div
      className={`bg-slate-100 rounded-2xl`}
      key={item.id}
    >
      {detailsShow && item && (
        <AdInspirationDetails
          data={item}
          open={detailsShow}
          IsPublic={false}
          onOpenChange={() => setDetailsShow(false)}
        />
      )}
      {item.imageUrl ? (
        <img
          src={
            item.imageUrl ??
            "https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs"
          }
          alt={`${item.brandName ?? "CreativeOs"} Image`}
          onClick={() => setDetailsShow(true)}
          loading="eager"
          className={`${isEqualWidth ? 'w-full h-[7.25rem]' : 'min-w-[10.7rem] w-[10.7rem] h-[10.7rem]'} rounded-2xl object-cover cursor-pointer hvr-grow`}
        />
      ) : item.videoUrl ? (
        <video
          className={`${isEqualWidth ? 'w-full h-[7.25rem]' : 'min-w-[10.7rem] w-[10.7rem] h-[10.7rem]'} rounded-2xl object-cover cursor-pointer hvr-grow`}
          muted
          loop
          controls={false}
          onClick={() => setDetailsShow(true)}
          autoPlay // Ensure video autoplays
          playsInline // For mobile devices (iOS in particular)
          src={item.videoUrl}
        ></video>
      ) : null}
    </div>
  );
};

export default SearchDialogAdInspirationImage;
