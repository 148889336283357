import { trpc } from '@/App';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { toast } from 'sonner';

export const useDowngradeUserPlan = () => {
  const [isDowngradeSuccess, setDowngradeSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleMutationError = (error: { message: string }) => {
    if (error instanceof Error) {
      if (error.message.includes('already present')) {
        toast.error('This account already exists.');
        setTimeout(() => navigate({ to: '/feeds/templates' }), 2000);
      } else {
        toast.error(error.message);
      }
    } else {
      toast.error('An unknown error occurred');
    }
  };

  const handleMutationSuccess = (response: { success: boolean }) => {
    if (response?.success) {
      setDowngradeSuccess(true);
      toast.success('Your subscription has been downgraded successfully!');
    }
  };

  // For moving from a higher plan to a lower one
  const { isPending: downgradeIsPending, mutate: downgradeUserPlan } =
    trpc.downgradeUserPlan.useMutation({
      onSuccess: handleMutationSuccess,
      onError: handleMutationError,
    });

  // Users who intend to cancel gets the discounted pro plan
  const { isPending: discountedUpgradeIsPending, mutate: discountedUpgrade } =
    trpc.addCouponToPlan.useMutation({
      onSuccess: handleMutationSuccess,
      onError: handleMutationError,
    });

  return {
    downgradeUserPlan,
    downgradeIsPending,
    isDowngradeSuccess,
    discountedUpgradeIsPending,
    discountedUpgrade,
  };
};
