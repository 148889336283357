import { trpc } from '@/utils/trpc';
import { useEffect, useState } from 'react';

const useMonitorSubscription = (checkoutSuccessful: boolean) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(checkoutSuccessful);

  trpc.monitorSubscriptionStatus.useSubscription(undefined, {
    enabled, // Stops the subscription when false
    onData(update: { isSubscribed: boolean }) {
      if (update.isSubscribed) {
        setIsSuccess(true);
        setIsLoading(false);
        setEnabled(false);
      }
    },
    onError() {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (checkoutSuccessful) {
      setEnabled(true);
    }
  }, [checkoutSuccessful]);

  return { subscriptionSuccessful: isSuccess, isLoading };
};

export default useMonitorSubscription;
