import { CheckoutUpgradeSubscription } from '@/components/CheckoutSubscriptionPlan.tsx';
import { Loader } from '@/components/custom-components/Loader';
import { Button } from '@/components/shadcn/button';
import {
  Dialog,
  DialogContent,
  DialogPortal,
} from '@/components/shadcn/dialog';
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
} from '@/components/shadcn/sidebar.tsx';
import { Tabs, TabsList, TabsTrigger } from '@/components/shadcn/tabs.tsx';
import useLogout from '@/hooks/useLogout';
import showToastNotification from '@/hooks/useShowToast';
import useUserPermissions from '@/hooks/useUserPermissions';
import { featureFlagKeys } from '@/utils/data/featureFlags';
import {
  FREE_PLAN,
  // DOWNGRADE_COUPON,
  PRO_PLAN,
  PlanDetail,
  SPARK_PLAN,
  STANDARD_PLAN,
  SUBSCRIPTION_STATUSES,
  plans,
} from '@/utils/data/plans.ts';
import { trpc } from '@/utils/trpc';
import { CreditCard, Message, User } from '@mynaui/icons-react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import DowngradePlan from './DowngradePlan';
import UpgradeBox from './UpgradeBox';
import { UserProfileEdit } from './UserProfileEdit';
import UserProfileSettingDetail from './UserProfileSettingDetail';
import { Stack, Text } from './custom-components';
import { Alert, AlertDescription, AlertTitle } from './shadcn/alert';

const sidebarOptions = {
  nav: [
    { name: 'My Account', icon: User },
    { name: 'Plan and Billing', icon: CreditCard },
  ],
};

export const UserProfileDialog = ({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) => {
  const annualFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.annualPlans);

  const { data: userData, isLoading: fetchingUserData } =
    trpc.me.useQuery(null);

  const { data: permissionData, isLoading: fetchingPermissions } =
    trpc.permissions.useQuery(null, {});

  const { userLimits, currentPlanConfigs } = useUserPermissions();

  const [period, setPeriod] = useState<'monthly' | 'annually'>('monthly');
  const [darkModeValue, setDarkModeValue] = useLocalStorage('darkMode', false);
  const [edit, setEdit] = useState<boolean>(false);
  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<PlanDetail | null>(null);
  const [subSection, setSubSection] = useState<string>(
    sidebarOptions.nav[0].name
  );

  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SUBSCRIPTION_STATUSES>(undefined);

  const closeDialog = () => {
    setUpgradeOpen(false);
    setCancelOpen(false);
    setSelected(null);
    onOpenChange();
  };

  const { logout: logoutUser } = useLogout();

  const { mutate: resetUserPassword, isPending: sendingPasswordResetEmail } =
    trpc.sendResetPasswordEmail.useMutation();

  async function onResetPassword({ email }: { email: string }) {
    if (email) {
      try {
        resetUserPassword(
          { email },
          {
            onSuccess: () => {
              // let's keep the email in
              showToastNotification('success', {
                message: 'Request sent successfully!',
                description:
                  'We have sent you an email to complete the password reset',
              });
            },
            onError: (error) => {
              showToastNotification('error', {
                message: error.message,
              });
            },
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  }

  const { data: chargebeeSyncStatus } = trpc.getIsUserSynced.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: scheduledSubscriptionChanges } =
    trpc.getScheduledSubscriptionChanges.useQuery(undefined, {
      refetchOnWindowFocus: false,
    });

  const { mutate: openPortalSessionUrl, isPending: fetchingPortalSessionUrl } =
    trpc.getPortalSessionUrl.useMutation({
      onSuccess: async (data) => {
        if (data) {
          window.location.href = data;
        }
      },
    });

  useEffect(() => {
    if (open) {
      setSubSection(sidebarOptions.nav[0]?.name);
    }
  }, [open]);

  const userOnStandard =
    permissionData &&
    permissionData?.usersPlans.length > 0 &&
    permissionData?.usersPlans.some((plan) =>
      [STANDARD_PLAN.plan_id, STANDARD_PLAN.no_trial_plan_id].includes(plan)
    );

  const userOnSpark =
    permissionData &&
    permissionData?.usersPlans.length > 0 &&
    permissionData?.usersPlans.some((plan) =>
      [SPARK_PLAN.plan_id, SPARK_PLAN.no_trial_plan_id].includes(plan)
    );

  const userOnPro =
    permissionData &&
    permissionData?.usersPlans.length > 0 &&
    permissionData?.usersPlans.some((plan) =>
      [PRO_PLAN.plan_id, PRO_PLAN.no_trial_plan_id].includes(plan)
    );

  const userOnFreePlan =
    permissionData &&
    permissionData?.usersPlans.length > 0 &&
    permissionData?.usersPlans.some((plan) =>
      [FREE_PLAN.plan_id].includes(plan)
    );

  useEffect(() => {
    if (permissionData && plans) {
      if (
        permissionData.userCanAccessEverything &&
        permissionData.usersPlans.length === 0
      )
        setSubscriptionStatus('onFreeTrial');
      else if (
        permissionData.usersPlans.length > 0 &&
        permissionData.usersPlans.some((plan) =>
          [
            FREE_PLAN.plan_id,
            STANDARD_PLAN.plan_id,
            STANDARD_PLAN.no_trial_plan_id,
            PRO_PLAN.plan_id,
            PRO_PLAN.no_trial_plan_id,
            SPARK_PLAN.plan_id,
            SPARK_PLAN.no_trial_plan_id,
          ].includes(plan)
        )
      ) {
        setSubscriptionStatus('hasPlan');
      } else {
        setSubscriptionStatus('hasNoPlan');
      }
    }
  }, [permissionData]);

  return (
    <Dialog
      open={open}
      onOpenChange={edit ? () => setEdit(false) : closeDialog}
    >
      <DialogPortal>
        {fetchingPermissions || fetchingUserData ? (
          <DialogContent
            className={
              'border-0 rounded-lg max-w-lg px-6 bg-themebackground dark:bg-themebackgrounddark'
            }
          >
            <div className="flex justify-center items-center w-full py-6 h-14">
              <Loader />
            </div>
          </DialogContent>
        ) : upgradeOpen && selected ? (
          <DialogContent
            className={
              'overflow-y-auto max-h-screen bg-themebackground dark:bg-themebackgrounddark'
            }
          >
            <CheckoutUpgradeSubscription
              userHasPlan={true}
              selectedPlan={selected}
            />
          </DialogContent>
        ) : cancelOpen && permissionData ? (
          <DowngradePlan
            permissions={permissionData}
            subscriptionStatus={subscriptionStatus}
            currentPlan={
              userOnStandard
                ? 'standard'
                : userOnSpark
                  ? 'spark'
                  : userOnPro
                    ? 'pro'
                    : userOnFreePlan
                      ? 'free'
                      : undefined
            }
          />
        ) : (
          <DialogContent
            className={`overflow-hidden p-0 max-h-full md:max-h-[500px] md:max-w-[700px] lg:max-w-[800px] bg-themebackground dark:bg-themebackgrounddark dark:darkthemefor`}
          >
            <SidebarProvider className="items-start">
              <Sidebar collapsible="none" className="hidden md:flex">
                <SidebarContent>
                  <SidebarGroup className={'flex flex-col justify-between '}>
                    <SidebarGroupContent className="md:h-[28rem]">
                      <SidebarMenu>
                        {sidebarOptions.nav.map((item) => (
                          <SidebarMenuItem
                            className={'cursor-pointer'}
                            key={item.name}
                          >
                            <SidebarMenuButton
                              asChild
                              isActive={item.name === subSection}
                              onClick={() => setSubSection(item.name)}
                            >
                              <span className={'flex gap-2 items-center'}>
                                <item.icon className="min-w-5" />

                                <span>{item.name}</span>
                              </span>
                            </SidebarMenuButton>
                          </SidebarMenuItem>
                        ))}
                      </SidebarMenu>
                    </SidebarGroupContent>
                    <SidebarGroupContent>
                      <SidebarMenu>
                        <SidebarMenuItem>
                          <SidebarMenuButton
                            className={'cursor-pointer'}
                            asChild
                            onClick={() => {
                              logoutUser();
                              setDarkModeValue(darkModeValue);
                              document.body.classList.toggle('dark', false);
                              localStorage.clear();
                            }}
                          >
                            <span className={''}>Log out</span>
                          </SidebarMenuButton>
                        </SidebarMenuItem>
                      </SidebarMenu>
                    </SidebarGroupContent>
                  </SidebarGroup>
                </SidebarContent>
              </Sidebar>
              <main className="flex h-[calc(100vh-1rem)] lg:h-[480px] flex-1 flex-col overflow-hidden ">
                <div className="flex flex-1 flex-col gap-4 overflow-y-auto p-6 pt-8">
                  <Tabs
                    defaultValue={sidebarOptions.nav[0].name}
                    className="mt-5 lg:hidden"
                    value={subSection}
                    onValueChange={(val) => setSubSection(val)}
                  >
                    <TabsList className="grid w-full grid-cols-2">
                      {sidebarOptions.nav.map((item) => (
                        <TabsTrigger
                          key={item.name}
                          value={item.name}
                          className="gap-2"
                        >
                          <item.icon className="h-5 w-5 min-w-5" /> {item.name}
                        </TabsTrigger>
                      ))}
                    </TabsList>
                  </Tabs>
                  {subSection === 'Plan and Billing' ? (
                    <div className={'space-y-6'}>
                      <div className={'space-y-2'}>
                        <h5 className={'font-semibold text-2xl'}>Your Plan</h5>
                        <p className={'text-thememutedforeground'}>
                          {subscriptionStatus === 'hasNoPlan'
                            ? 'You are not currently on a Paid Plan. Choose a plan from below.'
                            : null}
                        </p>
                      </div>
                      <div className={'space-y-3'}>
                        <div
                          className={'flex justify-between gap-5 items-center'}
                        >
                          <span className={'font-semibold'}>
                            Available Plans
                          </span>
                          {annualFlagEnabled ? (
                            <Tabs defaultValue={period}>
                              <TabsList className={'rounded-full'}>
                                <TabsTrigger
                                  className={'rounded-full'}
                                  value={'monthly'}
                                  onClick={() => setPeriod('monthly')}
                                >
                                  Pay Monthly
                                </TabsTrigger>
                                <TabsTrigger
                                  className={'rounded-full flex gap-1.5'}
                                  value={'annually'}
                                  onClick={() => setPeriod('annually')}
                                >
                                  <span>Pay Yearly</span>{' '}
                                  <span className={'text-themedestructive'}>
                                    Save 50%
                                  </span>
                                </TabsTrigger>
                              </TabsList>
                            </Tabs>
                          ) : null}
                        </div>
                        <div>
                          {scheduledSubscriptionChanges && (
                            <Alert>
                              <Message className="h-4 w-4" />
                              <AlertTitle>Heads up!</AlertTitle>
                              <AlertDescription>
                                Your subscription has been scheduled for a
                                downgrade to{' '}
                                {
                                  plans.find(
                                    (item) =>
                                      item.no_trial_plan_id ===
                                        scheduledSubscriptionChanges.plan ||
                                      item.plan_id ===
                                        scheduledSubscriptionChanges.plan
                                  )?.name
                                }{' '}
                                at your next renewal on{' '}
                                {new Date(
                                  scheduledSubscriptionChanges.scheduledAt
                                ).toLocaleDateString('en-US', {
                                  month: 'long',
                                  day: 'numeric',
                                  year: 'numeric',
                                })}
                                .
                              </AlertDescription>
                            </Alert>
                          )}
                        </div>
                        {[
                          ...(permissionData?.usersPlans.includes(
                            FREE_PLAN.plan_id
                          )
                            ? [FREE_PLAN]
                            : []),
                          SPARK_PLAN,
                          STANDARD_PLAN,
                          PRO_PLAN,
                        ].map((plan, index) => (
                          <UpgradeBox
                            boxData={plan}
                            key={index}
                            period={period}
                            userPlans={permissionData?.usersPlans ?? []}
                            descriptionDetailsToShow={'features'}
                            setUpgradeOpen={setUpgradeOpen}
                            setSelected={setSelected}
                          />
                        ))}
                      </div>
                      <div>
                        {chargebeeSyncStatus &&
                          subscriptionStatus === 'hasPlan' && (
                            <div
                              className={
                                'flex justify-between items-center gap-1.5 h-9'
                              }
                            >
                              {fetchingPortalSessionUrl ? (
                                <span className="font-medium text-sm leading-5 text-thememutedforeground tracking-wide	">
                                  Loading...
                                </span>
                              ) : (
                                <div className="flex gap-2">
                                  <Button
                                    size={'sm'}
                                    onClick={() => openPortalSessionUrl()}
                                  >
                                    Manage Billing
                                  </Button>
                                  {!userOnFreePlan && (
                                    <Button
                                      variant={'outline'}
                                      onClick={() => setCancelOpen(true)}
                                    >
                                      Cancel
                                    </Button>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                      <div>
                        <p
                          className={
                            'text-sm font-light text-thememutedforeground'
                          }
                        >
                          Learn more about{' '}
                          <a
                            href={'https://creativeos.com/#pricing'}
                            className={'underline'}
                          >
                            all plans & features
                          </a>
                          .
                        </p>
                      </div>
                      {currentPlanConfigs.length > 0 && permissionData ? (
                        <Stack className="gap-3 w-full">
                          <Text size={'base'} weight={'semibold'}>
                            Features
                          </Text>
                          <Stack className="gap-0.5">
                            <div className="flex gap-1 justify-between">
                              <Text size={'sm'} weight={'normal'}>
                                Ad Template Requests
                              </Text>
                              <Text
                                size={'sm'}
                                weight={'semibold'}
                                className="text-right"
                              >
                                {currentPlanConfigs[0].requestLimits.ads ==
                                'unlimited'
                                  ? 'Unlimited'
                                  : `${Math.max(userLimits?.request.ad ?? 0, 0)}/${currentPlanConfigs[0].requestLimits.ads} Remaining`}
                              </Text>
                            </div>
                            <Text
                              size={'xs'}
                              weight={'thin'}
                              className="text-thememutedforeground"
                            >
                              Your plan allows for{' '}
                              {currentPlanConfigs[0].requestLimits.ads} Ad
                              Template Requests/month. Resets on{' '}
                              {new Date(
                                permissionData?.limitNextUpdateAt
                              ).toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                              })}
                              .
                            </Text>
                          </Stack>
                          <Stack className="gap-0.5">
                            <div className="flex gap-1 justify-between">
                              <Text size={'sm'} weight={'normal'}>
                                Email Template Requests
                              </Text>
                              <Text
                                size={'sm'}
                                weight={'semibold'}
                                className="text-right"
                              >
                                {currentPlanConfigs[0].requestLimits.emails ==
                                'unlimited'
                                  ? 'Unlimited'
                                  : `${Math.max(userLimits?.request.email ?? 0, 0)}/${currentPlanConfigs[0].requestLimits.emails} Remaining`}
                              </Text>
                            </div>
                            <Text
                              size={'xs'}
                              weight={'thin'}
                              className="text-thememutedforeground"
                            >
                              Your plan allows for{' '}
                              {currentPlanConfigs[0].requestLimits.emails} Email
                              Template Requests/month. Resets on{' '}
                              {new Date(
                                permissionData?.limitNextUpdateAt
                              ).toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                              })}
                              .
                            </Text>
                          </Stack>
                          <Stack className="gap-0.5">
                            <div className="flex gap-1 justify-between">
                              <Text size={'sm'} weight={'normal'}>
                                Lander Template Requests
                              </Text>
                              <Text
                                size={'sm'}
                                weight={'semibold'}
                                className="text-right"
                              >
                                {currentPlanConfigs[0].requestLimits.lander ==
                                'unlimited'
                                  ? 'Unlimited'
                                  : `${Math.max(userLimits?.request.lander ?? 0, 0)}/${currentPlanConfigs[0].requestLimits.lander} Remaining`}
                              </Text>
                            </div>
                            <Text
                              size={'xs'}
                              weight={'thin'}
                              className="text-thememutedforeground"
                            >
                              Your plan allows for{' '}
                              {currentPlanConfigs[0].requestLimits.lander}{' '}
                              Lander Template Requests/month. Resets on{' '}
                              {new Date(
                                permissionData?.limitNextUpdateAt
                              ).toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                              })}
                              .
                            </Text>
                          </Stack>
                        </Stack>
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      {edit && userData ? (
                        <UserProfileEdit
                          userData={userData}
                          setEdit={setEdit}
                        />
                      ) : (
                        <div
                          className={`flex flex-col gap-6 ${!(edit || upgradeOpen) ? '' : 'hidden'}`}
                        >
                          {userData && (
                            <UserProfileSettingDetail
                              userData={userData}
                              setEdit={setEdit}
                            />
                          )}
                          <div className={'flex gap-2 mb-4'}>
                            <Button
                              variant={'outline'}
                              className="h-8"
                              onClick={() =>
                                onResetPassword({
                                  email: userData?.email ?? '',
                                })
                              }
                              disabled={
                                sendingPasswordResetEmail || !userData?.email
                              }
                              loading={sendingPasswordResetEmail}
                            >
                              {sendingPasswordResetEmail
                                ? 'Resetting Password...'
                                : 'Reset Password'}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </main>
            </SidebarProvider>
          </DialogContent>
        )}
      </DialogPortal>
    </Dialog>
  );
};
