import { Badge } from "@/components/shadcn/badge";
import { Button } from "@/components/shadcn/button";
import { PRO_PLAN, PlanDetail } from "@/utils/data/plans.ts";
import { CheckCircle } from "@mynaui/icons-react";
import {
  FREE_PLAN_ID,
  IGNITE_PLAN_ID,
  IGNITE_PLAN_NO_TRIAL,
  PRO_PLAN_ID,
  PRO_PLAN_NO_TRIAL,
  SPARK_PLAN_ID,
  SPARK_PLAN_NO_TRIAL,
} from "../../../../src/shared/constants";

export default function UpgradeBox({
  boxData,
  userPlans,
  period,
  descriptionDetailsToShow,
  setSelected,
  setUpgradeOpen,
}: {
  boxData: PlanDetail;
  userPlans: string[];
  period?: string;
  descriptionDetailsToShow: "description" | "features";
  setUpgradeOpen: (opened: boolean) => void;
  setSelected: (plan: PlanDetail) => void;
}) {
  const userPlanOrders = [
    [FREE_PLAN_ID],
    [SPARK_PLAN_ID, SPARK_PLAN_NO_TRIAL],
    [IGNITE_PLAN_ID, IGNITE_PLAN_NO_TRIAL],
    [PRO_PLAN_ID, PRO_PLAN_NO_TRIAL],
  ];
  const currentUserPlanIndex = userPlanOrders.findIndex((planGroup) =>
    planGroup.some((planId) => userPlans.includes(planId))
  );
  const userIsOnProPlan =
    userPlans.includes(PRO_PLAN.plan_id) ||
    userPlans.includes(PRO_PLAN.no_trial_plan_id) ||
    userPlans.includes(PRO_PLAN.yearly_plan_id);

  const isProPlanBox =
    boxData.plan_id === PRO_PLAN.plan_id ||
    boxData.plan_id === PRO_PLAN.no_trial_plan_id ||
    boxData.plan_id === PRO_PLAN.yearly_plan_id;

  const userPlansContainCurrentBoxDataPlans =
    userPlans.includes(boxData.plan_id) ||
    userPlans.includes(boxData.no_trial_plan_id) ||
    userPlans.includes(boxData.yearly_plan_id);

  return (userIsOnProPlan && !isProPlanBox) || userPlanOrders.findIndex((planGroup) =>
    planGroup.includes(boxData.plan_id)
  ) < currentUserPlanIndex ? (
    null
  ) : (
    <div
      className={`p-4 border-2	rounded-lg ${userPlansContainCurrentBoxDataPlans ? "border-themeborder" : "border-themedestructive"} gap-1`}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className=" text-base leading-6 font-medium">
            {boxData.name}
          </span>
          {isProPlanBox && !userPlansContainCurrentBoxDataPlans ? (
            <Badge className="text-themedestructive text-xs leading-4 font-medium bg-purple-100 hover:bg-purple-100">
              {userPlans.length == 0 ? "Recommended" : "Premium"}
            </Badge>
          ): null}
          {userPlansContainCurrentBoxDataPlans?
            <Badge className="text-xs leading-4 font-medium text-themeforeground bg-thememuted hover:bg-thememuted">
              Current Plan
            </Badge>: null
          }
        </div>
        <div className="flex items-center gap-2">
          <span className=" text-base leading-6 font-bold">
            {period === "annually" ? boxData.annual_price : boxData.price} /{" "}
            {period === "annually" ? "yr" : "mo"}
          </span>
          {!userPlansContainCurrentBoxDataPlans && (
            <Button
              variant={"destructivePurple"}
              size={"sm"}
              onClick={() => {
                setSelected(boxData);
                setUpgradeOpen(true);
              }}
            >
              {isProPlanBox && userPlans.length == 0
                ? "Get Pro"
                  : "Upgrade"}
            </Button>
          )}
        </div>
      </div>
      {!userPlansContainCurrentBoxDataPlans && (
        <div>
          {descriptionDetailsToShow === "features" ? (
            <div className="flex flex-col gap-2">
              <span className="text-sm font-normal leading-5">
                {period === "annually"
                  ? boxData.annual_monthly_price
                  : boxData.price}{" "}
                per month, cancel anytime{" "}
              </span>
              <>
                {boxData.features.map((feature, index) => (
                  <div
                    key={index}
                    className="flex gap-1 justify-start items-center text-thememutedforeground"
                  >
                    <CheckCircle className="w-4 h-4" />
                    <span className="text-sm font-normal leading-5 ">
                      {feature}
                    </span>
                  </div>
                ))}
              </>
            </div>
          ) : (
            <p className={"text-sm font-light"}>{boxData.description}</p>
          )}
        </div>
      )}
    </div>
  );
}
