declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

// Define types for eCommerce event data
export type EcommerceItem = {
  item_name: string;
  item_id: string;
  price?: number;
  quantity: number;
};

export type EcommerceEvent =
  | { event: 'begin_checkout'; ecommerce: { items: EcommerceItem[] } }
  | { event: 'add_shipping_info'; ecommerce: { shipping_tier: string } }
  | { event: 'add_payment_info'; ecommerce: { payment_type: string } }
  | {
      event: 'purchase';
      ecommerce: {
        transaction_id: string;
        value: number;
        currency: string;
        items: EcommerceItem[];
      };
    } 
  | {
      event: 'trial_start';
      ecommerce: {
        transaction_id: string;
        value: number;
        currency: string;
        items: EcommerceItem[];
      };
    }
  | {
      event: 'downgrade';
      ecommerce: {
        transaction_id: string;
        value: number;
        currency: string;
        items: EcommerceItem[];
      };
    };

// Push events to DataLayer
export const pushToDataLayer = (eventData: EcommerceEvent) => {
  if (window.dataLayer) {
    window.dataLayer.push(eventData);
  } else {
    console.warn('GTM not initialized');
  }
};

export const handleCheckoutStart = (cartItems: EcommerceItem[]) => {
  pushToDataLayer({
    event: 'begin_checkout',
    ecommerce: { items: cartItems },
  });
};

export const handleShippingInfo = (shippingMethod: string) => {
  pushToDataLayer({
    event: 'add_shipping_info',
    ecommerce: { shipping_tier: shippingMethod },
  });
};

export const handlePaymentSelection = (paymentMethod: string) => {
  pushToDataLayer({
    event: 'add_payment_info',
    ecommerce: { payment_type: paymentMethod },
  });
};

export const handleTrialStart = (
  orderId: string,
  totalAmount: number,
  purchasedItems: EcommerceItem[],
) => {
  pushToDataLayer({
    event: 'trial_start',
    ecommerce: {
      transaction_id: orderId,
      value: totalAmount,
      currency: 'USD',
      items: purchasedItems,
    },
  });
};

export const handleDowngrade = (
  orderId: string,
  totalAmount: number,
  purchasedItems: EcommerceItem[],
) => {
  pushToDataLayer({
    event: 'downgrade',
    ecommerce: {
      transaction_id: orderId,
      value: totalAmount,
      currency: 'USD',
      items: purchasedItems,
    },
  });
};

export const handlePurchase = (
  orderId: string,
  totalAmount: number,
  purchasedItems: EcommerceItem[],
) => {
  pushToDataLayer({
    event: 'purchase',
    ecommerce: {
      transaction_id: orderId,
      value: totalAmount,
      currency: 'USD',
      items: purchasedItems,
    },
  });
};
