import { CreativeOSPages } from "@/_shared/subscriptionPlans";
import AccessControl from "@/components/AccessControl";
import { Stack } from "@/components/custom-components";
import { Badge } from "@/components/shadcn/badge.tsx";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/creative-toolkit/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const navigate = useNavigate();

  return (
    <AccessControl page={CreativeOSPages.CREATIVE_TOOLKIT}>
      <Stack className="gap-3 lg:gap-6">
        <Stack className="gap-3 lg:gap-8">
          <Stack className="gap-3 lg:gap-6">
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <div className={""}>
                <h1 className={"text-2xl font-semibold"}>
                  Your Imagination. Our Tools.
                </h1>
                <p
                  className={
                    "text-sm lg:text-base text-thememutedforeground mt-2"
                  }
                >
                  Access powerful features to refine, optimize, and elevate
                  every creation.
                </p>
              </div>
            </div>
          </Stack>
        </Stack>
        <div>
          <div className={"grid lg:grid-cols-3 gap-4"}>
            <div
              style={{
                backgroundImage: `url(/dashboard/t1.jpg)`,
              }}
              className={
                "cursor-pointer hover:border-themedestructive border-2 rounded-lg py-6 px-4 min-h-[20rem] lg:min-h-[40rem] bg-center bg-no-repeat bg-cover"
              }
              onClick={() =>
                navigate({ to: "/feeds/creative-toolkit/copy-grader" })
              }
            >
              <div>
                <div className={"flex gap-2 justify-between"}>
                  <p className={"text-xl font-medium  text-themeprimary"}>
                    Copy Grader
                  </p>
                  <Badge variant={"secondaryDestructive"}>New</Badge>
                </div>
                <p className={"mt-1.5 leading-relaxed text-themeprimary"}>
                  See the readability score of your copy based on the
                  Flesch–Kincaid Grade Level.
                </p>
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url(/dashboard/t2.jpg)`,
              }}
              className={
                "cursor-pointer hover:border-themedestructive border-2 rounded-lg py-6 px-4 min-h-[20rem] lg:min-h-[40rem] bg-center bg-no-repeat bg-cover"
              }
              onClick={() =>
                navigate({ to: "/feeds/creative-toolkit/ad-test-calculator" })
              }
            >
              <div>
                <div className={"flex gap-2 justify-between"}>
                  <p className={"text-xl font-medium text-themeprimary"}>
                    Ad-Test Calculator
                  </p>
                  <Badge variant={"secondaryDestructive"}>New</Badge>
                </div>
                <p className={"mt-1.5 leading-relaxed text-themeprimary"}>
                  Calculate the optimal creative testing volume and budget
                  allocation.
                </p>
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url(/dashboard/t3.jpg)`,
              }}
              className={
                "border-2 rounded-lg py-6 px-4 min-h-[20rem] lg:min-h-[40rem] bg-center bg-cover bg-no-repeat"
              }
            >
              <div>
                <div className={"flex gap-2 justify-between"}>
                  <p
                    className={"text-xl font-medium text-thememutedforeground"}
                  >
                    Creative Analysis
                  </p>
                  <Badge>Coming Soon</Badge>
                </div>
                <p
                  className={"mt-1.5 text-thememutedforeground leading-relaxed"}
                >
                  Get AI-powered analysis of what’s working and not working for
                  your creative assets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Stack>
    </AccessControl>
  );
}
