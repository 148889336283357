import { AirTableAdRecord } from '../../../../src/shared';

export const REQUEST_TEMPLATE_MESSAGE = 'Hold on a moment...';
export const REQUEST_TEMPLATE_DESCRIPTION =
  'We’re currently checking your request status. This may take a few seconds.';
export const CREATIVE_TOOLKIT_LIMIT_REACHED_TITLE = 'Limit Reached';
export const CREATIVE_TOOLKIT_LIMIT_REACHED_BODY = `You've reached your monthly limit for the Creative Toolkit. Please check back next month.`;

export enum ExpertSpeciality {
  Ads = 'Ad Collection',
  Emails = 'Email Collection',
  Landers = 'Lander Collection',
}

export type BoardCollectionDataProps = {
  collections: ExpertProductType[];
};

export type ExpertProductType = {
  name: string;
  atId: string;
  image?: string | null;
  speciality: ('Ads' | 'Emails' | 'Landers')[];
  adTemplates?: AirTableAdRecord[];
  totalAdCount?: number;
  totalEmailCount?: number;
};

export const defaultUseQueryRefetchOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};
