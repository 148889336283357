import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import AccessControl from "@/components/AccessControl";
import { Button } from "@/components/shadcn/button";
import useUserPermissions from "@/hooks/useUserPermissions";
import { Stack } from "../Layout";
import { Text } from "../Text";

export function InspirationRequestNotifier({
  limitLeft = 0,
  pageType = "ad",
}: {
  limitLeft?: number;
  pageType?: "ad" | "email" | "lander";
}) {
  const userCanMakeRequests = limitLeft > 0;
  const { permissions, currentPlanConfigs, isProUser } = useUserPermissions();
  const currentPlanItemLimit = currentPlanConfigs.length > 0 ?
    (pageType == "ad"
      ? currentPlanConfigs[0].requestLimits.ads
      : pageType == "email"
        ? currentPlanConfigs[0].requestLimits.emails
        : currentPlanConfigs[0].requestLimits.lander) : 0;

  return permissions && currentPlanConfigs.length > 0 ? (
    (limitLeft < 3 && (currentPlanItemLimit == "unlimited" ||
      (currentPlanItemLimit > 0) )) 
      ? (
      <div
        className={`w-full px-4 py-2 flex gap-3 rounded-lg ${isProUser && !userCanMakeRequests ? "bg-thememuted" : "bg-purple-50"} justify-between items-center`}
      >
        <Stack className="gap-1">
          <Text weight={"semibold"} size={"sm"} className="text-themeforeground">
            {userCanMakeRequests
              ? `⚡ Only ${limitLeft} template ${limitLeft > 1 ? "requests" : "request"} left! `
              : `🚫 You’ve reached your limit! `}

            <Text weight={"normal"} size={"sm"}>
              {userCanMakeRequests
                ? `${isProUser ? `` : `Upgrade to Pro for Unlimited requests per month and unleash your creativity!`}`
                : ` You’ve used all ${pageType == "ad" ? currentPlanConfigs[0].requestLimits.ads : pageType == "email" ? currentPlanConfigs[0].requestLimits.emails : currentPlanConfigs[0].requestLimits.lander} template ${(pageType == "ad" ? currentPlanConfigs[0].requestLimits.ads : pageType == "email" ? currentPlanConfigs[0].requestLimits.emails : currentPlanConfigs[0].requestLimits.lander) == 1 ? "request" : "requests"} this month. ${
                    isProUser
                      ? `Don’t worry—your limit resets on ${new Date(
                          permissions.limitNextUpdateAt
                        ).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}.`
                      : `Upgrade to Pro for Unlimited requests a month!`
                  }`}
            </Text>
          </Text>
          <Text
            weight={"thin"}
            size={"xs"}
            className="text-thememutedforeground"
          >
            {userCanMakeRequests
              ? `Your limit of ${pageType == "ad" ? currentPlanConfigs[0].requestLimits.ads : pageType == "email" ? currentPlanConfigs[0].requestLimits.emails : currentPlanConfigs[0].requestLimits.lander} ${(pageType == "ad" ? currentPlanConfigs[0].requestLimits.ads : pageType == "email" ? currentPlanConfigs[0].requestLimits.emails : currentPlanConfigs[0].requestLimits.lander) == 1 ? "request" : "requests"} per month resets on ${new Date(
                  permissions.limitNextUpdateAt
                ).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}.`
              : isProUser
                ? ``
                : `Don’t worry—your limit of ${pageType == "ad" ? currentPlanConfigs[0].requestLimits.ads : pageType == "email" ? currentPlanConfigs[0].requestLimits.emails : currentPlanConfigs[0].requestLimits.lander} ${(pageType == "ad" ? currentPlanConfigs[0].requestLimits.ads : pageType == "email" ? currentPlanConfigs[0].requestLimits.emails : currentPlanConfigs[0].requestLimits.lander) == 1 ? "request" : "requests"} per month resets on ${new Date(
                    permissions.limitNextUpdateAt
                  ).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}.`}
          </Text>
        </Stack>

        {isProUser ? null : (
          <AccessControl
            feature={CreativeOSFeatures.UPGRADE}
            FeatureLocked={
              <Button variant={"destructivePurple"} size={"sm"}>
                Upgrade
              </Button>
            }
          />
        )}
      </div>
    ) : null
  ) : null;
}
