import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import { useState } from "react";
import { AirTableAdRecord } from "../../../../src/shared/airtable";
import AccessControl from "./AccessControl";
import TemplateDetails from "./TemplateDetails";
import { LockTagLargeNoBg } from "./custom-components/LockTag";

const SearchDialogAdTemplateImage = ({ item }: { item: AirTableAdRecord }) => {
  const [detailsShow, setDetailsShow] = useState<boolean>(false);

  return (
    <div className={`bg-slate-100 rounded-2xl`} key={item["Creative ID"]}>
      {detailsShow && item && (
        <TemplateDetails
          data={{
            Ad: item,
            IsLocked: false,
            IsPublic: false,
            brandName: undefined,
            shouldInvalidateCache: false,
            DesiredWidth: 0,
          }}
          open={detailsShow}
          onCloseDrawer={() => setDetailsShow(false)}
        />
      )}
      <AccessControl
        FeatureUnlocked={
          <AccessControl
            feature={CreativeOSFeatures.SHOW_AD_TEMPLATE_DETAILS}
            FeatureUnlocked={
              <img
                src={
                  item.imageUrl ??
                  "https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs"
                }
                alt={`${item.brandName ?? "CreativeOs"} Image`}
                onClick={() => setDetailsShow(true)}
                loading="eager"
                className="min-w-[10.7rem] w-[10.7rem] h-[10.7rem] rounded-2xl object-cover cursor-pointer lg:hvr-grow"
              />
            }
            FeatureLocked={
              <img
                src={
                  item.imageUrl ??
                  "https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs"
                }
                alt={`${item.brandName ?? "CreativeOs"} Image`}
                loading="eager"
                className="min-w-[10.7rem] w-[10.7rem] h-[10.7rem] rounded-2xl object-cover cursor-pointer lg:hvr-grow"
              />
            }
          />
        }
        feature={CreativeOSFeatures.SHOW_SEARCH_AD_TEMPLATE_IMAGE}
        FeatureLocked={
          <div className="relative">
            <img
              src={
                item.imageUrl ??
                "https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs"
              }
              alt={`${item.brandName ?? "CreativeOs"} Image`}
              loading="eager"
              className="min-w-[10.7rem] w-[10.7rem] h-[10.7rem] rounded-2xl object-cover cursor-pointer lg:hvr-grow filter blur-sm"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="bg-black bg-opacity-50 rounded-full p-2">
                <LockTagLargeNoBg />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default SearchDialogAdTemplateImage;
