import {
  CreativeOSFeatures,
  CreativeOSPages,
} from "@/_shared/subscriptionPlans";
import AccessControl from "@/components/AccessControl";
import { Stack, Text } from "@/components/custom-components";
import { CustomDiamondIcon } from "@/components/custom-components/DiamondIcon";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs";
import { LockTagLarge } from "@/components/custom-components/LockTag";
import { CustomTestTubeIcon } from "@/components/custom-components/TestTubeIcon";
import { Tree } from "@mynaui/icons-react";
import { Link, createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

type LiveTrendsDataProps = {
  liveTrends: TrendData[];
  showLockIcon: boolean;
  setShowLockIcon: () => void;
};

type TrendData = {
  trendTitle: string;
  featureName: CreativeOSFeatures;
  trendTitleIcon: JSX.Element;
  trendImageSrc: string;
  trendUrl: string;
};

export const Route = createFileRoute("/feeds/live-trends/")({
  component: () => <All />,
});

function All() {
  const [showLockIcon, setShowLockIcon] = useState<boolean>(false);

  return (
    <AccessControl page={CreativeOSPages.LIVE_COLLECTIONS}>
      <Stack className="gap-3 lg:gap-6">
        <Stack className="gap-3 lg:gap-8">
          <div className="flex gap-2 justify-between">
            <Stack className="gap-3 lg:gap-2">
              <Text weight="semibold" size={"xxl"}>
                Creations. In the Moment.
              </Text>
              <Text
                weight="normal"
                size={"lg"}
                className="text-thememutedforeground hidden lg:flex"
              >
                One place, endless possibilities—discover hidden gems, new ads,
                and evergreens.
              </Text>
            </Stack>
            <FeatureTabs
              defaultOrderValue={"Random"}
              urlSegmentCountToCompare={2}
              tabItems={[
                {
                  name: "Curated",
                  link: "/feeds/collections/creativeos",
                  featureName: CreativeOSFeatures.CREATIVEOS_COLLECTIONS,
                },
                {
                  name: "Live Trends",
                  link: "/feeds/live-trends",
                  featureName: CreativeOSFeatures.LIVE_COLLECTIONS,
                },
              ]}
            />
          </div>
        </Stack>
        <LiveTrendsGridView
          setShowLockIcon={() => {
            setShowLockIcon(true);
          }}
          showLockIcon={showLockIcon}
          liveTrends={[
            {
              trendTitle: "Hidden Gems",
              trendImageSrc: "/images/live_trends/hiddengems_banner.png",
              trendUrl: "/feeds/hidden-gems",
              trendTitleIcon: <CustomDiamondIcon />,
              featureName:
                CreativeOSFeatures.LIVE_COLLECTIONS_HIDDEN_GEMS_NAV_BUTTON,
            },
            {
              trendTitle: "Evergreen Ads",
              trendImageSrc: "/images/live_trends/evergreen_banner.png",
              trendUrl: "/feeds/evergreen",
              trendTitleIcon: <Tree className="w-6 h-6" />,
              featureName:
                CreativeOSFeatures.LIVE_COLLECTIONS_EVERGREEN_NAV_BUTTON,
            },
            {
              trendTitle: "Testing Lab",
              trendImageSrc: "/images/live_trends/testinglabs_banner.png",
              trendUrl: "/feeds/testing-labs",
              trendTitleIcon: <CustomTestTubeIcon />,
              featureName:
                CreativeOSFeatures.LIVE_COLLECTIONS_TESTING_LAB_NAV_BUTTON,
            },
          ]}
        />
      </Stack>
    </AccessControl>
  );
}

const LiveTrendsGridView = (props: LiveTrendsDataProps) => {
  return (
    <div className={"grid lg:grid-cols-2 gap-6 mb-10"}>
      {props.liveTrends && props.liveTrends.length > 0
        ? props.liveTrends.map((item: TrendData) => (
            <TrendItem
              showLockIcon={props.showLockIcon}
              setShowLockIcon={props.setShowLockIcon}
              key={item.trendTitle}
              data={item}
              featureName={item.featureName}
            />
          ))
        : props.liveTrends.length === 0 && (
            <div
              className={
                "h-full w-full lg:col-span-2 flex justify-center items-center"
              }
            >
              <p>No collections created</p>
            </div>
          )}
    </div>
  );
};

const TrendItem = ({
  data,
  setShowLockIcon,
  showLockIcon,
  featureName,
}: {
  data: TrendData;
  showLockIcon: boolean;
  featureName: CreativeOSFeatures;
  setShowLockIcon: () => void;
}) => {
  return (
    <AccessControl
      feature={featureName}
      FeatureUnlocked={
        <Link to={data.trendUrl}>
          <div
            className={
              "rounded-lg max-h-96 bg-thememuted px-6 pt-6 overflow-hidden flex flex-col justify-between"
            }
          >
            <div className="flex gap-2 justify-between">
              <div className={`flex gap-2 items-center mb-3`}>
                {data.trendTitleIcon}
                <Text
                  size={"xxl"}
                  weight={"medium"}
                  className="text-themeforeground"
                >
                  {data.trendTitle}
                </Text>
              </div>
            </div>
            <img
              src={data.trendImageSrc}
              alt=""
              className="object-cover w-full"
            />
          </div>
        </Link>
      }
      FeatureLocked={
        <div
          onClick={setShowLockIcon}
          className={
            "rounded-lg max-h-96 bg-thememuted px-6 pt-6 overflow-hidden flex flex-col justify-between"
          }
        >
          <div className="flex gap-2 justify-between">
            <div
              className={`flex gap-2 items-center mb-3 ${showLockIcon ? "opacity-50" : ""}`}
            >
              {data.trendTitleIcon}
              <Text
                size={"xxl"}
                weight={"medium"}
                className="text-themeforeground"
              >
                {data.trendTitle}
              </Text>
            </div>
            {showLockIcon && <LockTagLarge />}
          </div>
          <img
            src={data.trendImageSrc}
            alt=""
            className="object-cover w-full"
          />
        </div>
      }
    />
  );
};
