import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import AccessControl from "@/components/AccessControl";
import { Badge } from "@/components/shadcn/badge";
import { Button } from "@/components/shadcn/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/shadcn/collapsible";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar,
} from "@/components/shadcn/sidebar";
import { Pin, PinSolid } from "@mynaui/icons-react";
import { Link, useRouterState } from "@tanstack/react-router";
import { ChevronRight, type LucideIcon } from "lucide-react";
import { useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { LockTagSmall } from "../LockTag";
import { PingCirclesRed } from "../PingCircles";

export function SideBarItem({
  items,
  sectionTitle,
}: {
  items: {
    title: string;
    urls?: string[];
    icon?: LucideIcon | React.ComponentType<any>;
    image?: JSX.Element;
    isActive?: boolean;
    showPingCircles?: boolean;
    isComingSoon?: boolean;
    isNew?: boolean;
    onClick?: () => void;
    featureName?: CreativeOSFeatures;
    items?: {
      title: string;
      urls?: string[];
      onClick?: () => void;
      featureName?: CreativeOSFeatures;
      pinned?: boolean;
      dontCheckUrl?: boolean;
      dontCheckPathName?: boolean;
    }[];
  }[];
  sectionTitle?: string;
}) {
  const router = useRouterState();
  const { open } = useSidebar();

  const [pinnedItems, setPinnedItems] = useLocalStorage<{
    [key: string]: string[];
  }>("pinnedSidebarItems", {});

  const [openCollapsibles, setOpenCollapsibles] = useState<{
    [key: string]: boolean;
  }>({});

  const togglePin = (parentTitle: string, subItemTitle: string) => {
    setPinnedItems((prev) => {
      const updated = { ...prev };
      if (!updated[parentTitle]) updated[parentTitle] = [];
      if (updated[parentTitle].includes(subItemTitle)) {
        updated[parentTitle] = updated[parentTitle].filter(
          (title) => title !== subItemTitle
        );
      } else {
        updated[parentTitle].push(subItemTitle);
      }
      return updated;
    });
  };

  const isActive = (urls: string[]) =>
    urls.some(
      (url) =>
        url.includes(router.location.pathname) ||
        router.location.pathname.includes(url)
    )
      ? "bg-customConicGradient text-white hover:text-white"
      : "";

  return (
    <SidebarGroup>
      {sectionTitle ? (
        <SidebarGroupLabel>{sectionTitle}</SidebarGroupLabel>
      ) : null}
      <SidebarMenu>
        {items.map((item) => {
          const isOpen = openCollapsibles[item.title] ?? item.isActive;

          const pinnedSubItems =
            item.items?.filter((sub) =>
              pinnedItems[item.title]?.includes(sub.title)
            ) || [];

          return (item.items ?? []).length > 0 ? (
            <div key={item.title}>
              {open ? (
                <Collapsible
                  asChild
                  defaultOpen={item.isActive}
                  className="group/collapsible"
                  onOpenChange={(open) =>
                    setOpenCollapsibles((prev) => ({
                      ...prev,
                      [item.title]: open,
                    }))
                  }
                >
                  <SidebarMenuItem>
                    <CollapsibleTrigger asChild>
                      <SidebarMenuButton
                        tooltip={item.title}
                        className={
                          (item.urls ?? []).some(
                            (url) =>
                              url.includes(router.location.pathname) ||
                              router.location.pathname.includes(url)
                          )
                            ? "bg-customConicGradient text-white hover:text-white"
                            : ""
                        }
                      >
                        {item.icon ? <item.icon /> : item.image}
                        {item.showPingCircles ? <PingCirclesRed /> : null}
                        <span>{item.title}</span>
                        {(item.items ?? []).length > 0 ? (
                          <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                        ) : null}
                      </SidebarMenuButton>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <SidebarMenuSub className="mr-0 pr-1.5 border-0">
                        {[
                          ...pinnedSubItems,
                          ...(item.items?.filter(
                            (sub) =>
                              !pinnedItems[item.title]?.includes(sub.title)
                          ) || []),
                        ].map((subItem) => (
                          <AccessControl
                            key={subItem.title}
                            feature={subItem.featureName}
                            FeatureLocked={
                              <SidebarMenuSubItem
                                key={subItem.title}
                                className="flex justify-between items-center"
                              >
                                <SidebarMenuSubButton className="w-full justify-between">
                                  <span>{subItem.title}</span>
                                </SidebarMenuSubButton>
                                <div className="opacity-50">
                                  {<LockTagSmall />}
                                </div>
                              </SidebarMenuSubItem>
                            }
                            FeatureUnlocked={
                              <SidebarMenuSubItem
                                key={subItem.title}
                                className="flex justify-between items-center"
                              >
                                {subItem.urls &&
                                (subItem.urls ?? []).length > 0 ? (
                                  <SidebarMenuSubButton
                                    asChild
                                    className={`${
                                      (subItem.urls ?? []).some(
                                        (url) =>
                                          (subItem.dontCheckUrl
                                            ? false
                                            : url.includes(
                                                router.location.pathname
                                              )) ||
                                          (subItem.dontCheckPathName
                                            ? false
                                            : router.location.pathname.includes(
                                                url
                                              ))
                                      )
                                        ? "bg-customConicGradient text-white hover:text-white"
                                        : ""
                                    } w-full`}
                                  >
                                    <Link to={subItem.urls[0]}>
                                      <span>{subItem.title}</span>
                                    </Link>
                                  </SidebarMenuSubButton>
                                ) : (
                                  <SidebarMenuSubButton
                                    asChild
                                    onClick={subItem.onClick}
                                    className="w-full"
                                  >
                                    <span>{subItem.title}</span>
                                  </SidebarMenuSubButton>
                                )}
                                <Button
                                  variant={"ghost"}
                                  size={"iconXSm"}
                                  className="hover:bg-themeinput"
                                  onClick={() =>
                                    togglePin(item.title, subItem.title)
                                  }
                                >
                                  {pinnedItems[item.title]?.includes(
                                    subItem.title
                                  ) ? (
                                    <PinSolid className="w-4 h-4 min-w-4 text-muted-foreground" />
                                  ) : (
                                    <Pin className="w-4 h-4 min-w-4 text-muted-foreground" />
                                  )}
                                </Button>
                              </SidebarMenuSubItem>
                            }
                          />
                        ))}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </SidebarMenuItem>
                </Collapsible>
              ) : null}

              {/* Render Pinned Sub-Items Separately Only When Parent is Collapsed */}
              {!isOpen && open && pinnedSubItems.length > 0 ? (
                <div className="flex flex-col gap-1 py-[2px] pl-[1px] pb-[2.2px]">
                  {pinnedSubItems.map((subItem) => (
                    <SidebarMenuItem
                      key={`${item.title}-${subItem.title}`}
                      className="flex justify-between items-center pl-6 pr-[5px]"
                    >
                      <SidebarMenuSubButton
                        asChild
                        className={`w-full ${
                          (subItem.urls ?? []).some(
                            (url) =>
                              url.includes(router.location.pathname) ||
                              router.location.pathname.includes(url)
                          )
                            ? "bg-customConicGradient text-white hover:text-white"
                            : ""
                        }`}
                      >
                        {subItem.urls && subItem.urls.length > 0 ? (
                          <Link to={subItem.urls[0]}>
                            <span>{subItem.title}</span>
                          </Link>
                        ) : (
                          <span>{subItem.title}</span>
                        )}
                      </SidebarMenuSubButton>

                      <Button
                        variant={"ghost"}
                        size={"iconXSm"}
                        className="hover:bg-themeinput"
                        onClick={() => togglePin(item.title, subItem.title)}
                      >
                        <PinSolid className="w-4 h-4 min-w-4 text-muted-foreground" />
                      </Button>
                    </SidebarMenuItem>
                  ))}
                </div>
              ) : null}
            </div>
          ) : item.isComingSoon ? (
            <SidebarMenuButton
              className={"justify-between opacity-50 w-full"}
              tooltip={`${item.title} - Coming Soon`}
            >
              {item.icon ? <item.icon /> : item.image}
              <span className="w-full">{item.title}</span>
              <Badge className="w-fit text-nowrap min-w-[103px] font-medium size-sx bg-purple-100 hover:bg-purple-100 text-themedestructive">
                Coming Soon
              </Badge>
            </SidebarMenuButton>
          ) : item.urls && (item.urls ?? []).length > 0 ? (
            item.featureName ? (
              <AccessControl
                feature={item.featureName}
                FeatureUnlocked={
                  <Link to={item.urls[0]}>
                    <SidebarMenuButton
                      className={
                        (item.urls ?? []).some(
                          (url) =>
                            url.includes(router.location.pathname) ||
                            router.location.pathname.includes(url)
                        )
                          ? "bg-customConicGradient text-white hover:text-white"
                          : ""
                      }
                      tooltip={item.title}
                    >
                      {item.icon ? <item.icon /> : item.image}
                      <span>{item.title}</span>
                      {(item.items ?? []).length > 0 ? (
                        <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                      ) : null}
                    </SidebarMenuButton>
                  </Link>
                }
                FeatureLocked={
                  <SidebarMenuButton
                    className={"justify-between opacity-50 w-full"}
                    tooltip={item.title}
                  >
                    {item.icon ? <item.icon /> : item.image}
                    <span className="w-full">{item.title}</span>

                    <div className="opacity-50">{<LockTagSmall />}</div>
                  </SidebarMenuButton>
                }
              />
            ) : (
              <Link to={item.urls[0]}>
                <SidebarMenuButton
                  className={isActive(item.urls ?? [])}
                  tooltip={item.title}
                >
                  {item.icon ? <item.icon /> : item.image}
                  <span className="w-full">{item.title}</span>
                  {item.isNew ? (
                    <Badge className="w-fit font-medium size-sx bg-purple-100 hover:bg-purple-100 text-themedestructive">
                      New
                    </Badge>
                  ) : null}
                </SidebarMenuButton>
              </Link>
            )
          ) : (
            <SidebarMenuButton
              className={isActive(item.urls ?? [])}
              tooltip={item.title}
              onClick={item.onClick}
            >
              {item.icon ? <item.icon /> : item.image}
              <span>{item.title}</span>
              {(item.items ?? []).length > 0 ? (
                <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
              ) : null}
            </SidebarMenuButton>
          );
        })}
      </SidebarMenu>
    </SidebarGroup>
  );
}
