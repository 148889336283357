import { defaultUseQueryRefetchOptions } from "@/_shared/constants";
import { CreativeOSPages } from "@/_shared/subscriptionPlans";
import AccessControl from "@/components/AccessControl";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { AdFilter, Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { InspirationRequestNotifier } from "@/components/custom-components/InspirationRequestNotifier";
import { Loader } from "@/components/custom-components/Loader";
import NotFoundBox from "@/components/custom-components/NotFound";
import { ErrorDisplay } from "@/components/error.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { Ad, DiscoverGridView } from "@/components/templates/DiscoverGridView";
import { PostHogEvents } from "@/hooks/useCapturePosthogEvent.tsx";
import {
  AdFeedFilterOption,
  AdSelectedFilters,
  TemplateAdSearchParams,
} from "@/hooks/useFilterFeed.tsx";
import useUserPermissions from "@/hooks/useUserPermissions";
import { featureFlagKeys } from "@/utils/data/featureFlags.ts";
import { getInspirationTabs } from "@/utils/data/feeds.ts";
import { otherFilterOptions } from "@/utils/data/filters.ts";
import { trpc } from "@/utils/trpc.ts";
import { Shuffle } from "@mynaui/icons-react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useFeatureFlagEnabled, usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { z } from "zod";
import {
  FormatFilter,
  StatusFilters,
  TimeLiveFilters,
} from "../../../../src/server/types";
import { orderFilter as OrderFilterType } from "../../../../src/shared/airtable";


export const Route = createFileRoute("/feeds/inspiration/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): TemplateAdSearchParams => {
    const industry = search?.industry as string | undefined;
    const formats = search?.formats as string | undefined;
    const timeLive = search?.timeLive as string | undefined;
    const statuses = search?.statuses as string | undefined;
    const brands = search?.brands as string | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      industry,
      formats,
      timeLive,
      statuses,
      sideBarOpen,
      orderFilter,
      brands,
    };
  },
});

function All() {
  const navigate = useNavigate();

  const {
    industry: queryIndustry,
    formats: queryFormats,
    timeLive: queryTimeLive,
    statuses: queryStatus,
    orderFilter,
    brands: queryBrands,
  } = Route.useSearch();

  const [allData, setAllData] = useState<Ad[] | undefined>(undefined);
  const [filterOptions, setFilterOptions] = useState<AdFeedFilterOption[]>([]);
  const [cursor, setCursor] = useState(1);
  const posthog = usePostHog();

  const [selectedFilters, setSelectedFilters] = useState<AdSelectedFilters>({
    categories: queryIndustry ? queryIndustry.split(",") : undefined,
    formats: queryFormats
      ? (queryFormats.split(",") as FormatFilter[])
      : undefined,
    timeLive: queryTimeLive
      ? (queryTimeLive.split(",") as TimeLiveFilters[])
      : undefined,
    statuses: queryStatus
      ? (queryStatus.split(",") as StatusFilters[])
      : undefined,
    brands: queryBrands ? (queryBrands.split(",") as string[]) : undefined,
  });

  // Get the page industry to be passed to the filter
  const { data: pageIndustry } = trpc.getPageCategories.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  const { data: brands } = trpc.getBrands.useQuery(
    undefined,
    defaultUseQueryRefetchOptions
  );

  useEffect(() => {
    if (pageIndustry && brands && filterOptions.length === 0) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Avoid redundant updates
        return [
          ...otherFilterOptions,
          {
            title: "Industry",
            counter: 0,
            optionItems: pageIndustry.map((i) => ({
              label: i.name,
              value: false,
            })),
          },
          {
            title: "Brand",
            counter: 0,
            optionItems: brands.map((i) => ({
              label: i.brandName,
              logo: i.brandImage,
              value: false,
              id: i.brandId,
            })),
          },
        ];
      });
    }
  }, [pageIndustry, brands, filterOptions.length]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => ({
      ...option,
      optionItems: option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === "Industry" &&
            selectedFilters.categories?.includes(item.label)) ||
          (option.title === "Brand" &&
            selectedFilters.brands?.includes(item.id ?? "")) ||
          (option.title === "Format" &&
            selectedFilters.formats?.includes(item.label as FormatFilter)) ||
          (option.title === "Time Live" &&
            selectedFilters.timeLive?.includes(
              item.label as TimeLiveFilters
            )) ||
          (option.title === "Status" &&
            selectedFilters.statuses?.includes(item.label as StatusFilters)),
      })),
    }));

    setFilterOptions((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(updatedOptions)) return prev;
      return updatedOptions;
    });
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: {
      industry?: string;
      formats?: string;
      timeLive?: string;
      statuses?: string;
      brands?: string;
    }) => {
      const searchParams = new URLSearchParams();

      if (params.industry) searchParams.set("industry", params.industry);
      if (params.brands) searchParams.set("brands", params.brands);
      if (params.formats) searchParams.set("formats", params.formats);
      if (params.timeLive) searchParams.set("timeLive", params.timeLive);
      if (params.statuses) searchParams.set("statuses", params.statuses);

      navigate({
        to: "/feeds/inspiration",
        replace: true,
        search: (old) => {
          return { ...old, ...params, orderFilter };
        },
      });
    },
    [navigate]
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedIndustry: string[] = [];
      const selectedBrands: string[] = [];
      const selectedFormats: FormatFilter[] = [];
      const selectedTimeLive: TimeLiveFilters[] = [];
      const selectedStatus: StatusFilters[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Industry") selectedIndustry.push(item.label);
            if (group.title === "Brand") selectedBrands.push(`${item.id}`);
            if (group.title === "Format")
              selectedFormats.push(item.label as FormatFilter);
            if (group.title === "Time Live")
              selectedTimeLive.push(item.label as TimeLiveFilters);
            if (group.title === "Status")
              selectedStatus.push(item.label as StatusFilters);
          }
        });
      });

      setSelectedFilters({
        categories: selectedIndustry.length > 0 ? selectedIndustry : undefined,
        brands: selectedBrands.length > 0 ? selectedBrands : undefined,
        formats: selectedFormats.length > 0 ? selectedFormats : undefined,
        timeLive: selectedTimeLive.length > 0 ? selectedTimeLive : undefined,
        statuses: selectedStatus.length > 0 ? selectedStatus : undefined,
      });

      updateQueryString({
        industry:
          selectedIndustry.length > 0 ? selectedIndustry.join(",") : undefined,
        brands:
          selectedBrands.length > 0 ? selectedBrands.join(",") : undefined,
        formats:
          selectedFormats.length > 0 ? selectedFormats.join(",") : undefined,
        timeLive:
          selectedTimeLive.length > 0 ? selectedTimeLive.join(",") : undefined,
        statuses:
          selectedStatus.length > 0 ? selectedStatus.join(",") : undefined,
      });
    },
    [updateQueryString]
  );

  useEffect(() => {
    if (!selectedFilters) return;

    const params: Record<string, string | undefined> = {};

    if (selectedFilters.categories)
      params.industry = selectedFilters.categories.join(",");
    if (selectedFilters.brands)
      params.brands = selectedFilters.brands.join(",");
    if (selectedFilters.formats)
      params.formats = selectedFilters.formats.join(",");
    if (selectedFilters.timeLive)
      params.timeLive = selectedFilters.timeLive.join(",");
    if (selectedFilters.statuses)
      params.statuses = selectedFilters.statuses.join(",");
  }, [selectedFilters]);

  const {
    data: inspirationFeed,
    fetchNextPage,
    refetch,
    isLoading,
    isError,
    hasNextPage,
    isFetchingNextPage,
  } = trpc.filterAdFeed.useInfiniteQuery(
    {
      ...selectedFilters,
      order: orderFilter,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      initialCursor: cursor,
    }
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      !isLoading &&
      inspirationFeed &&
      allData &&
      allData.length &&
      inspirationFeed.pages[inspirationFeed.pages.length - 1]?.ads.length > 0
    ) {
      fetchNextPage();
    }
  }, [
    inView,
    hasNextPage,
    fetchNextPage,
    inspirationFeed,
    isLoading,
    isFetchingNextPage,
    allData,
  ]);

  useEffect(() => {
    if (!inspirationFeed) return;

    const uniqueAds = new Map<number, Ad>();
    for (const page of inspirationFeed.pages) {
      page.ads.forEach((ad) => uniqueAds.set(ad.id, ad));
    }

    setAllData((prevData) => {
      const newData = Array.from(uniqueAds.values());
      if (JSON.stringify(prevData) === JSON.stringify(newData)) return prevData; // Avoid redundant updates
      return newData;
    });
  }, [inspirationFeed]);

  const {userLimits} = useUserPermissions();

  useEffect(() => {
    if (selectedFilters) {
      setCursor((prevCursor) => {
        if (prevCursor === 1) return prevCursor; // Avoid unnecessary reset
        return 1;
      });
    }
  }, [selectedFilters]);

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }
  const flagEnabled = useFeatureFlagEnabled(featureFlagKeys.emailFeeds);
  const [isResolved, setIsResolved] = useState(false);

  useEffect(() => {
    if (flagEnabled !== undefined) {
      setIsResolved(true); // Mark the flag as resolved once its value is known
    }
  }, [flagEnabled]);

  if (!isResolved) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <Loader />
      </div>
    );
  }
  return (
    <AccessControl page={CreativeOSPages.AD_INSPIRATIONS}>
      <Stack className="gap-3 lg:gap-6">
        <Stack className="relative gap-3 lg:gap-8">
          <Stack className="gap-3 lg:gap-6 relative">
            <div
              className={
                "flex justify-between gap-5 items-center flex-wrap xs:flex-nowrap"
              }
            >
              <Stack>
                <Text weight="semibold" size={"xxl"}>
                  The Best. Not The Rest.
                </Text>
                <Text
                  weight="normal"
                  size={"lg"}
                  className="text-thememutedforeground flex"
                >
                  Explore top-tier examples handpicked to spark your creativity.
                </Text>
              </Stack>
              <div className={"flex gap-2"}>
                <FeatureTabs
                  defaultOrderValue={"Random"}
                  tabItems={getInspirationTabs({ showEmailFeeds: flagEnabled })}
                />
                <div className="flex items-center flex-wrap justify-end gap-2">
                  <div className="lg:hidden">
                    {
                      <OrderFilterSelect
                        defaultFilter={orderFilter || "Random"}
                        options={[
                          "Random",
                          "Recent",
                          "Oldest",
                          "Popular",
                          "Most Requested",
                        ]}
                      />
                    }
                  </div>

                  <Button
                    size={"sm"}
                    className="h-8"
                    variant={"brandGradient"}
                    onClick={() => {
                      if (orderFilter !== "Random") {
                        navigate({
                          search: (old) => {
                            return {
                              ...old,
                              orderFilter: "Random",
                            };
                          },
                          params: (old) => {
                            return {
                              ...old,
                            };
                          },
                        });

                        posthog?.capture(
                          PostHogEvents.USER_SHUFFLED_AD_INSPIRATION
                        );
                      } else {
                        refetch();
                      }
                    }}
                  >
                    <Shuffle className="h-5" />
                    Shuffle
                  </Button>
                </div>
              </div>
            </div>
          </Stack>
        </Stack>

        <div
          className={`lg:sticky lg:top-[4.3rem] lg:z-10 lg:bg-themebackground dark:lg:bg-themebackgrounddark  lg:py-2 flex ${pageIndustry && filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-start gap-1.5`}
        >
          {pageIndustry && filterOptions && filterOptions.length > 0 && (
            <AdFilter
              initialOptions={filterOptions}
              onOptionsChange={handleOptionsChange}
            />
          )}

          <div className={"hidden lg:flex"}>
            <OrderFilterSelect
              defaultFilter={orderFilter || "Random"}
              options={[
                "Random",
                "Recent",
                "Oldest",
                "Popular",
                "Most Requested",
              ]}
            />
          </div>
        </div>
        {userLimits ? <InspirationRequestNotifier pageType="ad" limitLeft={userLimits.request.ad} /> : null}
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-screen">
            <Loader />
          </div>
        ) : (
          <div>
            {inspirationFeed && allData && allData.length === 0 ? (
              <NotFoundBox />
            ) : (
              inspirationFeed &&
              allData &&
              allData.length > 0 && <DiscoverGridView data={allData} />
            )}
          </div>
        )}

        <div
          ref={scrollRef}
          className="flex justify-center w-full min-h-14 h-14"
        >
          {isFetchingNextPage && <Loader />}
        </div>
      </Stack>
    </AccessControl>
  );
}

export default All;
