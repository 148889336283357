import { CREATIVE_TOOLKIT_LIMIT_REACHED_BODY, CREATIVE_TOOLKIT_LIMIT_REACHED_TITLE } from "@/_shared/constants";
import {
  CreativeOSFeatures,
  CreativeOSPages,
} from "@/_shared/subscriptionPlans";
import AccessControl from "@/components/AccessControl";
import {
  AnalysisResults,
  PersuasionScoreTabs,
} from "@/components/CopyGrader.tsx";
import { CopyGraderChecker } from "@/components/CopyGraderChecker.tsx";
import { Stack } from "@/components/custom-components";
import { LockTagSmall } from "@/components/custom-components/LockTag";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/shadcn/breadcrumb.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/form.tsx";
import { Textarea } from "@/components/shadcn/textarea.tsx";
import { useCopyGrader } from "@/hooks/useCopyGrader.tsx";
import { useElementSize } from "@/hooks/useElementSize";
import showToastNotification from "@/hooks/useShowToast";
import useUserPermissions from "@/hooks/useUserPermissions";
import { trpc } from "@/utils/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { BookOpen, Box, ChevronUpDown } from "@mynaui/icons-react";
import { Link, createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { z } from "zod";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/creative-toolkit/copy-grader/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [fleschScoreShow, setFleschScoreShow] = useState<boolean>(false);
  const [squareRef, { width = 0 }] = useElementSize();
  const { isRefetchingUserLimits, isGettingUserLimits, userLimits } =
    useUserPermissions();
  const [analysisResults, setAnalysisResults] =
    useState<AnalysisResults | null>(null);

  const formSchema = z.object({
    copyText: z
      .string({ required_error: "Copy text is required" })
      .max(5000, { message: "Copy text must be less than 5000 characters" }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      copyText: "",
    },
  });
  const utils = trpc.useUtils();

  useEffect(() => {
    if (userLimits && userLimits.creativeToolkit < 1) {
      showToastNotification("warning", {
        message: CREATIVE_TOOLKIT_LIMIT_REACHED_TITLE,
        description: CREATIVE_TOOLKIT_LIMIT_REACHED_BODY,
      });
    }
  }, [userLimits?.creativeToolkit]);

  const { getReadingLevel, getPersuasionLevel, getSummary } = useCopyGrader({
    copyText: form.getValues("copyText"),
  });
  const { mutateAsync: captureCreativeToolkitUsage, isPending } =
    trpc.captureCreativeToolkitUsage.useMutation();
  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    captureCreativeToolkitUsage().then(() => {
      const {
        readabilityScore,
        totalCharacters,
        totalWords,
        totalSyllables,
        totalSentences,
        persuasionAnalysisResults,
        getReadingLevel,
      } = useCopyGrader({
        copyText: values.copyText,
      });

      setAnalysisResults({
        readability: {
          score: readabilityScore,
          metrics: {
            characters: totalCharacters,
            words: totalWords,
            sentences: totalSentences,
            syllables: totalSyllables,
          },
          grade: getReadingLevel(readabilityScore),
        },
        persuasion: persuasionAnalysisResults,
      });
      utils.getUserLimits.invalidate();
    });
  };

  return (
    <AccessControl page={CreativeOSPages.CREATIVE_TOOLKIT_COPY_GRADER}>
      {fleschScoreShow && analysisResults?.readability.score ? (
        <CopyGraderChecker
          open={fleschScoreShow}
          onOpenChange={() => setFleschScoreShow(false)}
          flesch={analysisResults?.readability.score}
        />
      ) : null}
      <Stack ref={squareRef} className="gap-3 lg:gap-6">
        <Stack className="gap-3 lg:gap-8">
          <Stack className="gap-3 lg:gap-6">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink asChild>
                    <Link to={"/feeds/creative-toolkit"}>Toolkit</Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Copy Grader</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <div className={""}>
                <h1 className={"text-lg lg:text-2xl font-semibold"}>
                  Grade Your Copy. Refine Every Word.
                </h1>
                <p
                  className={
                    "text-lg lg:text-base text-thememutedforeground mt-2"
                  }
                >
                  Put your text through our readability check for crisp, clear,
                  and captivating ads.
                </p>
              </div>
            </div>
          </Stack>
        </Stack>
        <div>
          <div className={"border rounded"}>
            <div
              className={
                "flex justify-between items-center gap-5 p-2  bg-muted text-sm"
              }
            >
              <h3 className={""}>Copy to Grade</h3>

              <div
                className={"flex items-center gap-2 text-thememutedforeground"}
              >
                <h3>Load Example Copy</h3>
                <ChevronUpDown size={"1rem"} />
              </div>
            </div>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(handleSubmit)}
                className={"p-2"}
              >
                <FormField
                  control={form.control}
                  name="copyText"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Textarea
                          className={"border-none"}
                          rows={4}
                          placeholder={"Enter Your Copy"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className={"flex justify-between items-center gap-2 mt-2"}>
                  <p className={"text-sm"}>
                    Characters remaining: {form.watch("copyText").length}
                    /5000
                  </p>
                  <AccessControl
                    feature={CreativeOSFeatures.USE_CREATIVE_TOOLKIT}
                    FeatureUnlocked={
                      <Button
                        disabled={
                          form.getValues("copyText") === "" ||
                          isPending ||
                          isRefetchingUserLimits ||
                          isGettingUserLimits
                        }
                        size={"sm"}
                        loading={isPending}
                      >
                        Analyze
                      </Button>
                    }
                    FeatureLocked={
                      <Button disabled={true} size={"sm"}>
                        Analyze
                        <div>{<LockTagSmall />}</div>
                      </Button>
                    }
                  />
                </div>
              </form>
            </Form>
          </div>
          <div className={"grid lg:grid-cols-2 gap-6"}>
            <div className={""}>
              <div className={""}>
                <div className={"py-3 flex justify-between items-center"}>
                  <div className={"flex items-center gap-1.5"}>
                    <BookOpen size={"1rem"} />
                    <h5 className={"text-sm"}>Reading Score</h5>
                  </div>
                  <div>
                    <h5 className={"text-sm font-light text-muted-foreground"}>
                      {analysisResults?.readability.score
                        ? getReadingLevel(analysisResults?.readability.score)
                        : "-"}
                    </h5>
                  </div>
                </div>
                <div className={"flex flex-col justify-center items-center"}>
                  <div className={"py-6 relative"}>
                    <SemiCircleProgressBar
                      strokeWidth={6}
                      percentage={analysisResults?.readability.score}
                      diameter={width > 750 ? 300 : undefined}
                      stroke={
                        analysisResults?.readability?.score
                          ? analysisResults?.readability.score >= 80
                            ? "#0CDB71"
                            : analysisResults?.readability.score >= 60 &&
                                analysisResults?.readability.score <= 79
                              ? "#F7A940"
                              : analysisResults?.readability.score >= 50 &&
                                  analysisResults?.readability.score <= 59
                                ? "#FF0054"
                                : "#CC2C69"
                          : "#E2E8F0"
                      }
                    />
                    <div
                      className={
                        "absolute bottom-5 left-1/2 transform -translate-x-1/2 text-center"
                      }
                    >
                      <h4 className={"text-4xl font-bold mb-2"}>
                        {analysisResults?.readability?.score
                          ? analysisResults?.readability.score < 0
                            ? "-"
                            : analysisResults?.readability.score
                          : "-"}
                      </h4>
                      <p className={"font-medium"}>
                        {analysisResults?.readability?.score &&
                          getSummary(analysisResults?.readability.score)}
                      </p>
                    </div>
                  </div>
                  {analysisResults && analysisResults.readability.metrics && (
                    <div
                      className={"mt-6 space-y-6 flex flex-col items-center"}
                    >
                      <p className={"font-light"}>
                        {analysisResults?.readability.score ? (
                          <span>
                            This copy reads at a{" "}
                            {getReadingLevel(
                              analysisResults?.readability.score
                            )}{" "}
                            Level
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Button
                        onClick={() => setFleschScoreShow(true)}
                        size={"sm"}
                        variant={"secondary"}
                      >
                        Learn More
                      </Button>
                    </div>
                  )}
                </div>
                {analysisResults && analysisResults.readability.metrics && (
                  <div className={"grid grid-cols-2"}>
                    {[
                      {
                        title: "Characters",
                        value: analysisResults?.readability.metrics.characters,
                      },
                      {
                        title: "Words",
                        value: analysisResults?.readability.metrics.words,
                      },
                      {
                        title: "Sentences",
                        value: analysisResults?.readability.metrics.sentences,
                      },
                      {
                        title: "Syllables",
                        value: analysisResults?.readability.metrics.syllables,
                      },
                    ].map((item) => (
                      <div
                        key={item.title}
                        className={"flex flex-col text-center"}
                      >
                        <p
                          className={
                            "font-light text-sm text-thememutedforeground"
                          }
                        >
                          {item.title}
                        </p>
                        <p className={"text-lg font-bold"}>{item.value}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={""}>
              <div className={"lg:pl-6 lg:border-l pb-6"}>
                <div className={"py-3 flex justify-between items-center"}>
                  <div className={"flex items-center gap-1.5"}>
                    <Box size={"1rem"} />
                    <h5 className={"text-sm"}>Persuasion Score</h5>
                  </div>
                  <div></div>
                </div>
                <div className={"flex flex-col justify-center items-center"}>
                  <div className={"py-6 relative"}>
                    <SemiCircleProgressBar
                      strokeWidth={6}
                      percentage={analysisResults?.persuasion.score}
                      diameter={width > 750 ? 300 : undefined}
                      stroke={
                        analysisResults?.persuasion?.score
                          ? analysisResults?.persuasion.score >= 80
                            ? "#0CDB71"
                            : analysisResults?.persuasion.score >= 60 &&
                                analysisResults?.persuasion.score <= 79
                              ? "#F7A940"
                              : analysisResults?.persuasion.score >= 50 &&
                                  analysisResults?.persuasion.score <= 59
                                ? "#FF0054"
                                : "#CC2C69"
                          : "#E2E8F0"
                      }
                    />
                    <div
                      className={
                        "absolute bottom-5 left-1/2 transform -translate-x-1/2 text-center"
                      }
                    >
                      <h4 className={"text-4xl font-bold mb-2"}>
                        {analysisResults?.persuasion?.score
                          ? analysisResults?.persuasion.score < 0
                            ? "-"
                            : analysisResults?.persuasion.score
                          : "-"}
                      </h4>
                      <p className={"font-medium"}>
                        {analysisResults?.persuasion?.score &&
                          getPersuasionLevel(analysisResults?.persuasion.score)
                            .level}
                      </p>
                    </div>
                  </div>

                  <div className={"mt-6 space-y-6 flex flex-col items-center"}>
                    {analysisResults?.persuasion?.score ? (
                      <p className={"font-light"}>
                        {
                          getPersuasionLevel(analysisResults?.persuasion.score)
                            .description
                        }
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className={"mt-6"}>
                  {analysisResults && (
                    <PersuasionScoreTabs
                      analysisResults={analysisResults.persuasion}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Stack>
    </AccessControl>
  );
}
