import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import { SidebarButton } from "@/components/SidebarButton";
import { CreativeOsHeader } from "@/components/custom-components/Header";
import { Loader } from "@/components/custom-components/Loader";
import { AppSidebar } from "@/components/custom-components/Sidebar";
import { ProtectedArea } from "@/components/protectedArea.tsx";
import { SidebarInset, SidebarProvider } from "@/components/shadcn/sidebar";
import { ManageAccountV2 } from "@/components/templates/AppHeader";
import useUserPermissions from "@/hooks/useUserPermissions";
import { Outlet, createFileRoute } from "@tanstack/react-router";
import { useEffect, useMemo, useState } from "react";

type FeedsProps = {
  sideBarOpen?: boolean | undefined;
  sidebarCollapsed?: boolean | undefined;
  cacheBuster?: number | undefined;
};

export const Route = createFileRoute("/feeds")({
  component: Feeds,
  validateSearch: (search: Record<string, unknown>): FeedsProps => {
    return {
      sideBarOpen: search.sideBarOpen as boolean | undefined,
      sidebarCollapsed: search.sidebarCollapsed as boolean | undefined,
      cacheBuster: search.cacheBuster as number | undefined,
    };
  },
});

export type PermissionsData = {
  userCanAccessEverything: boolean;
  userCanAccessAds: boolean;
  userCanAccessExperts: boolean;
  ID: string;
  usersPlans: string[];
  email: string;
};

function Feeds() {
  const [trialEndingPromptShow, setTrialEndingPromptShow] =
    useState<boolean>(false);
  const { permissions: permissionData, isLoading: fetchingPermissions } =
    useUserPermissions();

  const shouldShowTrialPrompt = useMemo(() => {
    if (!permissionData || fetchingPermissions) return false;

    return (
      !permissionData.userCanAccessEverything &&
      permissionData.usersPlans.length === 0
    );
  }, [permissionData, fetchingPermissions]);

  useEffect(() => {
    if (
      shouldShowTrialPrompt &&
      !trialEndingPromptShow &&
      !fetchingPermissions
    ) {
      setTrialEndingPromptShow(true);
    }
  }, [shouldShowTrialPrompt, trialEndingPromptShow, fetchingPermissions]);

  return (
    <ProtectedArea>
      {trialEndingPromptShow && permissionData && !fetchingPermissions && (
        <FreeTrialEnding
          open={trialEndingPromptShow}
          onOpenChange={() => setTrialEndingPromptShow(true)}
          permissionData={permissionData}
        />
      )}
      {fetchingPermissions ? (
        <div className="h-[100vh] w-[100vw] flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <SidebarProvider>
          <AppSidebar />
          <SidebarInset>
            <header className="px-3 lg:px-5 sticky top-0  z-20  flex flex-col py-5 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-auto bg-background">
              <div className="lg:hidden flex w-full h-12 bg-themebackground dark:bg-themebackgrounddark items-center justify-between">
                <>
                  <img
                    alt={"CreativeOS"}
                    src={"/images/logo-full-dark_mode.png"}
                    className={"h-5 dark:block hidden"}
                  />
                  <img
                    alt={"CreativeOS"}
                    src={"/images/logo-full-light_mode.png"}
                    className={"h-5 dark:hidden block"}
                  />
                </>
                <div className="items-center flex gap-3">
                  <ManageAccountV2 showName={false} />
                  <SidebarButton />
                </div>
              </div>
              <CreativeOsHeader />
            </header>
            <div className="px-3 lg:px-5 w-full">
              <Outlet />
            </div>
          </SidebarInset>
        </SidebarProvider>
      )}
    </ProtectedArea>
  );
}
