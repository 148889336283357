import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import { CanvaLogo, CanvaLogo2, FigmaLogo } from "@/assets/template-logo.tsx";
import AccessControl from "@/components/AccessControl";
import AdInspirationDetails from "@/components/AdInspirationDetails.tsx";
import TemplateDetails from "@/components/TemplateDetails.tsx";
import { copyToClipboard } from "@/components/copyToClipboard.tsx";
import { Stack, Text } from "@/components/custom-components";
import { AdHeader } from "@/components/custom-components/AdHeader";
import { AddToBoard } from "@/components/custom-components/AddToBoard/index.tsx";
import { DotsLoader } from "@/components/custom-components/Loader";
import { PingCirclesGreen } from "@/components/custom-components/PingCircles";
import { TemplatesFooter } from "@/components/custom-components/TemplatesHeader/index.tsx";
import WatermarkImage from "@/components/custom-components/Watermark";
import { Badge } from "@/components/shadcn/badge.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { DialogPortal } from "@/components/shadcn/dialog";
import { Ad } from "@/components/templates/DiscoverGridView";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { useCapturePostHogEvent } from "@/hooks/useCapturePosthogEvent.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import showToastNotification from "@/hooks/useShowToast.tsx";
import useUserPermissions from "@/hooks/useUserPermissions";
import { trpc } from "@/utils/trpc.ts";
import { BrandFigma, Download, Layout, Link, Lock } from "@mynaui/icons-react";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { AirTableAdRecord } from "../../../../../../src/shared/airtable";

export interface AdDataProps {
  brandName?: string;
  avatar?: string;
  Ad: AirTableAdRecord;
  IsPublic: boolean; // If the ad is being viewed from a public user
  IsLocked: boolean; // If the ad is public, all but the requested one should be blurred
  IsShared?: boolean; // If the ad is public, all but the requested one should be blurred
  DesiredWidth: number;
  shouldInvalidateCache: boolean; // We don't want to perform a refetch when we're on the saved page
}

export interface AdCardProps extends ComponentPropsWithoutRef<"div"> {
  adData: AdDataProps;
  showTemplateButton?: boolean;
  showRecentButton?: boolean;
  openDetailsOnInit?: boolean;
  showWaterMark?: boolean;
}

export interface DiscoverAdCardProps extends ComponentPropsWithoutRef<"div"> {
  adData: Ad;
  DesiredWidth: number;
  IsPublic: boolean;
  IsRequested?: boolean;
  IsShared?: boolean;
  openDetailsOnInit?: boolean;
  IsLocked: boolean;
}

export const AdCard = forwardRef<HTMLDivElement, AdCardProps>(
  (
    {
      adData,
      showTemplateButton = false,
      showRecentButton = false,
      openDetailsOnInit = false,
      showWaterMark = false,
      ...props
    },
    ref
  ) => {
    const [saveOpen, setSaveOpen] = useState<boolean>(false);
    const [showTemplate, setShowTemplate] = useState<boolean>(true);
    const [detailsShow, setDetailsShow] = useState<boolean>(openDetailsOnInit);
    const [, copyToClipboard] = useCopyToClipboard();

    return (
      <div
        ref={ref}
        onMouseEnter={
          showTemplateButton || showRecentButton
            ? () => setShowTemplate(false)
            : undefined
        }
        onMouseLeave={
          showTemplateButton || showRecentButton
            ? () => setShowTemplate(true)
            : undefined
        }
        className={`relative rounded bg-[#F5F5F5] break-inside-avoid-column overflow-hidden
        `}
        {...props}
      >
        {detailsShow && adData && (
          <TemplateDetails
            data={adData}
            open={detailsShow}
            onCloseDrawer={() => setDetailsShow(false)}
            showWaterMark={showWaterMark}
          />
        )}
        <div className={`rounded min-h-56`}>
          {showWaterMark ? (
            <WatermarkImage imageUrl={adData.Ad.imageUrl ?? ""} />
          ) : (
            <img
              alt={"ad display"}
              loading={"lazy"}
              src={adData.Ad.imageUrl ?? ""}
              className={"w-full rounded-md min-h-30"}
            />
          )}
        </div>

        <AccessControl
          feature={CreativeOSFeatures.AD_TEMPLATE_NAV_BUTTON}
          FeatureLocked={
            adData.IsShared ? <SharedOverlay /> : <BlurredOverlay />
          }
          FeatureUnlocked={
            <>
              {adData.IsShared ? (
                <SharedOverlay />
              ) : (showTemplateButton || showRecentButton) && showTemplate ? (
                <div
                  className={
                    "absolute inset-x-0 inset-y-0 rounded justify-between flex flex-col items-stretch transition-opacity duration-300"
                  }
                >
                  <div className="flex justify-between p-3 flex-wrap">
                    {showTemplateButton && (
                      <Badge variant={"destructivePurple"} className="gap-1">
                        <Layout className="w-4 h-4" />
                        Template
                      </Badge>
                    )}
                    {showRecentButton && (
                      <Badge
                        variant={"destructivePurple"}
                        className="gap-1 bg-[#0F172A66]"
                      >
                        <PingCirclesGreen />
                        Just Added
                      </Badge>
                    )}
                  </div>
                </div>
              ) : null}
              <div
                className={
                  "absolute bg-opacity-40 inset-x-0 inset-y-0 bg-slate-900 rounded justify-between opacity-0 hover:opacity-100 flex flex-col items-stretch transition-opacity duration-300 cursor-pointer"
                }
              >
                <AccessControl
                  feature={CreativeOSFeatures.SHOW_AD_TEMPLATE_DETAILS}
                  FeatureUnlocked={
                    <>
                      <div className="flex justify-between items-center p-3">
                        <GetAdTemplate
                          Ad={adData.Ad}
                          IsPublic={adData.IsPublic}
                        />
                        <div className="flex gap-2 flex-wrap justify-end">
                          {!adData.IsPublic && adData && (
                            <ManuallySaveTemplateToBoard
                              open={saveOpen}
                              onOpenChange={setSaveOpen}
                              data={adData}
                              variant="secondary"
                            />
                          )}
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <a
                                href={adData.Ad.imageUrl ?? ""}
                                download={"image.png"}
                              >
                                <Button variant={"secondary"} size={"icon"}>
                                  <Download className="w-5 h-5" />
                                </Button>
                              </a>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Download as image</p>
                            </TooltipContent>
                          </Tooltip>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                onClick={() => {
                                  copyToClipboard(
                                    `${window.location.origin}/share/templates/${adData.Ad.atID}`
                                  );
                                  showToastNotification("success", {
                                    message: "Copied!",
                                  });
                                }}
                                size={"icon"}
                                variant={"secondary"}
                              >
                                <Link className="w-5 h-5" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Share a public link</p>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      </div>
                      <div
                        className="h-full w-full bg-slate-900 bg-opacity-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDetailsShow(true);
                        }}
                      />
                    </>
                  }
                  FeatureLocked={
                    <>
                      <div className="flex justify-end items-center p-3">
                        <div className="flex gap-2 flex-wrap justify-end">
                          {/**this access control is added so share users who have not logged in cannot save */}
                          <AccessControl
                            feature={CreativeOSFeatures.SAVE}
                            FeatureUnlocked={
                              <>
                                {!adData.IsPublic && adData && (
                                  <ManuallySaveTemplateToBoard
                                    open={saveOpen}
                                    onOpenChange={setSaveOpen}
                                    data={adData}
                                    variant="secondary"
                                  />
                                )}
                              </>
                            }
                            FeatureLocked={<></>}
                          />

                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                onClick={() => {
                                  copyToClipboard(
                                    `${window.location.origin}/share/templates/${adData.Ad.atID}`
                                  );
                                  showToastNotification("success", {
                                    message: "Copied!",
                                  });
                                }}
                                size={"icon"}
                                variant={"secondary"}
                              >
                                <Link className="w-5 h-5" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Share a public link</p>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="h-full w-full bg-slate-900 bg-opacity-0" />
                    </>
                  }
                />
                <div className="flex justify-between p-3">
                  <TemplatesFooter
                    footerTitle={adData.Ad.brandName ?? "Template"}
                    brandImage={adData.Ad.brandImage}
                    footerType=""
                    titleColor="popover"
                  />
                </div>
              </div>
            </>
          }
        />
      </div>
    );
  }
);

export const BlurredOverlay = () => (
  <div
    className={
      "absolute inset-x-0 inset-y-0 rounded justify-between flex flex-col items-stretch transition-opacity duration-300 bg-slate-900 bg-opacity-50"
    }
  >
    <div className="flex justify-between p-3">
      <Badge variant={"destructivePurple"} className="gap-1 bg-themeprimary">
        {/* <div className="w-[0.333rem] h-[0.333rem] rounded-full bg-green-400" /> */}
        <Lock className="h-4 w-4" />
        Locked
      </Badge>
    </div>
  </div>
);
export const SharedOverlay = () => (
  <div
    className={
      "absolute inset-x-0 inset-y-0 rounded justify-between flex flex-col items-stretch"
    }
  >
    <div className="flex justify-between p-3">
      <Badge
        variant={"destructivePurple"}
        className="gap-1 bg-themeprimary bg-opacity-40"
      >
        <div className="w-[0.333rem] h-[0.333rem] rounded-full bg-green-400" />
        Shared With You
      </Badge>
    </div>
  </div>
);

export const DiscoverAdCard = forwardRef<HTMLDivElement, DiscoverAdCardProps>(
  (
    {
      adData,
      DesiredWidth,
      openDetailsOnInit = false,
      IsLocked,
      IsShared,
      IsPublic,
      IsRequested,
      ...props
    },
    ref
  ) => {
    const [detailsShow, setDetailsShow] = useState<boolean>(openDetailsOnInit);
    const [adRequested, setAdRequested] = useState<boolean>(false);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false); // State to manage video play/pause
    const videoRef = useRef<HTMLVideoElement>(null); // Ref for video element
    const utils = trpc.useUtils();
    const [, copyToClipboard] = useCopyToClipboard();
    const { userLimits } = useUserPermissions();
    // Manage video playback based on state
    useEffect(() => {
      if (videoRef.current) {
        if (isVideoPlaying) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    }, [isVideoPlaying]);

    const { mutateAsync: requestAdTemplate } =
      trpc.requestAdTemplateRpc.useMutation();

    if (!DesiredWidth) {
      return <></>;
    }

    return (
      <div
        ref={ref}
        className={`cursor-pointer relative rounded-md bg-[#F5F5F5] break-inside-avoid-column
  `}
        {...props}
      >
        {/* Background image or video */}
        <div className="relative w-full min-h-56">
          {adData.imageUrl && (
            <img
              className="rounded-md w-full"
              alt="ad display"
              loading="lazy"
              src={adData.imageUrl}
            />
          )}
          {adData.videoUrl && (
            <video
              ref={videoRef} // Reference the video element
              className="rounded-md w-full z-10"
              muted
              loop
              controls={false}
              playsInline
              src={adData.videoUrl}
            />
          )}
          {IsShared && !isVideoPlaying && <SharedOverlay />}

          <AccessControl
            feature={CreativeOSFeatures.AD_INSPIRATION_NAV_BUTTON}
            FeatureUnlocked={
              <>
                {/* Hover overlay */}
                <div
                  className={`rounded-md p-3 absolute top-0 left-0 w-full h-full flex flex-col justify-between ${adData.imageUrl ? "bg-slate-900 bg-opacity-50 opacity-0 transition-opacity duration-300 hover:opacity-100" : ""}`}
                  onMouseEnter={() => setIsVideoPlaying(true)} // Start playing video when mouse hovers over the overlay
                  onMouseLeave={() => setIsVideoPlaying(false)} // Stop playing video when mouse leaves the overlay
                >
                  {detailsShow && adData && (
                    <AdInspirationDetails
                      data={adData}
                      open={detailsShow}
                      IsPublic={IsPublic}
                      onOpenChange={() => setDetailsShow(false)}
                    />
                  )}
                  <div className={"flex justify-between gap-5 items-center"}>
                    <div>
                      {!(adData.videoUrl || IsRequested) && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <AccessControl
                              feature={
                                CreativeOSFeatures.AD_INSPIRATION_REQUEST
                              }
                              FeatureUnlocked={
                                <Button
                                  onClick={() => {
                                    setAdRequested(true);
                                    requestAdTemplate({
                                      adId: adData.id,
                                    }).then(() => {
                                      showToastNotification("success", {
                                        message: `${Math.max((userLimits?.request.ad ?? 0) - 1, 0)} Template Requests left!`,
                                        description: `You have ${Math.max((userLimits?.request.ad ?? 0) - 1, 0)} template requests left for the month.`,
                                      });
                                      utils.getUserLimits.invalidate();
                                      utils.permissions.invalidate();
                                      setTimeout(() => {
                                        setAdRequested(false);
                                      }, 5000);
                                    });
                                  }}
                                  variant={"outline"}
                                >
                                  {adRequested ? "Requested!" : "Request"}
                                </Button>
                              }
                              FeatureLocked={
                                <Button
                                  variant={"outline"}
                                  className={`opacity-50`}
                                >
                                  <Lock className="w-5 h-5 mr-2" />

                                  {adRequested ? "Requested!" : "Request"}
                                </Button>
                              }
                            />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Request Template</p>
                          </TooltipContent>
                        </Tooltip>
                      )}
                    </div>
                    <div
                      className={
                        "flex gap-2 items-center flex-wrap justify-end"
                      }
                    >
                      {!IsPublic && adData && (
                        <ManuallySaveAdToBoard
                          open={saveOpen}
                          onOpenChange={setSaveOpen}
                          data={adData}
                          variant={"secondary"}
                        />
                      )}

                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            onClick={() => {
                              copyToClipboard(
                                `${window.location.origin}/share/inspiration/${adData.id}`
                              );
                              showToastNotification("success", {
                                message: "Copied!",
                              });
                            }}
                            variant={"secondary"}
                            size={"icon"}
                          >
                            <Link className="w-5 h-5" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Share a public link</p>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    className={"flex flex-col flex-1"}
                    onClick={() => setDetailsShow(true)}
                  ></div>
                  <div className="text-white flex justify-between">
                    {adData.brandId && (
                      <AdHeader
                        brandName={adData.brandName}
                        brandId={adData.brandId}
                        Created={adData.earliestView}
                        avatar={adData.brandImage}
                        adRunningDays={
                          adData.isActiveSinceLastScrape
                            ? adData.adRunningDays
                            : adData.inactiveTime
                        }
                        status={
                          adData.isActiveSinceLastScrape ? "active" : "inactive"
                        }
                      />
                    )}
                    <div></div>
                  </div>
                </div>
              </>
            }
            FeatureLocked={
              <div
                className={`rounded-md p-3 absolute top-0 left-0 w-full h-full flex flex-col justify-between `}
              ></div>
            }
          />
        </div>
        <AccessControl
          feature={CreativeOSFeatures.AD_INSPIRATION_NAV_BUTTON}
          FeatureUnlocked={<></>}
          FeatureLocked={IsShared ? undefined : <BlurredOverlay />}
        />
      </div>
    );
  }
);

type templateProps = {
  Ad: AirTableAdRecord;
  IsPublic?: boolean;
  buttonVersion?: boolean;
};

export const GetAdTemplate = (props: templateProps) => {
  const [signupPromptOpen, setSignupPromptOpen] = useState<boolean>(false);

  const { capture } = useCapturePostHogEvent();

  const utils = trpc.useUtils();

  const { isLoading, refetch } = trpc.getFigmaCopyPasteCode.useQuery(
    {
      id: props.Ad.CreativeID.toString(),
      type: "record",
    },
    {
      enabled: false, // isRequested,
    }
  );
  const { mutateAsync: captureAdTemplatesDownload, isPending } =
    trpc.captureAdTemplatesDownload.useMutation();

  async function CaptureCanvaDownload() {
    try {
      await captureAdTemplatesDownload({ templateId: props.Ad["Creative ID"] });
      capture("USER_AD_TEMPLATE_CANVA_COPIED", {
        templateId: props.Ad["Creative ID"],
      });
      utils.getTimeSavedCounter.invalidate();
    } catch (error) {
      console.error(error);
    }
  }

  const handleCopyClick = async () => {
    if (props.IsPublic) {
      setSignupPromptOpen(true);
      return;
    }

    // Attempt to copy immediately after fetching
    const success = await copyToClipboard(refetch);
    if (success) {
      capture("USER_AD_TEMPLATE_FIGMA_COPIED", {
        templateId: props.Ad["Creative ID"],
      });
      utils.getTimeSavedCounter.invalidate();
      showToastNotification("success", {
        message: "Successfully copied template. CMD + V in Figma",
      });
    }
  };

  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };

  return (
    <>
      {signupPromptOpen && (
        <SignupPrompt
          open={signupPromptOpen}
          onOpenChange={() => setSignupPromptOpen(false)}
        />
      )}
      {props.buttonVersion ? (
        <div className="flex gap-3 items-center flex-wrap">
          <Button
            variant={"secondary"}
            className="w-full"
            onClick={handleCopyClick}
          >
            {isLoading ? (
              <div>
                <DotsLoader />
              </div>
            ) : (
              <div className="flex gap-2">
                <span>Copy to Figma</span> <FigmaLogo height="20" />
              </div>
            )}
          </Button>
          {props.Ad["Canva Template LInk"] && (
            <a
              href={props.IsPublic ? "" : props.Ad["Canva Template LInk"]}
              target={"_blank"}
              onClick={async (e) => {
                e.preventDefault();

                await CaptureCanvaDownload();
                if (!props.IsPublic && props.Ad["Canva Template LInk"]) {
                  if (isSafari()) {
                    window.location.href = props.Ad["Canva Template LInk"];
                  } else {
                    window.open(props.Ad["Canva Template LInk"], "_blank");
                  }
                } else {
                  setSignupPromptOpen(true);
                  return;
                }
              }}
              className="w-full"
            >
              <Button variant={"secondary"} className="w-full">
                {isPending ? (
                  <div>
                    <DotsLoader />
                  </div>
                ) : (
                  <div className="flex gap-2">
                    <span>Edit in Canva</span>
                    <CanvaLogo height="20" width="20" />
                  </div>
                )}
              </Button>
            </a>
          )}
        </div>
      ) : (
        <div className={" text-nowrap"}>
          <div className={"flex items-center gap-3"}>
            <div className={"flex gap-2 items-center flex-wrap"}>
              {props.Ad["Canva Template LInk"] && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <a
                      href={
                        props.IsPublic ? "" : props.Ad["Canva Template LInk"]
                      }
                      onClick={async (e) => {
                        e.preventDefault();
                        await CaptureCanvaDownload();

                        if (
                          !props.IsPublic &&
                          props.Ad["Canva Template LInk"]
                        ) {
                          if (isSafari()) {
                            window.location.href =
                              props.Ad["Canva Template LInk"];
                          } else {
                            window.open(
                              props.Ad["Canva Template LInk"],
                              "_blank"
                            );
                          }
                        } else {
                          setSignupPromptOpen(true);
                          return;
                        }
                      }}
                      target={"_blank"}
                    >
                      {isPending ? (
                        <>
                          <DotsLoader />
                        </>
                      ) : (
                        <>
                          <CanvaLogo />
                        </>
                      )}
                    </a>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Export to Canva</p>
                  </TooltipContent>
                </Tooltip>
              )}
              <Tooltip>
                <TooltipTrigger asChild>
                  {isLoading ? (
                    <>
                      <DotsLoader />
                    </>
                  ) : (
                    <span
                      className="w-9 h-9 rounded-full bg-black flex items-center justify-center cursor-pointer"
                      onClick={handleCopyClick}
                    >
                      <FigmaLogo />
                    </span>
                  )}
                </TooltipTrigger>
                <TooltipContent>
                  <p>Export to Figma</p>
                </TooltipContent>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export const GetInspirationTemplate = (props: templateProps) => {
  const [signupPromptOpen, setSignupPromptOpen] = useState<boolean>(false);

  const { capture } = useCapturePostHogEvent();

  const { isLoading, refetch } = trpc.getFigmaCopyPasteCode.useQuery(
    {
      id: props.Ad.CreativeID.toString(),
      type: "record",
    },
    {
      enabled: false, // isRequested,
    }
  );
  const { mutateAsync: captureAdTemplatesDownload, isPending } =
    trpc.captureAdTemplatesDownload.useMutation();

  async function CaptureCanvaDownload() {
    try {
      await captureAdTemplatesDownload({ templateId: props.Ad["Creative ID"] });
      capture("USER_AD_TEMPLATE_CANVA_COPIED", {
        templateId: props.Ad["Creative ID"],
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleCopyClick = async () => {
    if (props.IsPublic) {
      setSignupPromptOpen(true);
      return;
    }

    // Attempt to copy immediately after fetching
    const success = await copyToClipboard(refetch);
    if (success) {
      capture("USER_AD_TEMPLATE_FIGMA_COPIED", {
        templateId: props.Ad["Creative ID"],
      });
      showToastNotification("success", {
        message: "Successfully copied template. CMD + V in Figma",
      });
    }
  };

  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };

  return (
    <>
      {signupPromptOpen && (
        <SignupPrompt
          open={signupPromptOpen}
          onOpenChange={() => setSignupPromptOpen(false)}
        />
      )}
      <Stack className="flex gap-3">
        <Button
          className="w-full"
          onClick={handleCopyClick}
          loading={isLoading}
        >
          <div className="flex gap-2 items-center">
            <Text weight={"bold"} size="sm">
              Copy to Figma
            </Text>
            <BrandFigma className="w-5 h-5 min-w-5" />
          </div>
        </Button>
        {props.Ad["Canva Template LInk"] && (
          <a
            href={props.IsPublic ? "" : props.Ad["Canva Template LInk"]}
            target={"_blank"}
            onClick={async (e) => {
              e.preventDefault();

              await CaptureCanvaDownload();
              if (!props.IsPublic && props.Ad["Canva Template LInk"]) {
                if (isSafari()) {
                  window.location.href = props.Ad["Canva Template LInk"];
                } else {
                  window.open(props.Ad["Canva Template LInk"], "_blank");
                }
              } else {
                setSignupPromptOpen(true);
                return;
              }
            }}
            className="w-full"
          >
            <Button className="w-full" loading={isPending}>
              <div className="flex gap-2 items-center">
                <Text weight={"bold"} size="sm">
                  Edit in Canva
                </Text>
                <CanvaLogo2 height="20" width="20" />
              </div>
            </Button>
          </a>
        )}
      </Stack>
    </>
  );
};

// This is component is used for the saving airtable ad to the master saved ads

export function ManuallySaveTemplateToBoard({
  open,
  onOpenChange,
  data,
  variant,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: AdDataProps;
  variant: "outline" | "secondary";
}) {
  return (
    <AddToBoard
      itemType="template"
      templateData={{
        Ad: {
          CreativeID: data.Ad.CreativeID,
          atID: data.Ad.atID,
          AdDbId: data.Ad.AdDbId,
        },
      }}
      initialSavedState={data.Ad.Liked}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}

// This is component is used for the saving ads in db to the master saved ads

export function ManuallySaveAdToBoard({
  open,
  onOpenChange,
  data,
  variant,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: Ad;
  variant: "outline" | "secondary";
}) {
  return (
    <AddToBoard
      itemType="ad"
      adData={{ id: data.id }}
      initialSavedState={data.isSaved}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}

function SignupPrompt({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogPortal>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Want the copy-and-paste template for this ad?
            </DialogTitle>
            <DialogDescription className={"text-lg mt-4"}>
              Sign up for a free trial and get access to this ad and hundreds
              more
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
              <Button type="submit">Get Free Access</Button>
            </a>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
