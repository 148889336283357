import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import { Button, buttonVariants } from "@/components/shadcn/button.tsx";
import { useCopyGrader } from "@/hooks/useCopyGrader";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import showToastNotification from "@/hooks/useShowToast";
import useUserPermissions from "@/hooks/useUserPermissions";
import { cn } from "@/lib/utils";
import { trpc } from "@/utils/trpc";
import {
  CalendarUp,
  CheckCircleSolid,
  Copy,
  ExternalLink,
  InfoCircle,
  Layout,
  Like,
  Lock,
  Presentation,
} from "@mynaui/icons-react";
import { useState } from "react";
import { EmailFeedContent } from "../../../../src/server/services/emails/definitions";
import AccessControl from "./AccessControl";
import { Stack, Text } from "./custom-components";
import { Badge } from "./shadcn/badge";
import { Progress } from "./shadcn/progress";
import { Tooltip, TooltipContent, TooltipTrigger } from "./shadcn/tooltip";
import { GetLandingAdTemplate } from "./templates/LandingAdCard";

interface Props {
  data: EmailFeedContent;
  mediaComponent?: JSX.Element;
}

export default function EmailInspirationDetailsBody({
  data,
  mediaComponent,
}: Props) {
  const [, copyToClipboard] = useCopyToClipboard();
  const [adRequested, setAdRequested] = useState<boolean>(
    data.hasUserRequestedTemplate ?? false
  );
  const { mutateAsync: requestEmailTemplate, isPending } =
    trpc.requestEmail.useMutation();
  const [copiedText, setCopiedText] = useState<boolean>(false);
  const copyText = `${data?.subject ?? ""}\n${data?.bodyText ?? ""}`;
  const { userLimits } = useUserPermissions();
  const {
    readabilityScore,
    persuasionAnalysisResults,
    getReadingLevel,
    getPersuasionLevel,
  } = useCopyGrader({
    copyText,
  });
  const utils = trpc.useUtils();

  return (
    <div className="px-3 flex flex-col lg:px-8 py-3 pt-0 lg:py-8 lg:pt-4 gap-8 lg:gap-6">
      <div className="px-0 gap-2  flex flex-col">
        <div className="flex gap-2 items-center">
          <Text weight={"semibold"} size={"lg"} className="leading-7 w-fit">
            {data.brandName} Email
          </Text>
          {data?.template && (
            <Badge variant={"secondary"} className="px-2.5 py-1 flex gap-1">
              <Layout className="w-4 h-4 min-w-4" /> {"Template"}
            </Badge>
          )}
        </div>
        <div
          className={
            "flex items-center gap-2 lg:gap-4 text-thememutedforeground text-sm"
          }
        >
          <div className={"flex items-center gap-1"}>
            <span>
              <Like className={"w-4.5"} />
            </span>
            <span>{data?.requestCount || 0} Requests</span>
          </div>
          <div className={"flex items-center gap-1"}>
            <span>
              <Presentation className={"w-4.5"} />
            </span>
            <span>Inspiration</span>
          </div>
        </div>
      </div>
      {mediaComponent}
      {data?.template ? (
        <GetLandingAdTemplate
          buttonVersion
          type={"email"}
          Ad={{
            Screenshot: data.template["Email Screenshot"],
            ID: data.template["Email ID"].toString(),
            created: data.template.Created,
            type: "email",
            category: data.template.Category,
            imageUrl: data.template.imageUrl,
            isSaved: data.template.isSaved,
          }}
        />
      ) : (
        <Stack className="gap-3">
          <AccessControl
            feature={CreativeOSFeatures.EMAIL_INSPIRATION_REQUEST}
            FeatureUnlocked={
              <Button
                onClick={() => {
                  setAdRequested(true);
                  requestEmailTemplate({
                    emailId: data.id,
                  }).then(() => {
                    showToastNotification("success", {
                      message: `${Math.max((userLimits?.request.email ?? 0) - 1, 0)} Template Requests left!`,
                      description: `You have ${Math.max((userLimits?.request.email ?? 0) - 1, 0)} template requests left for the month.`,
                    });
                    utils.permissions.invalidate();
                    utils.getUserLimits.invalidate();
                    utils.getEmailById.invalidate({ id: data.id });
                  });
                }}
                variant={data?.isSaved ? "default" : "destructivePurple"}
                loading={isPending}
                className="w-full"
              >
                {adRequested ? (
                  <CheckCircleSolid className="w-5 h-5 mr-2" />
                ) : null}

                {adRequested ? "Requested" : "Request Template"}
              </Button>
            }
            FeatureLocked={
              <Button
                className={`w-full opacity-50`}
                variant={data?.isSaved ? "default" : "destructivePurple"}
                loading={isPending}
              >
                <Lock className="w-5 h-5 mr-2" />

                {adRequested ? "Requested" : "Request Template"}
              </Button>
            }
          />
          {data.homepage && (
            <a
              href={data.homepage}
              target={"_blank"}
              className={cn(buttonVariants({ variant: "secondary" }))}
            >
              View Landing Page
            </a>
          )}
        </Stack>
      )}
      <Stack className={"flex flex-col gap-4 text-sm"}>
        <Text weight={"medium"} size="sm" className="leading-5">
          Ad Details
        </Text>
        {data.homepage && (
          <div className={"flex justify-between items-center gap-4"}>
            <Text
              weight={"thin"}
              size="sm"
              className="leading-5 text-thememutedforeground w-24 min-w-24"
            >
              Landing Page
            </Text>
            <a
              href={data.homepage ?? ""}
              target={"_blank"}
              className="flex items-center max-w-full overflow-hidden text-themedestructive"
            >
              <span
                className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap`}
              >
                {data.homepage}
              </span>
              <ExternalLink className="w-4 h-4 min-w-4" />
            </a>
          </div>
        )}
        {data.date && !isNaN(new Date(data.date).getTime()) && (
          <div className={"flex justify-between items-center gap-4"}>
            <Text
              weight={"thin"}
              size="sm"
              className="leading-5 text-thememutedforeground w-24 min-w-24"
            >
              Published
            </Text>
            <div className="flex items-center gap-1 max-w-full">
              <CalendarUp className="w-4 h-4 min-w-4" />

              <span
                className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap`}
              >
                {new Date(data.date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </div>
          </div>
        )}
      </Stack>
      <div>
        {data && (
          <Stack className={"flex flex-col gap-4 text-sm"}>
            <Text weight={"medium"} size="sm" className="leading-5">
              Email Copy
            </Text>
            <div className={"flex justify-between items-center gap-4"}>
              <Text
                weight={"thin"}
                size="sm"
                className="leading-5 text-thememutedforeground w-46 min-w-46 flex items-center gap-2"
              >
                Readability
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className={"text-thememutedforeground"}>
                      <InfoCircle size={"1.2rem"} />
                    </span>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-sm">
                    <p>
                      This copy reads at a {getReadingLevel(readabilityScore)}{" "}
                      Level
                    </p>
                  </TooltipContent>
                </Tooltip>
              </Text>
              <div className={"flex items-center gap-1.5 w-full"}>
                <Progress value={readabilityScore} className={`h-2 `} />

                <span className="w-fit text-sm font-light text-thememutedforeground">
                  {readabilityScore}
                </span>
              </div>
            </div>
            <div className={"flex justify-between items-center gap-4"}>
              <Text
                weight={"thin"}
                size="sm"
                className="leading-5 text-thememutedforeground w-46 min-w-46 flex items-center gap-2"
              >
                Persuasion
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className={"text-thememutedforeground"}>
                      <InfoCircle size={"1.2rem"} />
                    </span>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-sm">
                    <p>
                      {
                        getPersuasionLevel(persuasionAnalysisResults.score)
                          .description
                      }
                    </p>
                  </TooltipContent>
                </Tooltip>
              </Text>
              <div className={"flex items-center gap-1.5 w-full"}>
                <Progress
                  value={persuasionAnalysisResults.score}
                  className={`h-2 `}
                />

                <span className="w-fit text-sm font-light text-thememutedforeground">
                  {persuasionAnalysisResults.score}
                </span>
              </div>
            </div>
            <div>
              <div className="">
                <Button
                  onClick={() => {
                    copyToClipboard(copyText).then(() => {
                      setCopiedText(true);
                      showToastNotification("success", {
                        message: "Copied!",
                      });
                      setTimeout(() => {
                        setCopiedText(false);
                      }, 2000);
                    });
                  }}
                  variant="outline"
                  size="sm"
                  className="flex gap-1.5 items-center"
                >
                  <span className="text-sm font-light">
                    {copiedText ? "Copied!" : "Copy Email Text"}
                  </span>
                  <Copy strokeWidth={1} size="1.2rem" />
                </Button>
              </div>
            </div>
          </Stack>
        )}
      </div>
    </div>
  );
}
