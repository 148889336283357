import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";

import { BoardDialog } from "@/components/BoardDialog";
import { UserProfileDialog } from "@/components/UserProfileDialog";
import { CustomLightBulb } from "@/components/shadcn/customLightBulb";

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
  useSidebar,
} from "@/components/shadcn/sidebar";
import { ManageAccountV2 } from "@/components/templates/AppHeader";
import useUserPermissions from "@/hooks/useUserPermissions";
import { featureFlagKeys } from "@/utils/data/featureFlags";
import { trpc } from "@/utils/trpc";
import {
  CogThree,
  GridOne,
  Layout,
  Lightning,
  MessageReply,
  PanelRightOpen,
  Pencil,
  QuestionCircle,
  Star,
  Store,
  Swatches,
} from "@mynaui/icons-react";
import { useUserback } from "@userback/react";
import { Moon, Sun } from "lucide-react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import * as React from "react";
import { useCallback } from "react";
import { useLocalStorage } from "usehooks-ts";
import { SideBarItem } from "./SideBarItem";

const data = {
  navMain: [
    {
      title: "Templates",
      featureName: CreativeOSFeatures.AD_TEMPLATE_NAV_BUTTON,
      urls: [
        "/feeds/templates",
        "/feeds/templates/emails",
        "/feeds/templates/landing-pages",
      ],
      icon: Layout,
    },
    {
      title: "Inspiration",
      urls: [
        "/feeds/inspiration",
        "/feeds/inspiration/emails",
        "/feeds/inspiration/landing-pages",
      ],
      icon: CustomLightBulb,
    },
    {
      title: "Brands",
      urls: ["/feeds/brands"],
      icon: Store,
      featureName: CreativeOSFeatures.BRANDS_PAGE_NAV_BUTTON,
    },
    {
      title: "Creative Toolkit",
      urls: ["/feeds/creative-toolkit"],
      icon: Swatches,
      isNew: true
    },
    {
      title: "Editor",
      icon: Pencil,
      isComingSoon: true,
    },
  ],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { open, setOpen } = useSidebar();
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    React.useState<boolean>(false);
  const [dark_mode, set_dark_mode] = useLocalStorage<boolean>(
    "darkMode",
    false
  );
  const [profileModalOpen, setProfileModalOpen] =
    React.useState<boolean>(false);

  const { data: allBoardsData } = trpc.getBoards.useQuery(
    { postType: "record" },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const userbackFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.userback);
  const { currentPlanConfigs } = useUserPermissions();
  const darkModeFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.darkMode);
  const { open: openUserback } = useUserback();

  const handleOpenUserback = useCallback(() => {
    openUserback();
  }, [openUserback]);
  React.useEffect(() => {
    document.body.classList.toggle("dark", dark_mode);
  }, [dark_mode]);
  return (
    <>
      {
        <UserProfileDialog
          open={profileModalOpen}
          onOpenChange={() => setProfileModalOpen(!profileModalOpen)}
        />
      }
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
        />
      )}

      <Sidebar collapsible="icon" {...props}>
        <SidebarHeader
          className={`flex ${open ? "flex-row" : "flex-col-reverse"} items-center justify-between`}
        >
          {open ? <ManageAccountV2 showName={open} /> : null}
          <PanelRightOpen
            className="text-thememutedforeground  w-4 h-4"
            onClick={() => setOpen(!open)}
          />
        </SidebarHeader>
        <SidebarContent>
          {!open ? (
            <hr className={"border-themeinput dark:border-darkthemeinput"} />
          ) : null}
          <SideBarItem items={data.navMain} />
          <hr className={"border-themeinput dark:border-darkthemeinput"} />
          <SideBarItem
            sectionTitle="Collections"
            items={[
              ...(currentPlanConfigs.length > 0
                ? [
                    {
                      title: "My Collections",
                      icon: (allBoardsData ?? []).length > 0 ? undefined : Star,
                      image:
                        (allBoardsData ?? []).length > 0 ? (
                          <img
                            alt={"star"}
                            src={"/images/filled-star.png"}
                            className={"h-4 w-4 min-w-4"}
                          />
                        ) : undefined,
                      items: [
                        {
                          title: "All Saved",
                          urls: [
                            "/feeds/saved/ads",
                            "/feeds/saved/emails",
                            "/feeds/saved/ad-landers",
                          ],
                        },
                        ...((allBoardsData ?? [])
                          .slice(
                            0,
                            currentPlanConfigs[0].myCollectionLimit ==
                              "unlimited"
                              ? (allBoardsData ?? []).length
                              : currentPlanConfigs[0].myCollectionLimit
                          )
                          .map((item: { id: string; name: string }) => ({
                            title: item.name,
                            urls: [
                              `/feeds/boards/${item.id}/ads`,
                              `/feeds/boards/${item.id}/emails`,
                              `/feeds/boards/${item.id}/landing-pages`,
                            ],
                            dontCheckUrl: true,
                          })) || []),
                        ...((allBoardsData ?? [])
                          .slice(
                            currentPlanConfigs[0].myCollectionLimit ==
                              "unlimited"
                              ? (allBoardsData ?? []).length
                              : currentPlanConfigs[0].myCollectionLimit,
                            (allBoardsData ?? []).length
                          )
                          .map((item: { id: string; name: string }) => ({
                            title: item.name,
                            urls: [
                              `/feeds/boards/${item.id}/ads`,
                              `/feeds/boards/${item.id}/emails`,
                              `/feeds/boards/${item.id}/landing-pages`,
                            ],
                            dontCheckUrl: true,
                            featureName: CreativeOSFeatures.MY_COLLECTIONS, // THIS FEATURE WILL NOT BE ADDED TO ANY OF THE PLANS SO COLLECTIONS WITH THIS FEATURE NAME WILL ALWAYS BE LOCKED
                          })) || []),
                        {
                          title: "View All",
                          urls: ["/feeds/boards"],
                          dontCheckPathName: true,
                        },
                        {
                          title: "Requested Templates",
                          urls: ["/feeds/requested"],
                        },
                        {
                          title: "New Collection",
                          onClick: () => setCreateBoardDialogOpen(true),
                          featureName: CreativeOSFeatures.ADD_NEW_COLLECTION,
                        },
                      ],
                    },
                  ]
                : []),
              {
                title: "Live Collections",
                icon: Lightning,
                showPingCircles: true,
                items: [
                  {
                    title: "Evergreen Ads",
                    urls: ["/feeds/evergreen"],
                    featureName:
                      CreativeOSFeatures.LIVE_COLLECTIONS_EVERGREEN_NAV_BUTTON,
                  },
                  {
                    title: "Testing Lab",
                    urls: ["/feeds/testing-labs"],
                    featureName:
                      CreativeOSFeatures.LIVE_COLLECTIONS_TESTING_LAB_NAV_BUTTON,
                  },
                  {
                    title: "Hidden Gems",
                    urls: ["/feeds/hidden-gems"],
                    featureName:
                      CreativeOSFeatures.LIVE_COLLECTIONS_HIDDEN_GEMS_NAV_BUTTON,
                  },
                  {
                    title: "View All",
                    urls: ["/feeds/live-trends"],
                  },
                ],
              },
              {
                title: "Expert Collections",
                icon: GridOne,
                urls: ["/feeds/collections/creativeos"],
                featureName: CreativeOSFeatures.CREATIVEOS_COLLECTIONS,
              },
            ]}
          />
        </SidebarContent>
        <SidebarFooter className="p-0">
          <SideBarItem
            items={[
              {
                icon: CogThree,
                title: "Settings",
                onClick: () => setProfileModalOpen(true),
              },
              {
                icon: QuestionCircle,
                title: "Help Center",
                urls: [
                  "https://intercom.help/creativeos/en/collections/11153232-how-to-use-creative-os",
                ],
              },
              ...(userbackFlagEnabled
                ? [
                    {
                      icon: MessageReply,
                      title: "Feedback",
                      onClick: handleOpenUserback,
                    },
                  ]
                : []),
              ...(darkModeFlagEnabled
                ? [
                    {
                      icon: dark_mode ? Sun : Moon,
                      title: dark_mode ? "Light Mode" : "Dark Mode",
                      onClick: () => {
                        set_dark_mode(!dark_mode);
                      },
                    },
                  ]
                : []),
            ]}
          />
        </SidebarFooter>
        <SidebarRail />
      </Sidebar>
    </>
  );
}
