import { featureFlagKeys } from '@/utils/data/featureFlags';
import { useUserback } from '@userback/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { createContext, useContext, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

type UserbackContextType = {
  isVisible: boolean;
  toggleUserback: (visible: boolean) => void;
};

const UserbackContext = createContext<UserbackContextType | undefined>(
  undefined,
);

export const CustomUserbackProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { show, hide } = useUserback();

  const userbackFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.userback);
  const [userback_visible, set_userback_visible] = useLocalStorage<boolean>("userbackVisible", false);

  useEffect(() => {
    if (!userbackFlagEnabled && typeof userbackFlagEnabled !== 'undefined') {
      set_userback_visible(false);
    }
  }, [userbackFlagEnabled]);

  useEffect(() => {
    // Ensure Userback loads correctly
    const checkUserback = setInterval(() => {
      if (window.Userback) {
        clearInterval(checkUserback);

        if (userback_visible) {
          show();
          window.Userback?.show();
        } else {
          hide();
          window.Userback?.hide();
        }
      }
    }, 100);

    return () => clearInterval(checkUserback);
  }, [show, hide, userback_visible]);

  const toggleUserback = (visible: boolean) => {
    set_userback_visible(visible);

    if (visible) {
      show();
      window.Userback?.show();
    } else {
      hide();
      window.Userback?.hide();
    }
  };

  return (
    <UserbackContext.Provider value={{ isVisible: userback_visible, toggleUserback }}>
      {children}
    </UserbackContext.Provider>
  );
};

export const useUserbackContext = () => {
  const context = useContext(UserbackContext);
  if (!context) {
    throw new Error(
      'useUserbackContext must be used within a UserbackProvider',
    );
  }
  return context;
};
