import useLogout from '@/hooks/useLogout';
import { Button } from './shadcn/button';

export default function LogoutButton() {
  const { logout, loggingOut } = useLogout();

  return (
    <Button onClick={logout} size={'sm'} variant={'outline'}>
      {loggingOut ? 'Logging out...' : 'Logout'}
    </Button>
  );
}
