import { trpc } from "@/App";
import FreeTrialEnding from "@/components/FreeTrialEnding";
import SearchDialog from "@/components/SearchDialog";
import { Text } from "@/components/custom-components";
import { Button } from "@/components/shadcn/button";
import useUserPermissions from "@/hooks/useUserPermissions";
import { featureFlagKeys } from "@/utils/data/featureFlags";
import { PRO_PLAN, STANDARD_PLAN } from "@/utils/data/plans";
import { Search } from "@mynaui/icons-react";
import { useRouter } from "@tanstack/react-router";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useEffect, useState } from "react";
import { LockTagSmall } from "../LockTag";

export const CreativeOsHeader = () => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const searchFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.search);

  const { permissions: permissionData, isLoading } = useUserPermissions();
  const { data: authUser } = trpc.me.useQuery(null);

  const showProButton =
    permissionData?.usersPlans &&
    !(
      permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
      permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
    );

  const router = useRouter();

  useEffect(() => {
    const unsubscribe = router.history.subscribe(() => {
      setSearchOpen(false);
    });

    return () => unsubscribe();
  }, [router]);

  return (
    <>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      {searchOpen && permissionData && (
        <SearchDialog
          open={searchOpen}
          onOpenChange={() => setSearchOpen(false)}
          permissions={permissionData}
        />
      )}
      <div
        className={`flex justify-between items-center flex-wrap gap-3 w-full bg-themebackground dark:bg-themebackgrounddark `}
      >
        <div className={"hidden lg:flex flex-1"}></div>
        {searchFlagEnabled ? (
          authUser ? (
            <Button
              variant={"secondary"}
              className="px-2 py-0 flex gap-4 items-center justify-between bg-searchBg h-8 rounded-full lg:max-w-72 w-full"
              onClick={() => setSearchOpen(true)}
            >
              <Search className="h-5 w-5 " />
              <Text weight={"medium"} size="sm">
                Discovery
              </Text>
            </Button>
          ) : (
            <div className="opacity-50 px-2 py-0 flex gap-4 items-center justify-between bg-searchBg h-8 rounded-full lg:max-w-72 w-full">
              <Search className="h-5 w-5 " />
              <Text weight={"medium"} size="sm" className="w-full">
                Discovery
              </Text>
              <LockTagSmall />
            </div>
          )
        ) : null}
        <div className={"hidden lg:flex lg:flex-1"}>
          {showProButton && !isLoading ? (
            <div className="flex gap-3 items-center justify-end w-full">
              <Text className="w-fit lg:flex hidden">
                {permissionData?.usersPlans.includes(STANDARD_PLAN.plan_id)
                  ? "Go Pro. Supercharge your creativity."
                  : ""}
              </Text>
              <Button
                onClick={() => setUpgradeDialogOpen(true)}
                variant={"destructivePurple"}
                size={"sm"}
              >
                Get Pro
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
