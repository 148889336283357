import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import AccessControl from "@/components/AccessControl";
import { Tabs, TabsList, TabsTrigger } from "@/components/shadcn/tabs";
import { useNavigate } from "@tanstack/react-router";
import { z } from "zod";
import {
  orderFilter as OrderFilterTypes,
  landerFeedOrderFilter,
} from "../../../../../../src/shared/airtable";
import { LockTagSmall } from "../LockTag";

export const FeatureTabs = ({
  tabItems,
  urlSegmentCountToCompare,
  defaultOrderValue = "Random",
}: {
  tabItems: Array<{
    name: string;
    link: string;
    // isPremiumFeature?: boolean;
    featureName?: CreativeOSFeatures;
    searchParams?: Record<string, string | undefined>;
  }>;
  urlSegmentCountToCompare?: number;
  defaultOrderValue?:
    | z.infer<typeof landerFeedOrderFilter>
    | z.infer<typeof OrderFilterTypes>;
}) => {
  const navigate = useNavigate();

  const handleClick = (
    link: string,
    searchParams?: Record<string, string | undefined>
  ) => {
    navigate({
      to: link,
      search: (d) => ({
        ...d,
        cacheBuster: Math.random(),
        orderFilter: defaultOrderValue,
        sideBarOpen: false,
        ...searchParams,
      }),
    });
  };

  return (
    <Tabs
      className="w-fit"
      value={
        urlSegmentCountToCompare
          ? location.pathname
              .split("/")
              .filter(Boolean)
              .slice(0, urlSegmentCountToCompare)
              .join("/")
          : location.pathname
      }
    >
      <TabsList className="rounded-full">
        {tabItems.map((item) =>
          item.featureName ? (
            <AccessControl
              feature={item.featureName}
              FeatureUnlocked={
                <TabsTrigger
                  key={item.name}
                  value={
                    urlSegmentCountToCompare
                      ? item.link
                          .split("/")
                          .filter(Boolean)
                          .slice(0, urlSegmentCountToCompare)
                          .join("/")
                      : item.link
                  }
                  className="rounded-full"
                >
                  <div
                    onClick={() => handleClick(item.link, item.searchParams)}
                    className="flex items-center gap-1.5 cursor-pointer"
                    key={item.name}
                  >
                    {item.name}
                  </div>
                </TabsTrigger>
              }
              FeatureLocked={
                <div
                  className="gap-1.5 cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                  key={item.name}
                >
                  {item.name}
                  <div className="opacity-50">{<LockTagSmall />}</div>
                </div>
              }
            />
          ) : (
            <TabsTrigger
              key={item.name}
              value={
                urlSegmentCountToCompare
                  ? item.link
                      .split("/")
                      .filter(Boolean)
                      .slice(0, urlSegmentCountToCompare)
                      .join("/")
                  : item.link
              }
              className="rounded-full"
            >
              <div
                onClick={() => handleClick(item.link, item.searchParams)}
                className="flex items-center gap-1.5 cursor-pointer"
                key={item.name}
              >
                {item.name}
              </div>
            </TabsTrigger>
          )
        )}
      </TabsList>
    </Tabs>
  );
};
