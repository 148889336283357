import {
  BoardCollectionDataProps,
  ExpertProductType,
  defaultUseQueryRefetchOptions,
} from "@/_shared/constants";
import {
  CreativeOSFeatures,
  CreativeOSPages,
} from "@/_shared/subscriptionPlans";
import AccessControl from "@/components/AccessControl";
import { BoardDialog } from "@/components/BoardDialog.tsx";
import { Stack, Text } from "@/components/custom-components";
import CollectionCard from "@/components/custom-components/CollectionCard/index.tsx";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { LockTagSmall } from "@/components/custom-components/LockTag/index.tsx";
import { Badge } from "@/components/shadcn/badge.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Plus, UserWaves } from "@mynaui/icons-react";
import { Link, createFileRoute } from "@tanstack/react-router";
import {  useState } from "react";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/collections/experts/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);

  const { data: experts, isLoading: expertsIsLoading } =
    trpc.getExpertsWithTemplates.useQuery({}, defaultUseQueryRefetchOptions);

  return (
    <AccessControl page={CreativeOSPages.EXPERT_COLLECTIONS}>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
        />
      )}
      <Stack className="gap-3 lg:gap-6">
        <Stack className="gap-3 lg:gap-8">
          <Stack className="gap-3 lg:gap-6">
            <div className="flex gap-2 justify-between">
              <Stack className="gap-2">
                <div className="gap-2 flex items-center justify-between">
                  <Text weight="semibold" size={"xxl"}>
                    Pro Curated. Pro Approved.
                  </Text>
                </div>
                <Text
                  weight="normal"
                  size={"lg"}
                  className="text-thememutedforeground hidden lg:flex"
                >
                  Expertly chosen collections that raise the bar.
                </Text>
              </Stack>
              <FeatureTabs
                defaultOrderValue={"Random"}
                urlSegmentCountToCompare={2}
                tabItems={[
                  {
                    name: "Curated",
                    link: "/feeds/collections/creativeos",
                    featureName: CreativeOSFeatures.CREATIVEOS_COLLECTIONS,
                  },
                  {
                    name: "Live Trends",
                    link: "/feeds/live-trends",
                    featureName: CreativeOSFeatures.LIVE_COLLECTIONS,
                  },
                ]}
              />
            </div>

            <div className={"flex justify-between gap-6 items-center"}>
              <div className="flex gap-2 items-center">
                {[
                  {
                    name: "All",
                    link: "/feeds/collections",
                    featureName: CreativeOSFeatures.ALL_COLLECTIONS,
                  },
                  {
                    name: "by CreativeOS",
                    link: "/feeds/collections/creativeos",
                    icon: (
                      <img
                        alt={""}
                        src={"/bg_gradient.jpg"}
                        className={"rounded-full shrink-0 bg-[#A259FF] w-4 h-4"}
                      />
                    ),
                    featureName: CreativeOSFeatures.CREATIVEOS_COLLECTIONS,
                  },
                  {
                    name: "Experts",
                    link: "/feeds/collections/experts",
                    icon: <UserWaves className="w-4 h-4" />,
                    selected: true,
                    featureName: CreativeOSFeatures.EXPERT_COLLECTIONS,
                  },
                ].map((item) => (
                  <AccessControl
                    feature={item.featureName}
                    FeatureUnlocked={
                      <Link
                        to={item.link}
                        search={(d) => ({
                          ...d,
                          cacheBuster: Math.random(),
                          orderFilter: "Random",
                          sideBarOpen: false,
                        })}
                        activeOptions={{
                          exact: true,
                          includeSearch: false,
                        }}
                        key={item.name}
                      >
                        <Badge
                          variant={item.selected ? "default" : "outline"}
                          className="gap-1 cursor-pointer items-center"
                        >
                          {item.icon}
                          {item.name}
                        </Badge>
                      </Link>
                    }
                    FeatureLocked={
                      <Badge
                        variant={item.selected ? "default" : "outline"}
                        className="gap-1 cursor-pointer items-center"
                      >
                        {item.icon}
                        {item.name}
                        <div className="opacity-50">
                          <LockTagSmall />
                        </div>
                      </Badge>
                    }
                  />
                ))}
              </div>

              <div className="gap-2 items-center hidden md:flex">
                <Button
                  onClick={() => setCreateBoardDialogOpen(true)}
                  size={"sm"}
                  variant={"ghost"}
                >
                  Create Your Own <Plus className="w-5 h-5" />
                </Button>
                <Text
                  weight="normal"
                  size={"base"}
                  className="text-thememutedforeground"
                >
                  {(experts?.data ?? []).length}
                  {(experts?.data ?? []).length > 1
                    ? " Collections"
                    : " Collection"}
                </Text>
              </div>
            </div>
          </Stack>
        </Stack>
        {!expertsIsLoading && experts ? <ExpertsGridView collections={experts.data} /> : null}
      </Stack>
    </AccessControl>
  );
}

export const ExpertsGridView = (props: BoardCollectionDataProps) => {
  return (
    <div className={"grid lg:grid-cols-2 gap-5 mb-10"}>
      {props.collections && props.collections.length > 0
        ? props.collections.map((item) => (
            <ExpertCollectionItem key={item.name} data={item} />
          ))
        : props.collections.length === 0 && (
            <div
              className={
                "h-full w-full lg:col-span-2 flex justify-center items-center"
              }
            >
              <p>No collections created</p>
            </div>
          )}
    </div>
  );
};

export const ExpertCollectionItem = ({ data }: { data: ExpertProductType }) => {
  return (data.adTemplates?.length ?? 0) < 1 ? (
    <></>
  ) : (
    <Link
      to={`/feeds/collections/experts/ad/$expert`}
      params={{ expert: data.name }}
    >
      <Stack className="gap-3">
        <CollectionCard
          videoUrls={[]}
          imageUrls={
            data.adTemplates?.map(
              (record) =>
                record.imageUrl ||
                record["Example Image"][0].thumbnails.large.url
            ) ?? []
          }
        />
        <div className="flex justify-between">
          <Stack className="gap-1 w-full">
            <h4 className={`truncate font-semibold text-sm leading-5`}>
              {data.name}
            </h4>

            <div className={`flex gap-1 items-center`}>
              <img
                src={data.image ?? "/bg_gradient-min.png"}
                alt={"CreativeOs"}
                className={`bg-[#BCBCBC] rounded-full w-6 h-6 object-contain`}
              />

              <Text
                weight={"normal"}
                size={"xs"}
                className={`text-thememutedforeground w-fit`}
              >
                {data.speciality} by {data.name}
              </Text>
              <Badge variant={"destructivePurple"}>Expert</Badge>
            </div>
          </Stack>
          <Text
            className="text-thememutedforeground w-fit text-nowrap"
            size={"xs"}
            weight={"normal"}
          >
            {data.adTemplates?.length ?? 0}{" "}
            {(data.adTemplates?.length ?? 0) > 1 ||
            (data.adTemplates?.length ?? 0) == 0
              ? " Items"
              : " Item"}
          </Text>
        </div>
      </Stack>
    </Link>
  );
};
