import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import { FigmaLogo } from "@/assets/template-logo.tsx";
import AccessControl from "@/components/AccessControl";
import EmailInspirationDetails from "@/components/EmailInspirationDetails";
import FreeAccessPrompt from "@/components/FreeAccessPrompt.tsx";
import LanderAdInspirationDetails from "@/components/LanderInspirationDetails";
import TemplateDetails from "@/components/TemplateDetails";
import { copyToClipboard } from "@/components/copyToClipboard.tsx";
import { Text } from "@/components/custom-components";
import { AdHeader } from "@/components/custom-components/AdHeader";
import { AddToBoard } from "@/components/custom-components/AddToBoard";
import { DotsLoader } from "@/components/custom-components/Loader";
import { PingCirclesGreen } from "@/components/custom-components/PingCircles";
import { TemplatesFooter } from "@/components/custom-components/TemplatesHeader";
import WatermarkImage from "@/components/custom-components/Watermark";
import { Badge } from "@/components/shadcn/badge";
import { Button } from "@/components/shadcn/button";
import { GenericCardProps } from "@/components/templates/LandingAdGridView";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { useCapturePostHogEvent } from "@/hooks/useCapturePosthogEvent.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import showToastNotification from "@/hooks/useShowToast.tsx";
import useUserPermissions from "@/hooks/useUserPermissions";
import { cn } from "@/lib/utils.ts";
import { trpc } from "@/utils/trpc.ts";
import { BrandFigma, Download, Layout, Link, Lock } from "@mynaui/icons-react";
import { VariantProps, cva } from "class-variance-authority";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useState,
} from "react";
import { EmailFeedContent } from "../../../../../../src/server/services/emails/definitions";
import { AirtableLandingPageRecord } from "../../../../../../src/shared";
import { BlurredOverlay, SharedOverlay } from "../AdCard";

const adCardStyles = cva("w-full relative rounded bg-[#F5F5F5]");

export interface LandingAdDataProps {
  Screenshot: string;
  ID: string;
  id?: number;
  created: string;
  category?: string;
  isSaved?: boolean;
  imageUrl?: string | null;
  brandId?: string | null;
  brandName?: string | null;
  brandImage?: string | null;
}

export type LandingFeedProps = {
  landerId: number;
  landerUrl: string;
  brandName: string | null;
  brandImage: string | null;
  desktopScreenshot: string | null;
  mobileScreenshot: string | null;
  brandId: string | null;
  isSaved?: boolean;
  hasUserRequestedTemplate?: boolean;
  createdAt?: string;
  requestCount?: string | number;
  template?: AirtableLandingPageRecord | null;
};

export interface AdCardProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof adCardStyles> {
  adData: LandingAdDataProps;
  type: "email" | "landing-page";
  showTemplateButton?: boolean;
  showRecentButton?: boolean;
  IsLocked?: boolean;
  IsShared?: boolean;
  openDetailsOnInit?: boolean;
  showWaterMark?: boolean;
}

export interface LandingAdCardProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof adCardStyles> {
  adData: LandingFeedProps;
  IsLocked?: boolean;
  IsShared?: boolean;
  openDetailsOnInit?: boolean;
  IsPublic?: boolean;
  IsRequested?: boolean;
  hasRequestLimitReached?: boolean;
}
export interface EmailAdCardProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof adCardStyles> {
  adData: EmailFeedContent;
  IsLocked?: boolean;
  IsShared?: boolean;
  openDetailsOnInit?: boolean;
  IsPublic?: boolean;
  IsRequested?: boolean;
}

export const LandingAdCard = forwardRef<HTMLDivElement, AdCardProps>(
  (
    {
      className,
      showTemplateButton = false,
      showRecentButton = false,
      openDetailsOnInit = false,
      IsShared = false,
      showWaterMark = false,
      adData,
      type,
      ...props
    },
    ref
  ) => {
    const [, copyToClipboard] = useCopyToClipboard();
    const [detailsShow, setDetailsShow] = useState<boolean>(openDetailsOnInit);
    const [showTemplate, setShowTemplate] = useState<boolean>(true);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);

    return (
      <div
        onMouseEnter={
          showTemplateButton || showRecentButton || IsShared
            ? () => {
                setShowTemplate(false);
              }
            : () => {}
        }
        onMouseLeave={
          showTemplateButton || showRecentButton || IsShared
            ? () => {
                setShowTemplate(true);
              }
            : () => {}
        }
        ref={ref}
        className={cn(
          adCardStyles({
            className: `${className} cursor-pointer`,
          })
        )}
        {...props}
      >
        {detailsShow && adData && (
          <TemplateDetails
            landingData={adData}
            type={type}
            open={detailsShow}
            onCloseDrawer={() => setDetailsShow(false)}
          />
        )}

        <div className={"rounded overflow-hidden"}>
          <ScrollArea className={"w-full h-[35.4375rem]"}>
            {showWaterMark ? (
              <WatermarkImage imageUrl={adData.imageUrl ?? adData.Screenshot} />
            ) : (
              <img
                alt={
                  type === "email"
                    ? "screenshot of a email template"
                    : "screenshot of a landing page template"
                }
                loading={"lazy"}
                className={"w-full rounded-md"}
                src={adData.imageUrl ?? adData.Screenshot}
              />
            )}
          </ScrollArea>
        </div>

        <AccessControl
          feature={
            type == "email"
              ? CreativeOSFeatures.EMAIL_TEMPLATE_NAV_BUTTON
              : CreativeOSFeatures.LANDER_TEMPLATE_NAV_BUTTON
          }
          FeatureLocked={IsShared ? <SharedOverlay /> : <BlurredOverlay />}
          FeatureUnlocked={
            <>
              {IsShared ? (
                <SharedOverlay />
              ) : (showTemplateButton || showRecentButton) && showTemplate ? (
                <div
                  className={
                    "absolute inset-x-0 inset-y-0 rounded justify-between flex flex-col items-stretch transition-opacity duration-300"
                  }
                >
                  <div className="flex justify-between p-3 flex-wrap">
                    {showTemplateButton && (
                      <Badge variant={"destructivePurple"} className="gap-1">
                        <Layout className="w-4 h-4" />
                        Template
                      </Badge>
                    )}
                    {showRecentButton && (
                      <Badge
                        variant={"destructivePurple"}
                        className="gap-1 bg-[#0F172A66]"
                      >
                        <PingCirclesGreen />
                        Just Added
                      </Badge>
                    )}
                  </div>
                </div>
              ) : null}
              <div
                className={
                  "absolute bg-opacity-40 inset-x-0 inset-y-0 bg-slate-900 rounded justify-between opacity-0 hover:opacity-100 flex flex-col items-stretch transition-opacity duration-300 cursor-pointer"
                }
              >
                <AccessControl
                  FeatureUnlocked={
                    <>
                      <div className="flex justify-between items-center p-3">
                        <GetLandingAdTemplate type={type} Ad={adData} />

                        <div className="flex gap-2 flex-wrap justify-end">
                          {adData && (
                            <ManuallySaveLandingAdToBoard
                              open={saveOpen}
                              onOpenChange={setSaveOpen}
                              data={adData}
                              variant={"secondary"}
                              type={type}
                            />
                          )}
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <a
                                href={adData.imageUrl ?? adData.Screenshot}
                                download={"image.png"}
                              >
                                <Button variant={"secondary"} size={"icon"}>
                                  <Download className="w-5 h-5" />
                                </Button>
                              </a>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Download as image</p>
                            </TooltipContent>
                          </Tooltip>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                onClick={() => {
                                  copyToClipboard(
                                    `${window.location.origin}/share/templates/${type === "email" ? "emails" : type === "landing-page" ? "landing-pages" : ""}/${adData.ID}`
                                  );
                                  showToastNotification("success", {
                                    message: "Copied!",
                                  });
                                }}
                                size={"icon"}
                                variant={"secondary"}
                              >
                                <Link className="w-5 h-5" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Share a public link</p>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      </div>
                      <div
                        className="h-full w-full bg-slate-900 bg-opacity-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDetailsShow(true);
                        }}
                      />
                    </>
                  }
                  FeatureLocked={
                    <>
                      <div className="flex justify-end items-center p-3">
                        <div className="flex gap-2 flex-wrap justify-end">
                          {/**this access control is added so share users who have not logged in cannot save */}
                          <AccessControl
                            feature={CreativeOSFeatures.SAVE}
                            FeatureUnlocked={
                              <>
                                {adData && (
                                  <ManuallySaveLandingAdToBoard
                                    open={saveOpen}
                                    onOpenChange={setSaveOpen}
                                    data={adData}
                                    variant={"secondary"}
                                    type={type}
                                  />
                                )}
                              </>
                            }
                            FeatureLocked={<></>}
                          />

                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                onClick={() => {
                                  copyToClipboard(
                                    `${window.location.origin}/share/templates/${type === "email" ? "emails" : type === "landing-page" ? "landing-pages" : ""}/${adData.ID}`
                                  );
                                  showToastNotification("success", {
                                    message: "Copied!",
                                  });
                                }}
                                size={"icon"}
                                variant={"secondary"}
                              >
                                <Link className="w-5 h-5" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Share a public link</p>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="h-full w-full bg-slate-900 bg-opacity-0" />
                    </>
                  }
                />
                <div className="flex justify-between p-3">
                  <TemplatesFooter
                    footerTitle={
                      location.pathname.includes(
                        "feeds/templates/landing-pages"
                      )
                        ? `${adData.brandName ? `${adData.brandName} ` : ""}Lander Template`
                        : `${adData.brandName ? `${adData.brandName} ` : ""}Email Template`
                    }
                    brandImage={adData.brandImage}
                    footerType=""
                    titleColor="popover"
                  />
                </div>
              </div>
            </>
          }
        />
      </div>
    );
  }
);

type templateProps = {
  Ad: GenericCardProps;
  IsPublic?: boolean;
  type: "email" | "landing-page";
  buttonVersion?: boolean;
};

export const GetLandingAdTemplate = (props: templateProps) => {
  const [accessPromptDialogOpen, setAccessPromptDialogOpen] =
    useState<boolean>(false);

  const { capture } = useCapturePostHogEvent();

  const utils = trpc.useUtils();

  const { isLoading, refetch } = trpc.getFigmaCopyPasteCode.useQuery(
    {
      id: props.Ad.ID.toString(),
      type: props.type,
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleCopyClick = async () => {
    if (props.IsPublic) {
      setAccessPromptDialogOpen(true);
      return;
    }

    // Attempt to copy immediately after fetching
    const success = await copyToClipboard(refetch);
    if (success) {
      utils.getTimeSavedCounter.invalidate();
      if (props.type === "email") {
        capture("USER_EMAIL_TEMPLATE_FIGMA_COPIED", {
          templateId: props.Ad.ID,
        });
      } else if (props.type === "landing-page") {
        capture("USER_LANDING_PAGE_TEMPLATE_FIGMA_COPIED", {
          templateId: props.Ad.ID,
        });
      }
      showToastNotification("success", {
        message: "Successfully copied template. CMD + V in Figma",
      });
    }
  };

  return (
    <>
      {accessPromptDialogOpen && (
        <FreeAccessPrompt
          open={accessPromptDialogOpen}
          onOpenChange={() => setAccessPromptDialogOpen(false)}
        />
      )}

      {props.buttonVersion ? (
        <Button
          className="w-full"
          onClick={handleCopyClick}
          loading={isLoading}
        >
          <div className="flex gap-2 items-center">
            <Text weight={"bold"} size="sm">
              Copy to Figma
            </Text>
            <BrandFigma className="w-5 h-5 min-w-5" />
          </div>
        </Button>
      ) : (
        <div className={"text-nowrap"}>
          <div className={"flex items-center gap-3"}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className={"flex gap-3 items-center"}>
                  {isLoading ? (
                    <div>
                      <DotsLoader />
                    </div>
                  ) : (
                    <div
                      className="w-9 h-9 rounded-full bg-black flex items-center justify-center cursor-pointer"
                      onClick={handleCopyClick}
                    >
                      <FigmaLogo />
                    </div>
                  )}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Export to Figma</p>
              </TooltipContent>
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
};

export const LandingFeedAdCard = forwardRef<HTMLDivElement, LandingAdCardProps>(
  (
    {
      adData,
      IsPublic,
      IsRequested,
      IsLocked = false,
      IsShared = false,
      openDetailsOnInit = false,
      ...props
    },
    ref
  ) => {
    const [detailsShow, setDetailsShow] = useState<boolean>(openDetailsOnInit);
    const [adRequested, setAdRequested] = useState<boolean>(false);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);
    const { userLimits } = useUserPermissions();
    const { mutateAsync: requestAdTemplate } =
      trpc.requestLanderTemplate.useMutation();
    const utils = trpc.useUtils();

    const [, copyToClipboard] = useCopyToClipboard();

    return (
      <div
        ref={ref}
        className={`h-[41.25rem] cursor-pointer relative rounded-md bg-[#F5F5F5] break-inside-avoid-column
        ${IsLocked && "filter blur-md pointer-events-none"}`}
        {...props}
      >
        {detailsShow && (
          <LanderAdInspirationDetails
            open={detailsShow}
            onOpenChange={() => setDetailsShow(false)}
            data={adData}
            IsPublic={false}
          />
        )}
        <div className="h-[41.25rem] relative w-full">
          {adData.desktopScreenshot && (
            <img
              alt={"screenshot of a landing page"}
              loading={"lazy"}
              className={
                "h-[41.25rem] w-full rounded-md hidden lg:flex object-cover object-top"
              }
              src={adData.desktopScreenshot}
            />
          )}
          {adData.mobileScreenshot && (
            <img
              alt={"screenshot of a landing page"}
              loading={"lazy"}
              className={
                "h-[41.25rem] w-full rounded-md flex lg:hidden object-cover object-top"
              }
              src={adData.mobileScreenshot}
            />
          )}

          <AccessControl
            feature={CreativeOSFeatures.LANDER_INSPIRATION_NAV_BUTTON}
            FeatureUnlocked={
              <>
                {/* Hover overlay */}
                <div
                  className={`rounded-md p-3 absolute top-0 left-0 w-full h-[41.25rem] flex flex-col justify-between bg-slate-900 bg-opacity-50 opacity-0 transition-opacity duration-300 hover:opacity-100`}
                >
                  <div className={"flex justify-between gap-5"}>
                    <div>
                      {!IsRequested && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <AccessControl
                              feature={
                                CreativeOSFeatures.LANDER_INSPIRATION_REQUEST
                              }
                              FeatureUnlocked={
                                <Button
                                  onClick={() => {
                                    setAdRequested(true);
                                    requestAdTemplate({
                                      landerID: adData.landerId,
                                    }).then(() => {
                                      showToastNotification("success", {
                                        message: `${Math.max((userLimits?.request.lander ?? 0) - 1, 0)} Template Requests left!`,
                                        description: `You have ${Math.max((userLimits?.request.lander ?? 0) - 1, 0)} template requests left for the month.`,
                                      });
                                      utils.permissions.invalidate();
                                      utils.getUserLimits.invalidate();

                                      setTimeout(() => {
                                        setAdRequested(false);
                                      }, 5000);
                                    });
                                  }}
                                  variant={"outline"}
                                >
                                  {adRequested ? "Requested!" : "Request"}
                                </Button>
                              }
                              FeatureLocked={
                                <Button
                                  variant={"outline"}
                                  className={`opacity-50`}
                                >
                                  <Lock className="w-5 h-5 mr-2" />

                                  {adRequested ? "Requested!" : "Request"}
                                </Button>
                              }
                            />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Request Template</p>
                          </TooltipContent>
                        </Tooltip>
                      )}
                    </div>
                    <div className={"flex gap-2 items-center flex-wrap"}>
                      {!IsPublic && adData && (
                        <ManuallySaveFeedAdToBoard
                          open={saveOpen}
                          onOpenChange={setSaveOpen}
                          data={adData}
                          variant={"secondary"}
                        />
                      )}

                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            onClick={() => {
                              copyToClipboard(
                                `${window.location.origin}/share/inspiration/landing-pages/${adData.landerId}`
                              );
                              showToastNotification("success", {
                                message: "Copied!",
                              });
                            }}
                            variant={"secondary"}
                            size={"icon"}
                          >
                            <Link />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Share a public link</p>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    className={"flex flex-col flex-1"}
                    onClick={() => setDetailsShow(true)}
                  ></div>
                  <div className="text-white flex justify-between">
                    {adData.brandId && (
                      <AdHeader
                        brandName={adData.brandName}
                        brandId={adData.brandId}
                        Created={undefined}
                        avatar={adData.brandImage}
                        adRunningDays={undefined}
                        status={undefined}
                        secondaryComponent={
                          <span className={"text-xs text-themeinput w-full"}>
                            Published{" "}
                            {adData.createdAt &&
                              new Date(adData.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                          </span>
                        }
                      />
                    )}
                  </div>
                </div>
              </>
            }
            FeatureLocked={
              <div
                className={`rounded-md p-3 absolute top-0 left-0 w-full h-full flex flex-col justify-between `}
              ></div>
            }
          />
        </div>
        <AccessControl
          feature={CreativeOSFeatures.LANDER_INSPIRATION_NAV_BUTTON}
          FeatureUnlocked={<></>}
          FeatureLocked={IsShared ? undefined : <BlurredOverlay />}
        />
      </div>
    );
  }
);
export const EmailFeedAdCard = forwardRef<HTMLDivElement, EmailAdCardProps>(
  (
    {
      adData,
      IsLocked = false,
      IsRequested,
      openDetailsOnInit = false,
      IsShared = false,
      IsPublic = false,
      ...props
    },
    ref
  ) => {
    const [, copyToClipboard] = useCopyToClipboard();
    const [adRequested, setAdRequested] = useState<boolean>(false);
    const utils = trpc.useUtils();

    const { mutateAsync: requestEmailTemplate } =
      trpc.requestEmail.useMutation();
    const [detailsShow, setDetailsShow] = useState<boolean>(openDetailsOnInit);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);
    const { userLimits } = useUserPermissions();
    return (
      <div
        ref={ref}
        className={`h-[41.25rem] cursor-pointer relative rounded-md bg-[#F5F5F5] break-inside-avoid-column
        ${IsLocked && "filter blur-md pointer-events-none"}`}
        {...props}
      >
        {detailsShow && (
          <EmailInspirationDetails
            open={detailsShow}
            onOpenChange={() => setDetailsShow(false)}
            data={adData}
            IsPublic={false}
          />
        )}

        <div className="h-[41.25rem] relative w-full overflow-hidden rounded-md">
          {adData.imageUrl && (
            <img
              alt={"screenshot of an email feed"}
              loading={"lazy"}
              className={
                "h-[41.25rem] w-full object-cover object-top scale-[200%] origin-top"
              }
              src={adData.imageUrl}
            />
          )}
          <AccessControl
            feature={CreativeOSFeatures.EMAIL_INSPIRATION_NAV_BUTTON}
            FeatureUnlocked={
              <>
                {/* Hover overlay */}

                <div
                  className={`rounded-md p-3 absolute top-0 left-0 w-full h-[41.25rem] flex flex-col justify-between bg-slate-900 bg-opacity-50 opacity-0 transition-opacity duration-300 hover:opacity-100`}
                >
                  <div className={"flex justify-between gap-5"}>
                    <div>
                      {!IsRequested && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <AccessControl
                              feature={
                                CreativeOSFeatures.EMAIL_INSPIRATION_REQUEST
                              }
                              FeatureUnlocked={
                                <Button
                                  onClick={() => {
                                    setAdRequested(true);
                                    requestEmailTemplate({
                                      emailId: adData.id,
                                    }).then(() => {
                                      showToastNotification("success", {
                                        message: `${Math.max((userLimits?.request.email ?? 0) - 1, 0)} Template Requests left!`,
                                        description: `You have ${Math.max((userLimits?.request.email ?? 0) - 1, 0)} template requests left for the month.`,
                                      });
                                      utils.permissions.invalidate();
                                      utils.getUserLimits.invalidate();

                                      setTimeout(() => {
                                        setAdRequested(false);
                                      }, 5000);
                                    });
                                  }}
                                  variant={"outline"}
                                >
                                  {adRequested ? "Requested!" : "Request"}
                                </Button>
                              }
                              FeatureLocked={
                                <Button
                                  variant={"outline"}
                                  className={`opacity-50`}
                                >
                                  <Lock className="w-5 h-5 mr-2" />

                                  {adRequested ? "Requested!" : "Request"}
                                </Button>
                              }
                            />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Request Template</p>
                          </TooltipContent>
                        </Tooltip>
                      )}
                    </div>
                    <div className={"flex gap-2 items-center flex-wrap"}>
                      {!IsPublic && adData && (
                        <ManuallySaveFeedEmailToBoard
                          open={saveOpen}
                          onOpenChange={setSaveOpen}
                          data={adData}
                          variant={"secondary"}
                        />
                      )}

                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            onClick={() => {
                              copyToClipboard(
                                `${window.location.origin}/share/inspiration/emails/${adData.id}`
                              );
                              showToastNotification("success", {
                                message: "Copied!",
                              });
                            }}
                            variant={"secondary"}
                            size={"icon"}
                          >
                            <Link />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Share a public link</p>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    className={"flex flex-col flex-1"}
                    onClick={() => setDetailsShow(true)}
                  ></div>
                  <div className="text-white flex justify-between">
                    {adData.brandId && (
                      <AdHeader
                        brandName={adData.brandName}
                        brandId={adData.brandId}
                        Created={undefined}
                        avatar={adData.brandImage}
                        adRunningDays={undefined}
                        status={undefined}
                        secondaryComponent={
                          <span className={"text-xs text-themeinput w-full"}>
                            Published{" "}
                            {adData.date &&
                              new Date(adData.date).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                          </span>
                        }
                      />
                    )}
                  </div>
                </div>
              </>
            }
            FeatureLocked={
              <div
                className={`rounded-md p-3 absolute top-0 left-0 w-full h-full flex flex-col justify-between `}
              ></div>
            }
          />
        </div>
        <AccessControl
          feature={CreativeOSFeatures.EMAIL_INSPIRATION_NAV_BUTTON}
          FeatureUnlocked={<></>}
          FeatureLocked={IsShared ? undefined : <BlurredOverlay />}
        />
      </div>
    );
  }
);

export function ManuallySaveFeedEmailToBoard({
  open,
  onOpenChange,
  data,
  variant,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: EmailFeedContent;
  variant: "outline" | "secondary";
}) {
  return (
    <AddToBoard
      itemType="email-ad"
      adData={{ id: data.id }}
      initialSavedState={data.isSaved || false}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}
export function ManuallySaveFeedAdToBoard({
  open,
  onOpenChange,
  data,
  variant,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: LandingFeedProps;
  variant: "outline" | "secondary";
}) {
  return (
    <AddToBoard
      itemType="lander"
      landerData={{ landerId: data.landerId }}
      initialSavedState={data.isSaved || false}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}

export function ManuallySaveLandingAdToBoard({
  open,
  onOpenChange,
  data,
  variant,
  type,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: LandingAdDataProps;
  variant: "outline" | "secondary";
  type: "email" | "landing-page";
}) {
  return (
    <AddToBoard
      itemType={type == "email" ? "email-template" : "landing-page-template"}
      emailLanderTemplateData={data}
      initialSavedState={data.isSaved || false}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}
