import {
  CreativeOSFeatures,
  CreativeOSPages,
} from "@/_shared/subscriptionPlans";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import useUserPermissions from "@/hooks/useUserPermissions";
import { useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { Stack } from "./custom-components";
import { Button } from "./shadcn/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogPortal,
  DialogTitle,
} from "./shadcn/dialog";

interface AccessControlProps {
  page?: CreativeOSPages;
  feature?: CreativeOSFeatures;
  children?: React.ReactNode;
  FeatureUnlocked?: JSX.Element;
  FeatureLocked?: JSX.Element;
}

const AccessControl: React.FC<AccessControlProps> = ({
  page,
  feature,
  children,
  FeatureLocked,
  FeatureUnlocked,
}) => {
  // ✅ Always call hooks at the top before any conditional returns
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);
  const [signupPromptOpen, setSignupPromptOpen] = useState<boolean>(false);
  const [_wos_user] = useLocalStorage<
    | {
        object: "user";
        email: string;
        id: string;
      }
    | undefined
  >("_wos_user", undefined);
  const {
    permissions,
    isLoading,
    error,
    userHasAccessToPage,
    userHasAccessToFeature,
  } = useUserPermissions();

  // ✅ Prevent early return that skips hooks
  let content;

  if (error || !_wos_user || !permissions) {
    content = page ? (
      <Stack className="flex flex-col items-center justify-center h-[85svh] gap-5">
        <span className="text-red-500">
          Failed to load permissions. Please try again.
        </span>
      </Stack>
    ) : (
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (_wos_user && permissions) {
            setUpgradeDialogOpen(true);
          } else {
            setSignupPromptOpen(true);
          }
        }}
        className="!cursor-pointer"
      >
        {FeatureLocked}
      </div>
    );
  } else if (page) {
    content =
      !userHasAccessToPage(page) && !isLoading ? (
        <Stack className="flex flex-col items-center justify-center h-[85svh] gap-5">
          <span>You do not have access to this page</span>
          <Button
            variant="destructivePurple"
            onClick={() => setUpgradeDialogOpen(true)}
          >
            Upgrade
          </Button>
        </Stack>
      ) : (
        children
      );
  } else if (feature) {
    content = !userHasAccessToFeature(feature) ? (
      FeatureLocked ? (
        <div
          onClick={() => setUpgradeDialogOpen(true)}
          className="!cursor-pointer"
        >
          {FeatureLocked}
        </div>
      ) : null
    ) : FeatureUnlocked ? (
      FeatureUnlocked
    ) : null;
  } else {
    content = children ?? FeatureUnlocked ?? null;
  }

  return (
    <>
      {signupPromptOpen && (
        <SignupPrompt
          open={signupPromptOpen}
          onOpenChange={() => setSignupPromptOpen(false)}
        />
      )}
      {upgradeDialogOpen && permissions && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissions}
        />
      )}
      {content}
    </>
  );
};

export default AccessControl;

function SignupPrompt({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogPortal>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Unlock Full Access to CreativeOS
            </DialogTitle>
            <DialogDescription className={"text-lg mt-4"}>
              Sign up for CreativeOS to access this and our full library of Ad,
              Email, and Landing Page Templates, and so much more.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
              <Button type="submit">Start Free Trial</Button>
            </a>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
