import { useDowngradeUserPlan } from '@/hooks/useDowngradeUserPlan';
import { handleDowngrade } from '@/lib/gtag';
import {
  DOWNGRADE_PLANS,
  FREE_PLAN,
  IGNITE_DOWNGRADE_COUPON,
  PRO_DOWNGRADE_COUPON,
  PRO_PLAN,
  SPARK_PLAN,
  STANDARD_PLAN,
  SUBSCRIPTION_STATUSES,
  plans,
} from '@/utils/data/plans';
import { CheckCircle, DotsCircleSolid, XCircle } from '@mynaui/icons-react';
import { useState } from 'react';
import { toast } from 'sonner';
import { UpgradeSuccessModal } from './CheckoutSubscriptionPlan';
import { PermissionData } from './SearchDialog';
import { Loader } from './custom-components/Loader';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from './shadcn/alert-dialog';
import { Badge } from './shadcn/badge';
import { Button } from './shadcn/button';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from './shadcn/carousel';
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from './shadcn/dialog';

type CURRENT_PLAN = 'standard' | 'spark' | 'pro' | 'free';

const FREE_PLAN_ID = FREE_PLAN.plan_id as DOWNGRADE_PLANS;
const SPARK_PLAN_ID = SPARK_PLAN.plan_id as DOWNGRADE_PLANS;
const SPARK_NO_TRIAL_PLAN_ID = SPARK_PLAN.no_trial_plan_id as DOWNGRADE_PLANS;

const DowngradePlan = ({
  permissions,
  subscriptionStatus,
  currentPlan,
}: {
  permissions: PermissionData;
  subscriptionStatus: SUBSCRIPTION_STATUSES;
  currentPlan?: CURRENT_PLAN;
}) => {
  const [period] = useState<'monthly' | 'annually'>('monthly');
  const [newPlan, setNewPlan] = useState<string | null>(null);

  const userHasNoPlan = subscriptionStatus == 'hasNoPlan';

  const {
    downgradeUserPlan,
    discountedUpgrade,
    downgradeIsPending,
    discountedUpgradeIsPending,
    isDowngradeSuccess,
  } = useDowngradeUserPlan();

  function downgradePlan(selectedPlan: DOWNGRADE_PLANS) {
    if (selectedPlan) {
      downgradeUserPlan({
        planId: selectedPlan,
        userId: permissions?.ID,
      });

      // find the plan details
      const plan = plans.find((item) => item.plan_id === selectedPlan);

      if (plan) {
        handleDowngrade(
          `${new Date()}`,
          parseInt(plan.price.replace('$', ''), 10) ?? 0,
          [
            {
              item_id: selectedPlan,
              item_name: plan?.name,
              price: parseInt(plan.price.replace('$', ''), 10) ?? 0,
              quantity: 1,
            },
          ]
        );
      }
    } else toast.error('No plan ID was passed');
  }

  function discountUpgrade(selectedPlan: string, couponCode: string) {
    if (selectedPlan) {
      discountedUpgrade({
        coupons: [couponCode],
      });
    } else toast.error('No plan ID was passed');
  }

  return (
    <DialogContent
      className={`${isDowngradeSuccess ? 'md:max-w-xl' : 'w-full md:max-w-[85vw]'}`}
    >
      {isDowngradeSuccess && newPlan ? (
        <UpgradeSuccessModal newPlanName={newPlan} type={'downgrade'} />
      ) : (
        <>
          <DialogHeader>
            <DialogTitle className="text-center text-xl md:text-2xl font-semibold">
              Don’t lose your inspiration, we’ll give it to you for FREE ❤️
            </DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          {downgradeIsPending || discountedUpgradeIsPending ? (
            <div className="flex justify-center items-center w-full py-6 h-14">
              <Loader />
            </div>
          ) : (
            <div className="space-y-3">
              <div>
                <h4 className="font-semibold">Available Plans</h4>
              </div>
              <div className="hidden lg:grid lg:grid-cols-4 gap-2.5">
                {FREE_PLAN && (
                  <FreePlanCard
                    currentPlan={currentPlan}
                    setNewPlan={setNewPlan}
                    userHasNoPlan={userHasNoPlan}
                    downgradePlan={downgradePlan}
                  />
                )}
                {SPARK_PLAN && (
                  <SparkPlanCard
                    period={period}
                    setNewPlan={setNewPlan}
                    currentPlan={currentPlan}
                    subscriptionStatus={subscriptionStatus}
                    downgradePlan={downgradePlan}
                  />
                )}
                {STANDARD_PLAN && (
                  <StandardPlanCard
                    period={period}
                    setNewPlan={setNewPlan}
                    currentPlan={currentPlan}
                    discountUpgrade={discountUpgrade}
                  />
                )}
                {PRO_PLAN && (
                  <ProPlanCard
                    period={period}
                    setNewPlan={setNewPlan}
                    currentPlan={currentPlan}
                    discountUpgrade={discountUpgrade}
                  />
                )}
              </div>
              <div className='flex justify-center'>
              <Carousel className="block lg:hidden md:mx-5 md:max-w-full max-w-xs ">
                
                <CarouselContent>
                  {FREE_PLAN && (
                    <CarouselItem className="">
                      <FreePlanCard
                        currentPlan={currentPlan}
                        setNewPlan={setNewPlan}
                        userHasNoPlan={userHasNoPlan}
                        downgradePlan={downgradePlan}
                      />
                    </CarouselItem>
                  )}
                  {SPARK_PLAN && (
                    <CarouselItem className="md:basis-1/2 lg:basis-1/3">
                      <SparkPlanCard
                        period={period}
                        setNewPlan={setNewPlan}
                        currentPlan={currentPlan}
                        subscriptionStatus={subscriptionStatus}
                        downgradePlan={downgradePlan}
                      />
                    </CarouselItem>
                  )}
                  {STANDARD_PLAN && (
                    <CarouselItem className="md:basis-1/2 lg:basis-1/3">
                      <StandardPlanCard
                        period={period}
                        setNewPlan={setNewPlan}
                        currentPlan={currentPlan}
                        discountUpgrade={discountUpgrade}
                      />
                    </CarouselItem>
                  )}
                  {PRO_PLAN && (
                    <CarouselItem className="md:basis-1/2 lg:basis-1/3">
                      <ProPlanCard
                        period={period}
                        setNewPlan={setNewPlan}
                        currentPlan={currentPlan}
                        discountUpgrade={discountUpgrade}
                      />
                    </CarouselItem>
                  )}
                </CarouselContent>
                <CarouselPrevious className='ml-5' />
                <CarouselNext className='mr-5' />
              </Carousel>
              </div>
            </div>
          )}
        </>
      )}
    </DialogContent>
  );
};

export default DowngradePlan;

const FreePlanCard = ({
  currentPlan,
  setNewPlan,
  userHasNoPlan,
  downgradePlan,
}: {
  currentPlan?: CURRENT_PLAN;
  setNewPlan: (plan: string) => void;
  userHasNoPlan: boolean;
  downgradePlan: (plan_id: DOWNGRADE_PLANS) => void;
}) => {
  const [confirmShow, setConfirmShow] = useState<boolean>(false);

  return (
    <div className={'space-y-3 flex flex-col justify-between h-full'}>
      {confirmShow && (
        <ConfirmDowngrade
          open={confirmShow}
          onOpenChange={() => setConfirmShow(false)}
          newPlan="Free Plan"
          confirmHandler={() => {
            setNewPlan('Free Plan');
            downgradePlan(FREE_PLAN_ID);
          }}
        />
      )}
      <div className={'space-y-3 p-4 border-2 rounded-lg h-full'}>
        <div>
          <div className={'flex gap-2 items-center'}>
            <h3 className={'font-medium'}>{FREE_PLAN.name}</h3>
            {currentPlan === 'free' ? (
              <Badge variant={'secondary'}>Current Plan</Badge>
            ) : null}
          </div>
          <p className={'mt-1'}>
            <span className={'text-2xl font-semibold'}>{FREE_PLAN.price}</span>
          </p>
          <p className={'text-sm font-light text-thememutedforeground'}></p>
        </div>

        <div>
          <p className={'text-sm font-light text-thememutedforeground'}>
            {FREE_PLAN.description}
          </p>
          <ul className={'space-y-3 mt-2'}>
            {FREE_PLAN.features.map((feature, index) => (
              <li
                key={index}
                className="flex gap-1 justify-start items-center text-thememutedforeground"
              >
                <span>
                  <CheckCircle className="w-4 h-4" />
                </span>
                <span className="text-sm font-normal leading-5 ">
                  {feature}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Button
        onClick={() => setConfirmShow(true)}
        variant={'secondary'}
        disabled={userHasNoPlan}
        className="w-full mt-3"
      >
        Downgrade to Free
      </Button>
    </div>
  );
};

const SparkPlanCard = ({
  currentPlan,
  setNewPlan,
  downgradePlan,
  period,
  subscriptionStatus,
}: {
  currentPlan?: CURRENT_PLAN;
  setNewPlan: (plan: string) => void;
  period: 'monthly' | 'annually';
  subscriptionStatus: SUBSCRIPTION_STATUSES;
  downgradePlan: (plan_id: DOWNGRADE_PLANS) => void;
}) => {
  const [confirmShow, setConfirmShow] = useState<boolean>(false);

  return (
    <div className={'space-y-3 flex flex-col justify-between h-full'}>
      {confirmShow && (
        <ConfirmDowngrade
          open={confirmShow}
          onOpenChange={() => setConfirmShow(false)}
          newPlan="Free Plan"
          confirmHandler={() => {
            setNewPlan('Spark Plan');
            downgradePlan(
              subscriptionStatus === 'hasNoPlan'
                ? SPARK_NO_TRIAL_PLAN_ID
                : SPARK_PLAN_ID
            );
          }}
        />
      )}
      <div className={'space-y-3 p-4 border-2 rounded-lg h-full'}>
        <div>
          <div className={'flex gap-2 items-center'}>
            <h3 className={'font-medium'}>{SPARK_PLAN.name}</h3>
            {currentPlan === 'spark' ? (
              <Badge variant={'secondary'}>Current Plan</Badge>
            ) : null}
          </div>
          <p className={'mt-1'}>
            <span className={'text-2xl font-semibold'}>
              {period === 'monthly'
                ? SPARK_PLAN.price
                : SPARK_PLAN.annual_monthly_price}
            </span>{' '}
            / month, billed {period}
          </p>
        </div>

        <div>
          <p className={'text-sm font-light text-thememutedforeground'}>
            {SPARK_PLAN.description}
          </p>
          <ul className={'space-y-3 mt-2'}>
            {SPARK_PLAN.features.map((feature, index) => (
              <li
                key={index}
                className="flex gap-1 justify-start items-center text-thememutedforeground"
              >
                <span>
                  <CheckCircle className="w-4 h-4" />
                </span>
                <span className="text-sm font-normal leading-5 ">
                  {feature}
                </span>
              </li>
            ))}
            {SPARK_PLAN.nonFeatures.map((feature, index) => (
              <li
                key={index}
                className="flex gap-1 justify-start items-center text-thememutedforeground"
              >
                <span>
                  <XCircle className="w-4 h-4 text-red-600" />
                </span>
                <span className="text-sm font-normal leading-5 ">
                  {feature}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Button
        onClick={() => setConfirmShow(true)}
        className="w-full mt-3"
        variant={currentPlan === 'spark' ? 'outline' : 'secondary'}
      >
        {currentPlan === 'spark' ? 'Keep My Plan' : 'Downgrade to Spark'}
      </Button>
    </div>
  );
};

const StandardPlanCard = ({
  currentPlan,
  setNewPlan,
  discountUpgrade,
  period,
}: {
  currentPlan?: CURRENT_PLAN;
  setNewPlan: (plan: string) => void;
  period: 'monthly' | 'annually';
  discountUpgrade: (selectedPlan: string, couponCode: string) => void;
}) => {
  const [confirmShow, setConfirmShow] = useState<boolean>(false);

  return (
    <div className={'space-y-3 flex flex-col justify-between h-full'}>
      {confirmShow && (
        <ConfirmDowngrade
          open={confirmShow}
          onOpenChange={() => setConfirmShow(false)}
          newPlan="Free Plan"
          confirmHandler={() => {
            setNewPlan('Ignite Plan');
            discountUpgrade(
              period === 'monthly'
                ? STANDARD_PLAN.plan_id
                : STANDARD_PLAN.yearly_plan_id,
              IGNITE_DOWNGRADE_COUPON
            );
          }}
        />
      )}
      <div className={'space-y-3 p-4 border-2 rounded-lg h-full'}>
        <div>
          <div className={'flex gap-2 items-center'}>
            <h3 className={'font-medium'}>{STANDARD_PLAN.name}</h3>
            {currentPlan === 'standard' ? (
              <Badge variant={'secondary'}>Current Plan</Badge>
            ) : null}
          </div>
          <p className={'mt-1'}>
            <span
              className={'text-2xl font-semibold line-through text-slate-500'}
            >
              {period === 'monthly'
                ? STANDARD_PLAN.price
                : STANDARD_PLAN.annual_monthly_price}
            </span>{' '}
            <span className={'text-2xl font-semibold'}>
              {period === 'monthly'
                ? parseInt(STANDARD_PLAN.price.replace('$', ''), 10) -
                  STANDARD_PLAN.amount_saved_per_month
                : STANDARD_PLAN.annual_monthly_price}
            </span>{' '}
            / month, billed {period}
          </p>
        </div>

        <div>
          <p className={'text-sm font-light text-thememutedforeground'}>
            {STANDARD_PLAN.description}
          </p>
          <ul className={'space-y-3 mt-2'}>
            {STANDARD_PLAN.features.map((feature, index) => (
              <li
                key={index}
                className="flex gap-1 justify-start items-center text-thememutedforeground"
              >
                <span>
                  <CheckCircle className="w-4 h-4" />
                </span>
                <span className="text-sm font-normal leading-5 ">
                  {feature}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Button
        onClick={()=>setConfirmShow(true)}
        className="w-full mt-3"
        variant={currentPlan === 'standard' ? 'outline' : 'secondary'}
      >
        {currentPlan === 'standard' ? 'Keep My Plan' : 'Downgrade to Ignite'}
      </Button>
    </div>
  );
};

const ProPlanCard = ({
  currentPlan,
  setNewPlan,
  discountUpgrade,
  period,
}: {
  currentPlan?: CURRENT_PLAN;
  setNewPlan: (plan: string) => void;
  period: 'monthly' | 'annually';
  discountUpgrade: (selectedPlan: string, couponCode: string) => void;
}) => {
  const [confirmShow, setConfirmShow] = useState<boolean>(false);

  return (
    <div className={'space-y-3 flex flex-col justify-between h-full'}>
      {confirmShow && (
        <ConfirmDowngrade
          open={confirmShow}
          onOpenChange={() => setConfirmShow(false)}
          newPlan="Free Plan"
          confirmHandler={() => {
            setNewPlan('Pro Plan');
            discountUpgrade(
              period === 'monthly' ? PRO_PLAN.plan_id : PRO_PLAN.yearly_plan_id,
              PRO_DOWNGRADE_COUPON
            );
          }}
        />
      )}
      <div className={'space-y-3 p-4 border-2 rounded-lg h-full'}>
        <div>
          <div className={'flex gap-2 items-center'}>
            <h3 className={'font-medium'}>{PRO_PLAN.name}</h3>
            {currentPlan === 'pro' ? (
              <Badge variant={'secondary'}>Current Plan</Badge>
            ) : (
              <Badge variant={'secondaryDestructive'}>Upgrade & Save</Badge>
            )}
          </div>
          <p>
            <span
              className={'text-2xl font-semibold line-through text-slate-500'}
            >
              {period === 'monthly'
                ? PRO_PLAN.price
                : PRO_PLAN.annual_monthly_price}
            </span>{' '}
            <span className={'text-2xl font-semibold'}>
              {period === 'monthly'
                ? parseInt(PRO_PLAN.price.replace('$', ''), 10) -
                  PRO_PLAN.amount_saved_per_month
                : PRO_PLAN.annual_monthly_price}
            </span>{' '}
            / month, billed {period}
          </p>
        </div>

        <div>
          <p className={'text-sm font-light text-thememutedforeground'}>
            {PRO_PLAN.description}
          </p>
          <ul className={'space-y-3 mt-2'}>
            {PRO_PLAN.features.map((feature, index) => (
              <li
                key={index}
                className="flex gap-1 justify-start items-center text-thememutedforeground"
              >
                <span>
                  <CheckCircle className="w-4 h-4" />
                </span>
                <span className="text-sm font-normal leading-5 ">
                  {feature}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Button
        onClick={() => setConfirmShow(true)}
        className="w-full mt-3"
        variant={'destructivePurple'}
      >
        <span className="flex items-center gap-2">
          <span>
            {currentPlan === 'pro' ? 'Apply Discount' : 'Upgrade to Pro Now'}
          </span>{' '}
          <DotsCircleSolid size={'0.4rem'} />{' '}
          <span>Save ${PRO_PLAN.amount_saved_per_month}/mo</span>
        </span>
      </Button>
    </div>
  );
};

const ConfirmDowngrade = ({
  newPlan,
  open,
  onOpenChange,
  confirmHandler,
}: {
  newPlan: string;
  open: boolean;
  onOpenChange: () => void;
  confirmHandler: () => void;
}) => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Confirm Your Downgrade to {newPlan}
          </AlertDialogTitle>
          <AlertDialogDescription>
            You’re about to downgrade your plan. Some features from your current
            plan will no longer be available. Would you like to proceed?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={confirmHandler}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
