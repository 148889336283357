import { CreativeOSPages } from "@/_shared/subscriptionPlans";
import AccessControl from "@/components/AccessControl";
import { Stack, Text } from "@/components/custom-components";
import { Loader } from "@/components/custom-components/Loader";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loading } from "@/components/loading.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/shadcn/dialog.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/shadcn/form.tsx";
import { Input } from "@/components/shadcn/input.tsx";
import {
  BrandDataProps,
  BrandsGridView,
} from "@/components/templates/BrandsGridView";
import { trpc } from "@/utils/trpc.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute } from "@tanstack/react-router";
import { CheckCircle2 } from "lucide-react";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { z } from "zod";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/brands/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const LIMIT = 100;

  const [requestBrandOpen, setRequestBrandOpen] = useState<boolean>(false);
  const [allBrands, setAllBrands] = useState<BrandDataProps[] | undefined>(
    undefined
  );

  const [filteredBrands, setFilteredBrands] = useState<BrandDataProps[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const {
    data: brands,
    fetchNextPage,
    isLoading,
    isError,
    hasNextPage,
    isFetchingNextPage,
  } = trpc.getBrandsPaginated.useInfiniteQuery(
    {
      limit: LIMIT,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });
  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      brands &&
      allBrands &&
      allBrands.length &&
      brands.pages[brands.pages.length - 1].results.length >= LIMIT
    ) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, brands]);

  useEffect(() => {
    if (!brands) return;

    // setAllBrands(() => undefined);

    const records: BrandDataProps[] = [];
    for (const page of brands.pages) {
      records.push(...page.results);
    }
    const filteredRecords: BrandDataProps[] = records.filter(
      (item, index) =>
        index === records.findIndex((obj) => obj.brandId === item.brandId)
    );

    setAllBrands(() => filteredRecords);
    if (searchTerm)
      setFilteredBrands([
        ...filteredRecords.filter((item) =>
          item.brandName?.toLowerCase().includes(searchTerm.toLowerCase())
        ),
      ]);
  }, [brands]);

  if (isError) {
    return <ErrorDisplay />;
  }

  if (isLoading && !allBrands) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <AccessControl page={CreativeOSPages.BRANDS}>
      <Stack className="gap-3 lg:gap-6">
        {requestBrandOpen && (
          <RequestBrand
            open={requestBrandOpen}
            onOpenChange={() => setRequestBrandOpen(false)}
          />
        )}

        <Stack className="gap-3 lg:gap-6 lg:sticky lg:top-14 lg:z-10 lg:bg-themebackground dark:lg:bg-themebackgrounddark lg:py-2 flex justify-between items-center w-full">
          <div className={"flex justify-between gap-5 items-center w-full"}>
            <div>
              <Text weight="semibold" size={"xxl"}>
                Bold Marks. Bigger Impact.{" "}
                {(brands?.pages ?? []).length > 0
                  ? `(${brands?.pages[0].count ?? 0})`
                  : ""}
              </Text>
              <p
                className={
                  "text-sm lg:text-base text-thememutedforeground mt-2"
                }
              >
                Discover standout brand stories and what sparks engagement.
              </p>
            </div>
            <div className={"lg:flex hidden gap-3"}>
              <div>
                <Button onClick={() => setRequestBrandOpen(true)} size={"sm"}>
                  Request Brand
                </Button>
              </div>
              {allBrands && (
                <BrandSearchForm
                  data={allBrands}
                  setSearchResults={(filBrands, sTerm) => {
                    setSearchTerm(sTerm);
                    setFilteredBrands(filBrands);
                  }}
                />
              )}
            </div>
          </div>
          <div className={"flex lg:hidden w-full gap-3"}>
            {allBrands && (
              <BrandSearchForm
                data={allBrands}
                setSearchResults={setFilteredBrands}
              />
            )}
            <div>
              <Button onClick={() => setRequestBrandOpen(true)} size={"sm"}>
                Request Brand
              </Button>
            </div>
          </div>
        </Stack>
        <BrandsGridView
          data={
            filteredBrands.length > 0 || searchTerm
              ? filteredBrands
              : (allBrands ?? [])
          }
        />
        <div
          ref={scrollRef}
          className="flex justify-center w-full min-h-14 h-14"
        >
          {isFetchingNextPage && <Loader />}
        </div>
      </Stack>
    </AccessControl>
  );
}

export default function BrandSearchForm({
  data,
  setSearchResults,
}: {
  data: BrandDataProps[];
  setSearchResults: (
    data: BrandDataProps[],
    searchTerm: string | undefined
  ) => void;
}) {
  const searchFormSchema = z.object({
    brand: z.string({ required_error: "Brand is required" }),
  });

  type SearchFormValues = z.infer<typeof searchFormSchema>;

  const defaultValues: Partial<SearchFormValues> = {};

  const form = useForm<SearchFormValues>({
    resolver: zodResolver(searchFormSchema),
    defaultValues,
  });

  const handleSearch = (e: FormEvent) => e.preventDefault();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return setSearchResults(data, undefined);
    const results = data.filter((item) =>
      item.brandName?.toLowerCase().includes(e.target?.value.toLowerCase())
    );

    console.log(results);
    setSearchResults(results, e.target.value.toLowerCase());
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSearch}>
        <FormField
          name="search"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="relative">
                  <Input
                    type={"text"}
                    {...field}
                    onChange={handleSearchChange}
                    placeholder="Search For Brand"
                    className="lg:w-80 w-[calc(100vw-24px)]" // Padding left to make space for the icon
                  />
                </div>
              </FormControl>
            </FormItem>
          )}
        ></FormField>
      </form>
    </Form>
  );
}

function RequestBrand({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) {
  const [message, setMessage] = useState<string | null>(null);

  const { mutate: requestBrand, isPending: createIsPending } =
    trpc.requestABrand.useMutation({
      onSuccess: async () => {
        setMessage("Request submitted successfully");
      },
    });

  const formSchema = z.object({
    brandName: z.string().min(1, "Brand name is required"),
    brandDomain: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      brandName: "",
      brandDomain: "",
    },
  });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      requestBrand(values);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={open || createIsPending} onOpenChange={onOpenChange}>
      <DialogContent className={"gap-6"}>
        <DialogHeader>
          <DialogTitle>
            <Text weight={"semibold"} size={"lg"} className="leading-7">
              {"Request Brand"}
            </Text>
          </DialogTitle>
        </DialogHeader>
        {message ? (
          <div
            className={
              "flex flex-col items-center text-center gap-2 w-full h-full m-auto"
            }
          >
            <CheckCircle2 size={"3rem"} strokeWidth={"1.2"} color={"green"} />
            <p>{message}</p>
          </div>
        ) : createIsPending ? (
          <div className={"flex flex-col gap-3 w-full h-full m-auto"}>
            <Loading />
          </div>
        ) : (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className={""}>
              <div className={"flex flex-col gap-6"}>
                <FormField
                  control={form.control}
                  name="brandName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium leading-5">
                        Brand Name
                      </FormLabel>
                      <FormControl>
                        <Input
                          className="shadow-none"
                          placeholder={"Name"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="brandDomain"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Brand Domain</FormLabel>
                      <FormControl>
                        <Input
                          type={"text"}
                          className="shadow-none"
                          placeholder={"www.website.com"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className={"flex justify-end gap-2"}>
                  <Button
                    className={"w-15 px-3 h-8 text-sm"}
                    variant={"outline"}
                    type={"button"}
                    onClick={onOpenChange}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    className={"w-15 px-3 h-8 text-sm"}
                    variant={"default"}
                  >
                    {createIsPending ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
}
