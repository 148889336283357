import { defaultUseQueryRefetchOptions } from "@/_shared/constants.ts";
import {
  CreativeOSFeatures,
  CreativeOSPages,
} from "@/_shared/subscriptionPlans.ts";
import AccessControl from "@/components/AccessControl.tsx";
import {
  MasonryComponent,
  TypedResponsiveMasonry,
} from "@/components/ResponsiveMasonryWrapper.tsx";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { Loader } from "@/components/custom-components/Loader";
import NotFoundBox from "@/components/custom-components/NotFound/index.tsx";
import { TemplatesFilterPopover } from "@/components/custom-components/TemplateFilterPopover/index.tsx";
import { ErrorDisplay } from "@/components/error.tsx";
import { AdCard } from "@/components/templates/AdCard";
import useCardLayoutWithImpressions from "@/hooks/useCardLayoutWithImpressions.tsx";
import { AdFeedFilterOption } from "@/hooks/useFilterFeed.tsx";
import { showTemplateRecentButton } from "@/utils/data/feeds.ts";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { z } from "zod";
import { orderFilter as OrderFilterType } from "../../../../src/shared/airtable/index.ts";
import { trpc } from "../utils/trpc.ts";

export type SelectedTemplateFilters = {
  collectionIds: string[];
  brands?: string[];
};

type SearchParams = {
  collections?: string;
  getStarted?: string;
  sideBarOpen?: boolean;
  orderFilter?: z.infer<typeof OrderFilterType>;
  brands?: string;
  searchTerm?: string;
};

export const Route = createFileRoute("/feeds/search/templates/ads")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const collections: string | undefined =
      typeof search.collections === "string" ? search.collections : undefined;
    const searchTerm: string | undefined =
      typeof search.searchTerm === "string" ? search.searchTerm : undefined;
    const getStarted: string | undefined =
      typeof search.getStarted === "string" ? search.getStarted : undefined;
    const sideBarOpen: boolean | undefined =
      typeof search.sideBarOpen === "boolean" ? search.sideBarOpen : undefined;
    const brands: string | undefined =
      typeof search.brands === "string" ? search.brands : undefined;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      collections,
      searchTerm,
      getStarted,
      sideBarOpen,
      orderFilter,
      brands,
    };
  },
});

function All() {
  const {
    collections: queryCollections,
    searchTerm: querySearchTerm,
    orderFilter,
    brands: queryBrands,
  } = Route.useSearch();
  const navigate = useNavigate();

  const [filterOptions, setFilterOptions] = useState<AdFeedFilterOption[]>([]);

  const [selectedFilters, setSelectedFilters] =
    useState<SelectedTemplateFilters>({
      collectionIds: queryCollections ? queryCollections.split(",") : [],
      brands: queryBrands ? (queryBrands.split(",") as string[]) : undefined,
    });

  const { data: brands } = trpc.getBrands.useQuery(
    undefined,
    defaultUseQueryRefetchOptions
  );

  // Get the template collections to be passed to the filter
  const { data: templateCollections } = trpc.getAllCollections.useQuery(
    {},
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  // Update the filters after fetching the templates
  useEffect(() => {
    if (
      templateCollections &&
      templateCollections.length > 0 &&
      brands &&
      brands.length > 0
    ) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Avoid redundant updates
        return [
          {
            title: "Collection",
            counter: 0,
            optionItems: templateCollections.map((i) => ({
              label: i.Title,
              value: false,
              id: i.atID,
            })),
          },
          {
            title: "Brand",
            counter: 0,
            optionItems: brands.map((i) => ({
              label: i.brandName,
              value: false,
              id: i.brandId,
              logo: i.brandImage ?? undefined,
            })),
          },
        ];
      });
    }
  }, [templateCollections, brands]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => ({
      ...option,
      optionItems: option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === "Collection" &&
            selectedFilters.collectionIds?.includes(item.id ?? "")) ||
          (option.title === "Brand" &&
            selectedFilters.brands?.includes(item.id ?? "")),
      })),
    }));

    setFilterOptions((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(updatedOptions)) return prev;
      return updatedOptions;
    });
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: {
      collections?: string;
      brands?: string;
      searchTerm?: string;
    }) => {
      const searchParams = new URLSearchParams();

      if (params.collections)
        searchParams.set("collections", params.collections);
      if (params.brands) searchParams.set("brands", params.brands);

      navigate({
        to: "/feeds/search/templates/ads",
        replace: true,
        search: (old) => ({ ...old, ...params, orderFilter }),
      });
    },
    [navigate, orderFilter]
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedCollections: string[] = [];
      const selectedBrands: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Collection")
              selectedCollections.push(`${item.id}`);
            if (group.title === "Brand") selectedBrands.push(`${item.id}`);
          }
        });
      });

      setSelectedFilters({
        collectionIds:
          selectedCollections.length > 0 ? selectedCollections : [],
        brands: selectedBrands.length > 0 ? selectedBrands : undefined,
      });

      updateQueryString({
        collections:
          selectedCollections.length > 0
            ? selectedCollections.join(",")
            : undefined,
        brands:
          selectedBrands.length > 0 ? selectedBrands.join(",") : undefined,
        searchTerm: querySearchTerm,
      });
    },
    [updateQueryString]
  );

  useEffect(() => {
    if (!selectedFilters) return;
    const params: Record<string, string | undefined> = {};
    if (selectedFilters.collectionIds)
      params.collections = selectedFilters.collectionIds.join(",");
    if (selectedFilters.brands)
      params.brands = selectedFilters.brands.join(",");
  }, [selectedFilters]);

  const [cursor, setCursor] = useState(1);
  const [allFetchedData, setAllFetchedData] = useState<
    {
      imageUrl: string;
      Ready: boolean;
      Created: string;
      "Creative ID": string;
      "Canva Template LInk": string;
      CreativeID: number;
      atID: string;
      createdTimestamp: number;
      collections?: string[] | undefined;
      Tags?: string | null | undefined;
      Liked?: boolean | undefined;
    }[]
  >([]);

  const {
    data: filteredTemplatesByCollections,
    isLoading,
    isError,
    refetch,
  } = trpc.searchAdTemplate.useQuery(
    {
      limit: 20,
      query: querySearchTerm ?? "",
      cursor,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (inView && (filteredTemplatesByCollections?.data.length ?? 0) >= 20) {
      setCursor((prevCursor) => {
        const newCursor = prevCursor + 1;
        return newCursor;
      });
    }
  }, [
    inView,
    refetch,
    querySearchTerm,
    filteredTemplatesByCollections,
    cursor,
  ]);

  useEffect(() => {
    refetch();
  }, [cursor]);

  useEffect(() => {
    if (filteredTemplatesByCollections?.data) {
      setAllFetchedData((prevData) => [
        ...prevData,
        ...filteredTemplatesByCollections.data.map((ad) => ({
          ...ad,
          "Canva Template LInk": ad["Canva Template LInk"] ?? "",
        })),
      ]);
    }
  }, [filteredTemplatesByCollections]);

  const { squareRef, desiredCardWidth, columns, gutterWidth } =
    useCardLayoutWithImpressions();

  return (
    <AccessControl page={CreativeOSPages.SEARCH_AD_TEMPLATES}>
      <Stack className="gap-3 lg:gap-6 relative">
        <Stack className="gap-3 lg:gap-8">
          <FeatureTabs
            tabItems={[
              {
                name: "Ads",
                link: "/feeds/search/templates/ads",
                searchParams: { searchTerm: querySearchTerm },
                featureName: CreativeOSFeatures.SEARCH_AD_TEMPLATE_NAV_BUTTON,
              },
              {
                name: "Emails",
                link: "/feeds/search/templates/emails",
                searchParams: { searchTerm: querySearchTerm },

                featureName:
                  CreativeOSFeatures.SEARCH_EMAIL_TEMPLATE_NAV_BUTTON,
              },
              {
                name: "Landers",
                link: "/feeds/search/templates/landing-pages",
                searchParams: { searchTerm: querySearchTerm },

                featureName:
                  CreativeOSFeatures.SEARCH_LANDER_TEMPLATE_NAV_BUTTON,
              },
            ]}
          />
          <Stack className="gap-3 lg:gap-6">
            <div className={"flex justify-between gap-5 items-center"}>
              <Text weight="semibold" size={"xxl"} className="w-fit">
                Your Discovery Results{" "}
                {filteredTemplatesByCollections
                  ? `(${filteredTemplatesByCollections?.totalHits})`
                  : ""}
                :<span className="opacity-45">“{querySearchTerm}”</span>
              </Text>
              <div
                className={" gap-2 items-center flex-wrap justify-end hidden"}
              >
                <div className="lg:hidden">
                  <OrderFilterSelect
                    defaultFilter={orderFilter || "Random"}
                    options={["Random", "Recent", "Popular", "Oldest"]}
                  />
                </div>
              </div>
            </div>
          </Stack>
        </Stack>

        <Stack className="w-full gap-3">
          <Text weight="medium" size={"sm"} className="w-fit hidden">
            Narrow your search using filters:
          </Text>
          <div
            className={`z-10 lg:sticky lg:top-0 lg:bg-white lg:py-2 hidden ${filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-center`}
          >
            <div>
              {filterOptions && filterOptions.length > 0 && (
                <TemplatesFilterPopover
                  initialOptions={filterOptions}
                  onOptionsChange={handleOptionsChange}
                  placeholder={"Filter Ad Templates"}
                />
              )}
            </div>

            <div className={"hidden lg:flex"}>
              <OrderFilterSelect
                defaultFilter={orderFilter || "Random"}
                options={["Random", "Recent", "Popular", "Oldest"]}
              />
            </div>
          </div>
        </Stack>
        {isLoading && allFetchedData.length < 1 ? (
          <div className="flex justify-center items-center w-full h-[70vh]">
            <Loader />
          </div>
        ) : isError ? (
          <ErrorDisplay />
        ) : (
          <div>
            {allFetchedData.length === 0 ? (
              <NotFoundBox />
            ) : (
              <div ref={squareRef} className={"relative w-full lg:px-0 pb-10"}>
                {allFetchedData.length > 0 && (
                  <div>
                    <TypedResponsiveMasonry
                      columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                    >
                      <MasonryComponent gutter={gutterWidth / 16 + "rem"}>
                        {allFetchedData.map((ad) => {
                          return (
                            <AdCard
                              key={ad.atID}
                              adData={{
                                Ad: {
                                  ...ad,
                                  Tags: undefined,
                                  Liked: ad.Liked ?? false,
                                  "Canva Template LInk":
                                    ad["Canva Template LInk"] ?? "",
                                  Expert: undefined,
                                  form: "",
                                  "Example Image": [],
                                  "Template Image": [],
                                  Status: "",
                                  AdDbId: 0,
                                },
                                IsLocked: false,
                                IsPublic: false,
                                brandName: undefined,
                                shouldInvalidateCache: false,
                                DesiredWidth: desiredCardWidth,
                              }}
                              showRecentButton={showTemplateRecentButton(
                                ad.Created
                              )}
                            />
                          );
                        })}
                      </MasonryComponent>
                    </TypedResponsiveMasonry>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div
          ref={scrollRef}
          className="flex justify-center w-full min-h-14 h-14"
        >
          {isLoading && allFetchedData.length > 0 && <Loader />}
        </div>
      </Stack>
    </AccessControl>
  );
}
