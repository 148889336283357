import { useState } from 'react';
import LanderAdInspirationDetails from './LanderInspirationDetails';
import { LandingFeedProps } from './templates/LandingAdCard';

const SearchDialogLanderInspirationImage = ({
  item,
  isEqualWidth,
}: {
  item: LandingFeedProps;
  isEqualWidth?: boolean;
}) => {
  const [detailsShow, setDetailsShow] = useState<boolean>(false);

  return (
    <div className={`bg-slate-100 rounded-2xl`} key={item.landerId}>
      {detailsShow && (
        <LanderAdInspirationDetails
          open={detailsShow}
          onOpenChange={() => setDetailsShow(false)}
          data={item}
          IsPublic={false}
        />
      )}
      <img
        src={
          item.desktopScreenshot ??
          item.mobileScreenshot ??
          'https://placehold.jp/b3b3b3/ffffff/150x150.png?text=CreativeOs'
        }
        alt={`${item.brandName ?? 'CreativeOs'} Image`}
        onClick={() => setDetailsShow(true)}
        loading="eager"
        className={`${isEqualWidth ? 'w-full h-[7.25rem]' : 'min-w-[10.7rem] w-[10.7rem] h-[10.7rem]'} rounded-2xl object-cover cursor-pointer lg:hvr-grow`}
      />
    </div>
  );
};

export default SearchDialogLanderInspirationImage;
