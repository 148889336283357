import { defaultUseQueryRefetchOptions } from "@/_shared/constants.ts";
import {
  CreativeOSFeatures,
  CreativeOSPages,
} from "@/_shared/subscriptionPlans.ts";
import AccessControl from "@/components/AccessControl.tsx";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { Loader } from "@/components/custom-components/Loader";
import { LockTagSmallNoBg } from "@/components/custom-components/LockTag/index.tsx";
import TemplatesFilterPopover from "@/components/custom-components/TemplateFilterPopover/index.tsx";
import { ErrorDisplay } from "@/components/error.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { LandingAdGridView } from "@/components/templates/LandingAdGridView";
import { AdFeedFilterOption } from "@/hooks/useFilterFeed.tsx";
import useUserPermissions from "@/hooks/useUserPermissions.tsx";
import { trpc } from "@/utils/trpc.ts";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { z } from "zod";
import {
  AirTableEmailRecord,
  orderFilter as OrderFilterType,
  Tags,
} from "../../../../src/shared/airtable/index.ts";

type SearchParams = {
  Tags?: Tags;
  cacheBuster: number;
  sideBarOpen?: boolean;
  categories?: string;
  brands?: string;
  orderFilter?: z.infer<typeof OrderFilterType>;
};
export type SelectedTemplateFilters = {
  categories?: string[];
  brands?: string[];
};
export const Route = createFileRoute("/feeds/templates/emails/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const cacheBuster = (search?.cacheBuster as number) ?? Math.random();
    const sideBarOpen = search?.sideBarOpen as boolean;
    const categories = search?.categories as string | undefined;
    const brands = search?.brands as string | undefined;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      cacheBuster: cacheBuster,
      sideBarOpen,
      categories,
      brands,
      orderFilter,
    };
  },
});

function All() {
  const {
    cacheBuster,
    orderFilter,
    categories: queryCategories,
    brands: queryBrands,
  } = Route.useSearch();
  const navigate = useNavigate();
  // Get the template collections to be passed to the filter
  const { data: templateCategories } =
    trpc.getEmailTemplateCategories.useQuery();
  const [filterOptions, setFilterOptions] = useState<AdFeedFilterOption[]>([]);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedTemplateFilters>({
      categories: queryCategories ? queryCategories.split(",") : undefined,
      brands: queryBrands ? (queryBrands.split(",") as string[]) : undefined,
    });

  const { data: brands } = trpc.getBrands.useQuery(
    undefined,
    defaultUseQueryRefetchOptions
  );

  const updateQueryString = useCallback(
    (params: { categories?: string; brands?: string }) => {
      const searchParams = new URLSearchParams();

      if (params.categories) searchParams.set("categories", params.categories);
      if (params.brands) searchParams.set("brands", params.brands);

      navigate({
        to: "/feeds/templates/emails",
        replace: true,
        search: (old) => {
          return {
            ...old,
            ...params,
            orderFilter,
            cacheBuster: Math.random(),
          };
        },
      });
    },
    [navigate]
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedCategories: string[] = [];
      const selectedBrands: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Category") selectedCategories.push(item.label);
            if (group.title === "Brand") selectedBrands.push(`${item.id}`);
          }
        });
      });

      setSelectedFilters({
        categories:
          selectedCategories.length > 0 ? selectedCategories : undefined,
        brands: selectedBrands.length > 0 ? selectedBrands : undefined,
      });

      updateQueryString({
        categories:
          selectedCategories.length > 0
            ? selectedCategories.join(",")
            : undefined,
        brands:
          selectedBrands.length > 0 ? selectedBrands.join(",") : undefined,
      });
    },
    [updateQueryString]
  );

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;
    setCursor(0);

    const updatedOptions = filterOptions.map((option) => ({
      ...option,
      optionItems: option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === "Category" &&
            selectedFilters.categories?.includes(item.label)) ||
          (option.title === "Brand" &&
            selectedFilters.brands?.includes(item.id ?? "")),
      })),
    }));

    setFilterOptions((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(updatedOptions)) return prev; // Prevent unnecessary updates
      return updatedOptions;
    });
  }, [filterOptions, selectedFilters]);

  useEffect(() => {
    if (
      templateCategories &&
      templateCategories.length > 0 &&
      brands &&
      brands.length > 0
    ) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Prevent redundant updates
        return [
          {
            title: "Category",
            counter: 0,
            optionItems: templateCategories.map((i) => ({
              label: i.category,
              value: false,
            })),
          },
          {
            title: "Brand",
            counter: 0,
            optionItems: brands.map((i) => ({
              label: i.brandName,
              value: false,
              id: i.brandId,
              logo: i.brandImage ?? undefined,
            })),
          },
        ];
      });
    }
  }, [templateCategories, brands]);

  const [cursor, setCursor] = useState(0);
  const [allData, setAllData] = useState<AirTableEmailRecord[] | undefined>(
    undefined
  );
  const { currentPlanConfigs } = useUserPermissions();

  const {
    data: emailTemplates,
    fetchNextPage,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
  } = trpc.emails.useInfiniteQuery(
    {
      categories: selectedFilters.categories,
      brands: selectedFilters.brands,
      sortingOptions: orderFilter,
      cacheBuster: cacheBuster,
      limit: [
        ...currentPlanConfigs
          .map(
            (config) =>
              config.accessiblePages?.[CreativeOSPages.EMAIL_TEMPLATES]?.limit
          )
          .filter((limit) => limit !== undefined && limit !== "infinity"),
        20,
      ][0],
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      initialCursor: cursor,
    }
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      emailTemplates &&
      allData &&
      allData.length &&
      emailTemplates.pages[emailTemplates.pages.length - 1].Emails.length >= 20
    ) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, emailTemplates]);

  useEffect(() => {
    if (!emailTemplates) return;

    setAllData(() => undefined);

    const records: AirTableEmailRecord[] = [];
    for (const page of emailTemplates.pages) {
      records.push(...page.Emails);
    }
    setAllData(() =>
      records.filter(
        (item, index) =>
          index ===
          records.findIndex((obj) => obj["Email ID"] === item["Email ID"])
      )
    );
  }, [emailTemplates]);

  return (
    <AccessControl page={CreativeOSPages.EMAIL_TEMPLATES}>
      <Stack className="gap-3 lg:gap-6 relative">
        <Stack className="gap-3 lg:gap-8">
          <Stack className="gap-3 lg:gap-6">
            <div className={"flex justify-between gap-5 items-center"}>
              <div>
                <Text weight="semibold" size={"xxl"} className="w-fit">
                  Yours. For The Making.
                </Text>
                <Text
                  weight="normal"
                  size={"lg"}
                  className="text-thememutedforeground hidden lg:flex"
                >
                  Shape ideas into standout ads with endless possibilities.
                </Text>
              </div>
              <div className={"flex gap-2 items-center flex-wrap justify-end"}>
                <FeatureTabs
                  tabItems={[
                    {
                      name: "Ads",
                      link: "/feeds/templates",
                      featureName: CreativeOSFeatures.AD_TEMPLATE_NAV_BUTTON,
                    },
                    {
                      name: "Emails",
                      link: "/feeds/templates/emails",
                      featureName: CreativeOSFeatures.EMAIL_TEMPLATE_NAV_BUTTON,
                    },
                    {
                      name: "Landers",
                      link: "/feeds/templates/landing-pages",
                      featureName:
                        CreativeOSFeatures.LANDER_TEMPLATE_NAV_BUTTON,
                    },
                  ]}
                />
                <div className="lg:hidden">
                  <OrderFilterSelect
                    defaultFilter={orderFilter || "Random"}
                    options={["Random", "Recent", "Popular", "Oldest"]}
                  />
                </div>
              </div>
            </div>
          </Stack>
        </Stack>

        <div
          className={`z-10 lg:sticky lg:top-[4.3rem] lg:bg-themebackground dark:lg:bg-themebackgrounddark  lg:py-2 flex ${filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-center`}
        >
          <div>
            {filterOptions && filterOptions.length > 0 && (
              <TemplatesFilterPopover
                initialOptions={filterOptions}
                onOptionsChange={handleOptionsChange}
                placeholder={"Filter Ad Templates"}
              />
            )}
          </div>

          <div className={"hidden lg:flex"}>
            <OrderFilterSelect
              defaultFilter={orderFilter || "Random"}
              options={["Random", "Recent", "Popular"]}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-[70vh]">
            <Loader />
          </div>
        ) : isError ? (
          <ErrorDisplay />
        ) : allData && allData.length === 0 ? (
          <div className="flex flex-col justify-center items-center">
            <p className="text-center w-4/5 lg:w-1/2 mb-5">
              Looks like you've gone down a path with no templates...this is
              your fault! All we do is win...but really, maybe try a different
              configuration of filters - we got you!
            </p>
            <img src="/giphy.webp" width="480" height="270" alt="" />
          </div>
        ) : (
          <LandingAdGridView
            Cards={(allData ?? []).map((data) => ({
              created: data.Created,
              ID: data["Email ID"].toString(),
              id: data["Email ID"],
              Screenshot: data["Email Screenshot"],
              type: "email",
              category: data.Category,
              isSaved: data.isSaved,
              imageUrl: data.imageUrl,
              brandId: data.brandId,
              brandName: data.brandName,
              brandImage: data.brandImage,
            }))}
            HasAccess={
              emailTemplates && emailTemplates.pages.length > 0
                ? emailTemplates.pages[0].HasAccess
                : false
            }
            type={"email"}
          />
        )}
        {currentPlanConfigs
          .map(
            (config) =>
              config.accessiblePages?.[CreativeOSPages.EMAIL_TEMPLATES]?.limit
          )
          .includes("infinity") ? (
          <div
            ref={scrollRef}
            className="flex justify-center w-full min-h-14 h-14"
          >
            {isFetchingNextPage && <Loader />}
          </div>
        ) : emailTemplates &&
          emailTemplates.pages[emailTemplates.pages.length - 1].Emails.length >=
            [
              ...currentPlanConfigs
                .map(
                  (config) =>
                    config.accessiblePages?.[CreativeOSPages.EMAIL_TEMPLATES]
                      ?.limit
                )
                .filter((limit) => limit !== undefined && limit !== "infinity"),
              20,
            ][0] ? (
          <div className="w-full flex justify-center py-5">
            <AccessControl
              feature={CreativeOSFeatures.UPGRADE}
              FeatureLocked={
                <Button
                  variant={"destructivePurple"}
                  className="w-60 items-center gap-2 cursor-pointer opacity-50"
                >
                  <span>Show More</span> <LockTagSmallNoBg />
                </Button>
              }
            />
          </div>
        ) : null}
      </Stack>
    </AccessControl>
  );
}
