import { defaultUseQueryRefetchOptions } from "@/_shared/constants";
import { trpc } from "@/utils/trpc";
import { ArrowRight } from "@mynaui/icons-react";
import { Link } from "@tanstack/react-router";
import SearchDialogAdInspirationImage from "./SearchDialogAdInspirationImage";
import SearchDialogEmailInspirationImage from "./SearchDialogEmailInspirationImage";
import SearchDialogLanderInspirationImage from "./SearchDialogLanderInspirationImage";
import { ScrollArea, ScrollBar } from "./shadcn/scroll-area";
import { Skeleton } from "./shadcn/skeleton";

const SearchDialogInspirationTab = () => {
  // Inspirations
  const { data: randomAdInspiration, isLoading: adInspirationIsLoading } =
    trpc.filterAdFeed.useQuery({
      order: "Random",
      numOfAds: 7,
    });

  const {
    data: randomEmailInspiration,
    isLoading: emailInspirationsIsLoading,
  } = trpc.emailFeed.useQuery(
    {
      order: "Random",
      limit: 7,
    },
    defaultUseQueryRefetchOptions
  );

  const {
    data: randomLandersInspiration,
    isLoading: landerInspirationIsLoading,
  } = trpc.getAllLandingPages.useQuery(
    {
      filters: {
        sort: "Random",
      },
      limit: 7,
    },
    defaultUseQueryRefetchOptions
  );
  return (
    <div className="flex flex-col gap-2 pb-6">
      {(randomAdInspiration?.ads ?? []).length > 0 || adInspirationIsLoading ? (
        <div className="space-y-2.5">
          <h6 className="text-sm">Ads</h6>
          <div className="flex space-x-2.5">
            {adInspirationIsLoading ? (
              <div className="flex space-x-2.5">
                {[...Array(3)].map((_, index) => (
                  <Skeleton
                    key={index}
                    className="w-[11.4063rem] h-[11.4063rem] rounded-2xl"
                  />
                ))}
              </div>
            ) : (
              <ScrollArea className="whitespace-nowrap space-y-2.5">
                <div className="flex space-x-2.5 pr-5">
                  {randomAdInspiration?.ads.map((item) => (
                    <SearchDialogAdInspirationImage
                      key={item.id}
                      item={{
                        id: item.id,
                        reach: null,
                        earliestView: "",
                        adRunningDays: 0,
                        imageUrl: item.imageUrl ?? null,
                        videoUrl: item.videoUrl ?? "",
                        brandId: item.brandId ?? "",
                        brandName: item.brandName ?? "",
                        brandImage: item.brandImage ?? "",
                        isSaved: item.isSaved ?? false,
                      }}
                    />
                  ))}
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            )}
          </div>
          <div className="flex justify-end mr-3">
            <Link className="text-slate-500" to="/feeds/inspiration">
              <span className="flex items-center gap-2 text-sm">
                <span>See all</span> <ArrowRight size={"1rem"} />
              </span>
            </Link>
          </div>
        </div>
      ) : null}
      {(randomEmailInspiration?.emails ?? []).length > 0 ||
      emailInspirationsIsLoading ? (
        <div className="space-y-2.5">
          <h6 className="text-sm">Emails</h6>
          <div className="flex space-x-2.5">
            {emailInspirationsIsLoading ? (
              <div className="flex space-x-2.5">
                {[...Array(3)].map((_, index) => (
                  <Skeleton
                    key={index}
                    className="w-[11.4063rem] h-[11.4063rem] rounded-2xl"
                  />
                ))}
              </div>
            ) : (
              <ScrollArea className="whitespace-nowrap space-y-2.5">
                <div className="flex space-x-2.5 pr-5">
                  {randomEmailInspiration?.emails.map((item) => (
                    <SearchDialogEmailInspirationImage
                      key={item.id}
                      item={{
                        ...item,
                        requestCount: 0,
                        template: null,
                        to: "",
                        imageUrl: item.imageUrl ?? null,
                        subject: item.subject ?? "",
                        homepage: item.homepage ?? "",
                        brandId: item.brandId ?? "",
                        brandName: item.brandName ?? "",
                        brandImage: item.brandImage ?? "",
                      }}
                    />
                  ))}
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            )}
          </div>
          <div className="flex justify-end mr-3">
            <Link className="text-slate-500" to="/feeds/inspiration/emails">
              <span className="flex items-center gap-2 text-sm">
                <span>See all</span> <ArrowRight size={"1rem"} />
              </span>
            </Link>
          </div>
        </div>
      ) : null}
      {(randomLandersInspiration?.results ?? []).length > 0 ||
      landerInspirationIsLoading ? (
        <div className="space-y-2.5">
          <h6 className="text-sm">Landers</h6>
          <div className="flex space-x-2.5">
            {landerInspirationIsLoading ? (
              <div className="flex space-x-2.5">
                {[...Array(3)].map((_, index) => (
                  <Skeleton
                    key={index}
                    className="w-[11.4063rem] h-[11.4063rem] rounded-2xl"
                  />
                ))}
              </div>
            ) : (
              <ScrollArea className="whitespace-nowrap space-y-2.5">
                <div className="flex space-x-2.5 pr-5">
                  {randomLandersInspiration?.results.map((item) => (
                    <SearchDialogLanderInspirationImage
                      key={item.landerId}
                      item={{
                        ...item,
                        requestCount: 0,
                        template: null,
                        landerUrl: item.landerUrl ?? "",
                        desktopScreenshot: item.desktopScreenshot ?? "",
                        mobileScreenshot: item.mobileScreenshot ?? "",
                        brandId: item.brandId ?? "",
                        brandName: item.brandName ?? "",
                        brandImage: item.brandImage ?? "",
                      }}
                    />
                  ))}
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            )}
          </div>
          <div className="flex justify-end mr-3">
            <Link
              className="text-slate-500"
              to="/feeds/inspiration/landing-pages"
            >
              <span className="flex items-center gap-2 text-sm">
                <span>See all</span> <ArrowRight size={"1rem"} />
              </span>
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SearchDialogInspirationTab;
