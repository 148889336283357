import { Stack, Text } from "@/components/custom-components";
import { CalendarUp, Layout, Mobile, User } from "@mynaui/icons-react";
import { Badge } from "./shadcn/badge";
import {
  GetLandingAdTemplate,
  LandingAdDataProps,
} from "./templates/LandingAdCard";

export const TemplateDetailsLanderEmailBody = ({
  numberOfUsers,
  landingData,
  dateSaved,
  type,
  mediaComponent,
}: {
  onCloseDrawer: () => void;
  copyToClipboard: (x: string) => void;
  numberOfUsers: number;
  dateSaved: string;
  landingData: LandingAdDataProps;
  type?: "email" | "landing-page";
  mediaComponent?: JSX.Element;
}) => {
  return (
    <div className="px-3 flex flex-col lg:px-8 py-3 pt-0 lg:py-8 lg:pt-4 gap-8 lg:gap-6">
      <div className="px-0 gap-2  flex flex-col">
        <div className="flex gap-2 items-center">
          <Text weight={"semibold"} size={"lg"} className="leading-7 w-fit">
            {type == "email" ? "Email" : "Lander"} Template
          </Text>
          <Badge variant={"secondary"} className="px-2.5 py-1 flex gap-1">
            <Layout className="w-4 h-4 min-w-4" /> {"Template"}
          </Badge>
        </div>
        <div
          className={
            "flex items-center gap-4 text-thememutedforeground text-sm"
          }
        >
          <div className={"flex items-center gap-1"}>
            <span>
              <User className={"w-4.5"} />
            </span>
            <span>{numberOfUsers || 0} users</span>
          </div>
        </div>
      </div>

      <GetLandingAdTemplate buttonVersion type={type!} Ad={landingData} />
      {type === "landing-page" && (
        <Stack className="gap-1">
          <div className="flex gap-1.5 items-center">
            <Mobile />
            <Text weight={"medium"} size={"sm"}>
              Mobile Design Included!
            </Text>
          </div>
          <Text
            weight={"medium"}
            size={"xs"}
            className="text-thememutedforeground"
          >
            Both Desktop and Mobile designs are included in the Figma file.
          </Text>
        </Stack>
      )}

      {mediaComponent}

      <Stack className={"flex flex-col gap-4 text-sm"}>
        <Text weight={"medium"} size="sm" className="leading-5">
          Template Details
        </Text>
        <div className={"flex justify-between items-center gap-4"}>
          <Text
            weight={"thin"}
            size="sm"
            className="leading-5 text-thememutedforeground w-24 min-w-24"
          >
            Live On
          </Text>
          <div className="flex items-center gap-1 max-w-full">
            <CalendarUp className="w-4 h-4 min-w-4" />

            <span
              className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap`}
            >
              {dateSaved}
            </span>
          </div>
        </div>
      </Stack>
    </div>
  );
};
