import { CreativeOSFeatures } from "@/_shared/subscriptionPlans";
import { Button, buttonVariants } from "@/components/shadcn/button.tsx";
import { Progress } from "@/components/shadcn/progress.tsx";
import { ScrollArea } from "@/components/shadcn/scroll-area.tsx";
import { Ad } from "@/components/templates/DiscoverGridView";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { useCopyGrader } from "@/hooks/useCopyGrader";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import showToastNotification from "@/hooks/useShowToast";
import useUserPermissions from "@/hooks/useUserPermissions";
import { cn } from "@/lib/utils";
import { trpc } from "@/utils/trpc";
import {
  CalendarUp,
  CheckCircleSolid,
  Copy,
  ExternalLink,
  InfoCircle,
  Layout,
  Like,
  Lock,
} from "@mynaui/icons-react";
import { useState } from "react";
import { Ad as AdCreativeDetails } from "../../../../src/server/types";
import AccessControl from "./AccessControl";
import { Stack, Text } from "./custom-components";
import {
  PingCirclesGray,
  PingCirclesGreen,
} from "./custom-components/PingCircles";
import { Badge } from "./shadcn/badge";
import { GetInspirationTemplate } from "./templates/AdCard";

interface Props {
  data: Ad;
  adCreativeDetails?: AdCreativeDetails | null;
  IsPublic: boolean;
  mediaComponent?: JSX.Element;
}

export default function AdInspirationDetailsBody({
  data,
  adCreativeDetails,
  mediaComponent,
  IsPublic,
}: Props) {
  const [, copyToClipboard] = useCopyToClipboard();
  const [adRequested, setAdRequested] = useState<boolean>(
    adCreativeDetails?.hasUserRequestedTemplate ?? false
  );
  const [copiedText, setCopiedText] = useState<boolean>(false);
  const [showCopyButton, setShowCopyButton] = useState<boolean>(false);
  const copyText = `${adCreativeDetails?.headline ?? ""}\n${adCreativeDetails?.description ?? ""}\n${adCreativeDetails?.text ?? ""}\n${adCreativeDetails?.ctaText ?? ""}`;
  const { userLimits } = useUserPermissions();
  const {
    readabilityScore,
    persuasionAnalysisResults,
    getReadingLevel,
    getPersuasionLevel,
  } = useCopyGrader({
    copyText,
  });
  const utils = trpc.useUtils();
  const { mutateAsync: requestAdTemplate, isPending } =
    trpc.requestAdTemplateRpc.useMutation();

  return (
    <div className="px-3 flex flex-col lg:px-8 py-3 pt-0 lg:py-8 lg:pt-4 gap-8 lg:gap-6">
      <div className="px-0 gap-2 flex flex-col">
        <div className="flex gap-2 items-center">
          <Text weight={"semibold"} size={"lg"} className="leading-7 w-fit">
            {data.brandName} Ad
          </Text>
          {adCreativeDetails?.template && (
            <Badge
              variant={
                adCreativeDetails?.hasUserRequestedTemplate
                  ? "secondaryDestructive"
                  : "secondary"
              }
              className="px-2.5 py-1 flex gap-1"
            >
              <Layout className="w-4 h-4 min-w-4" />{" "}
              {adCreativeDetails?.hasUserRequestedTemplate
                ? "Template Made for You!"
                : "Template"}
            </Badge>
          )}
        </div>
        <div
          className={
            "flex items-center gap-2 lg:gap-4 text-thememutedforeground text-sm flex-wrap"
          }
        >
          <div>
            <Text
              weight={"normal"}
              size={"sm"}
              className={"flex items-center gap-2 text-thememutedforeground"}
            >
              {data.isActiveSinceLastScrape ? (
                <>
                  <PingCirclesGreen />
                  Active for {data.adRunningDays} Days
                </>
              ) : (
                <>
                  <PingCirclesGray />
                  Inactive for {data.inactiveTime} Days
                </>
              )}
            </Text>
          </div>
          <div className={"flex items-center gap-1"}>
            <span>
              <Like className={"w-4.5"} />
            </span>
            <span>{adCreativeDetails?.numberOfRequests || 0} Requests</span>
          </div>
        </div>
      </div>
      {mediaComponent}
      {adCreativeDetails?.template ? (
        <GetInspirationTemplate
          buttonVersion
          Ad={adCreativeDetails?.template}
          IsPublic={IsPublic}
        />
      ) : (
        <Stack className="gap-3">
          {!data.videoUrl && (
            <AccessControl
              feature={CreativeOSFeatures.AD_INSPIRATION_REQUEST}
              FeatureUnlocked={
                <Button
                  onClick={() => {
                    setAdRequested(true);
                    requestAdTemplate({
                      adId: data.id,
                    }).then(() => {
                      showToastNotification("success", {
                        message: `${Math.max((userLimits?.request.ad ?? 0) - 1, 0)} Template Requests left!`,
                        description: `You have ${Math.max((userLimits?.request.ad ?? 0) - 1, 0)} template requests left for the month.`,
                      });
                      utils.getUserLimits.invalidate();
                      utils.permissions.invalidate();
                      utils.getAdCreativeDetails.invalidate({
                        adId: data.id,
                      });
                    });
                  }}
                  variant={
                    adCreativeDetails?.hasUserRequestedTemplate
                      ? "default"
                      : "destructivePurple"
                  }
                  loading={isPending}
                  className="w-full"
                >
                  {adRequested ? (
                    <CheckCircleSolid className="w-5 h-5 mr-2" />
                  ) : null}

                  {adRequested ? "Requested" : "Request Template"}
                </Button>
              }
              FeatureLocked={
                <Button
                  className={`w-full opacity-50`}
                  variant={
                    adCreativeDetails?.hasUserRequestedTemplate
                      ? "default"
                      : "destructivePurple"
                  }
                  loading={isPending}
                >
                  <Lock className="w-5 h-5 mr-2" />

                  {adRequested ? "Requested" : "Request Template"}
                </Button>
              }
            />
          )}
          {adCreativeDetails?.landingPageUrl && (
            <a
              href={adCreativeDetails?.landingPageUrl}
              target={"_blank"}
              className={cn(buttonVariants({ variant: "secondary" }))}
            >
              View Landing Page
            </a>
          )}
        </Stack>
      )}
      {adCreativeDetails && (
        <Stack className={"flex flex-col gap-4 text-sm"}>
          <Text weight={"medium"} size="sm" className="leading-5">
            Ad Details
          </Text>
          <div className={"flex justify-between items-center gap-4"}>
            <Text
              weight={"thin"}
              size="sm"
              className="leading-5 text-thememutedforeground w-24 min-w-24"
            >
              Landing Page
            </Text>
            <a
              href={adCreativeDetails?.landingPageUrl ?? ""}
              target={"_blank"}
              className="flex items-center max-w-full overflow-hidden text-themedestructive"
            >
              <span
                className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap`}
              >
                {adCreativeDetails.landingPageUrl}
              </span>
              <ExternalLink className="w-4 h-4 min-w-4" />
            </a>
          </div>
          {adCreativeDetails.publishedDate &&
            !isNaN(new Date(adCreativeDetails.publishedDate).getTime()) && (
              <div className={"flex justify-between items-center gap-4"}>
                <Text
                  weight={"thin"}
                  size="sm"
                  className="leading-5 text-thememutedforeground w-24 min-w-24"
                >
                  Published
                </Text>
                <div className="flex items-center gap-1 max-w-full">
                  <CalendarUp className="w-4 h-4 min-w-4" />

                  <span
                    className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap`}
                  >
                    {new Date(
                      adCreativeDetails.publishedDate
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>
                </div>
              </div>
            )}
          <div className={"flex justify-between items-center gap-4"}>
            <Text
              weight={"thin"}
              size="sm"
              className="leading-5 text-thememutedforeground w-24 min-w-24"
            >
              Run Time
            </Text>
            <div className="flex items-center gap-1 max-w-full">
              {data.isActiveSinceLastScrape ? (
                <PingCirclesGreen />
              ) : (
                <PingCirclesGray />
              )}

              <span
                className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap flex`}
              >
                {`${data.isActiveSinceLastScrape ? `Active for ${data.adRunningDays} Days` : `Inactive for ${data.inactiveTime} Days`}`}
              </span>
            </div>
          </div>
          <div className={"flex justify-between items-center gap-4"}>
            <Text
              weight={"thin"}
              size="sm"
              className="leading-5 text-thememutedforeground w-46 min-w-46 flex items-center gap-2"
            >
              {{
                5: "Excellent",
                4: "Great",
                3: "Good",
                2: "Average",
                1: "Low",
              }[adCreativeDetails.rating] || ""}{" "}
              Performance
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className={"text-thememutedforeground"}>
                    <InfoCircle size={"1.2rem"} />
                  </span>
                </TooltipTrigger>
                <TooltipContent className="max-w-sm">
                  <p>
                    Performance Score is an estimate calculated using benchmarks
                    like Ad Longevity, Ad Spend, and performance metrics
                  </p>
                </TooltipContent>
              </Tooltip>
            </Text>
            <div className={"flex items-center gap-1.5 w-full"}>
              <Progress
                value={(adCreativeDetails.rating / 5) * 100}
                className={`h-2`}
              />
              <span className="w-fit text-sm font-light text-thememutedforeground">
                {(adCreativeDetails.rating / 5) * 100}
              </span>
            </div>
          </div>
        </Stack>
      )}
      {adCreativeDetails && (
        <Stack className={"flex flex-col gap-4 text-sm"}>
          <Text weight={"medium"} size="sm" className="leading-5">
            Ad Copy
          </Text>

          <div className="border rounded">
            <ScrollArea
              className="p-2 h-fit max-h-[20svh] relative overflow-y-auto"
              onMouseEnter={() => setShowCopyButton(true)}
              onMouseLeave={() => setShowCopyButton(false)}
            >
              {showCopyButton && (
                <div className="flex justify-end absolute top-1 right-1">
                  <Button
                    onClick={() => {
                      copyToClipboard(copyText).then(() => {
                        setCopiedText(true);
                        showToastNotification("success", {
                          message: "Copied!",
                        });
                        setTimeout(() => {
                          setCopiedText(false);
                        }, 2000);
                      });
                    }}
                    variant="outline"
                    size="sm"
                    className="flex gap-1.5 items-center"
                  >
                    <span className="text-sm font-light">
                      {copiedText ? "Copied!" : "Copy"}
                    </span>
                    <Copy strokeWidth={1} size="1.2rem" />
                  </Button>
                </div>
              )}
              <p className="text-thememutedforeground">
                {adCreativeDetails.headline}
              </p>
              <p className="text-thememutedforeground">
                {adCreativeDetails.description}
              </p>
              <p className="text-thememutedforeground">
                {adCreativeDetails.text}
              </p>
            </ScrollArea>
            <div className="p-2 border-t flex justify-between gap-1.5">
              <div>
                <p className="text-sm">{adCreativeDetails.headline}</p>
                <p className="text-xs text-thememutedforeground font-light">
                  {adCreativeDetails.description}
                </p>
              </div>
            </div>
          </div>

          <div className={"flex justify-between items-center gap-4"}>
            <Text
              weight={"thin"}
              size="sm"
              className="leading-5 text-thememutedforeground w-46 min-w-46 flex items-center gap-2"
            >
              Readability
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className={"text-thememutedforeground"}>
                    <InfoCircle size={"1.2rem"} />
                  </span>
                </TooltipTrigger>
                <TooltipContent className="max-w-sm">
                  <p>
                    This copy reads at a {getReadingLevel(readabilityScore)}{" "}
                    Level
                  </p>
                </TooltipContent>
              </Tooltip>
            </Text>
            <div className={"flex items-center gap-1.5 w-full"}>
              <Progress value={readabilityScore} className={`h-2 `} />

              <span className="w-fit text-sm font-light text-thememutedforeground">
                {readabilityScore}
              </span>
            </div>
          </div>
          <div className={"flex justify-between items-center gap-4"}>
            <Text
              weight={"thin"}
              size="sm"
              className="leading-5 text-thememutedforeground w-46 min-w-46 flex items-center gap-2"
            >
              Persuasion
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className={"text-thememutedforeground"}>
                    <InfoCircle size={"1.2rem"} />
                  </span>
                </TooltipTrigger>
                <TooltipContent className="max-w-sm">
                  <p>
                    {
                      getPersuasionLevel(persuasionAnalysisResults.score)
                        .description
                    }
                  </p>
                </TooltipContent>
              </Tooltip>
            </Text>
            <div className={"flex items-center gap-1.5 w-full"}>
              <Progress
                value={persuasionAnalysisResults.score}
                className={`h-2 `}
              />

              <span className="w-fit text-sm font-light text-thememutedforeground">
                {persuasionAnalysisResults.score}
              </span>
            </div>
          </div>
        </Stack>
      )}
    </div>
  );
}
