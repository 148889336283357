import {
  AppliedPromoDetail,
  getAllCountries,
  getStatesByCode2,
  inputStyles,
  nextDueDate,
} from '@/components/CheckoutSubscriptionPlan.tsx';
import LogoutButton from '@/components/LogoutButton';
import { Loader } from '@/components/custom-components/Loader';
import OnboardingHeader from '@/components/onboarding/OnboardingHeader.tsx';
import { Badge } from '@/components/shadcn/badge.tsx';
import { Button } from '@/components/shadcn/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/shadcn/form';
import { Input } from '@/components/shadcn/input.tsx';
import { Label } from '@/components/shadcn/label.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shadcn/select.tsx';
import { Tabs, TabsList, TabsTrigger } from '@/components/shadcn/tabs.tsx';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/shadcn/tooltip';
import useMonitorSubscription from '@/hooks/useMonitorSubscription';
import showToastNotification from '@/hooks/useShowToast.tsx';
import { handleCheckoutStart, handleTrialStart } from '@/lib/gtag';
import { cn } from '@/lib/utils.ts';
import { featureFlagKeys } from '@/utils/data/featureFlags';
import { PlanDetail, getCurrencySymbol, plans } from '@/utils/data/plans.ts';
import { trpc } from '@/utils/trpc.ts';
import {
  CardCVV,
  CardComponent,
  CardExpiry,
  CardNumber,
} from '@chargebee/chargebee-js-react-wrapper';
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { zodResolver } from '@hookform/resolvers/zod';
import { Lock, XCircle } from '@mynaui/icons-react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { TRPCError } from '@trpc/server';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import {
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { useLocalStorage } from 'usehooks-ts';
import { z } from 'zod';

type SearchParams = {
  plan?: string;
};

export const Route = createFileRoute('/checkout')({
  component: Checkout,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const plan = search?.plan as string | undefined;

    return {
      plan,
    };
  },
});

function Checkout() {
  const annualFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.annualPlans);

  const [cosPlan, set_cos_plan] = useLocalStorage<string>('cos_plan', '');
  const [cosCoupon] = useLocalStorage<string>('cos_coupon', '');

  const [period, setPeriod] = useState<'monthly' | 'annually'>('monthly');

  // Ref to ensure applyPromo is only called once
  const hasAppliedPromo = useRef(false);

  const cardRef = useRef<ChargebeeComponents | null>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [addPromoOpen, setAddPromoOpen] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanDetail | undefined>(
    undefined,
  );

  const [appliedPromo, setAppliedPromo] = useState<
    AppliedPromoDetail | undefined
  >(undefined);

  const navigate = useNavigate();

  const countries = getAllCountries();

  const [checkoutSuccessful, setCheckoutSuccessful] = useState<boolean>(false);

  const { subscriptionSuccessful } = useMonitorSubscription(checkoutSuccessful);

  const checkoutFormSchema = z.object({
    chargebeeToken: z.string({ required_error: 'Chargebee Token is required' }),
    coupon: z.string(),
    firstName: z
      .string({ required_error: 'First name is required' })
      .min(2, 'First name is required'),
    lastName: z
      .string({ required_error: 'Last name is required' })
      .min(2, 'Last name is required'),
    plan: z.string({ required_error: 'Plan ID is required' }),
    billingAddress: z.object({
      country: z
        .string({ required_error: 'Country is required' })
        .describe(
          'country in ISO Code https://www.iso.org/iso-3166-country-codes.html',
        )
        .min(2, 'Country is required'),
      zip: z
        .string({ required_error: 'Zip code is required' })
        .min(2, 'Zip code is required'),
      city: z
        .string({ required_error: 'City is required' })
        .min(2, 'City is required'),
      state: z
        .string({ required_error: 'State/Region is required' })
        .min(2, 'State/Region is required'),
      line1: z
        .string({ required_error: 'Line 1 is required' })
        .min(2, 'Line 1 is required'),
      line2: z.string().optional(),
    }),
  });

  type CheckoutFormValues = z.infer<typeof checkoutFormSchema>;

  const defaultValues: Partial<CheckoutFormValues> = {
    coupon: cosCoupon || '',
    chargebeeToken: '',
    plan: '',
    firstName: '',
    lastName: '',
    billingAddress: {
      city: '',
      zip: '',
      state: '',
      line1: '',
      line2: '',
      country: '',
    },
  };

  const form = useForm<CheckoutFormValues>({
    resolver: zodResolver(checkoutFormSchema),
    defaultValues,
  });

  useLayoutEffect(() => {
    document.body.classList.toggle('dark', false);
  }, []);

  useEffect(() => {
    if (!cosPlan || !plans) {
      navigate({ to: '/plans' });
      return;
    }

    const selected = plans.find(
      (p) => p.plan_id === cosPlan || p.yearly_plan_id === cosPlan,
    );

    if (selected) {
      setSelectedPlan(selected);
      handleCheckoutStart([
        {
          item_id: cosPlan,
          item_name: selected.name,
          price: parseInt(selected.price.replace('$', ''), 10),
          quantity: 1,
        },
      ]);
    }
  }, [cosPlan, plans, navigate, handleCheckoutStart]);

  // Set period based on the `cos_plan` in localStorage
  useEffect(() => {
    const setInitialPeriod = () => {
      const currentPlan = plans.find(
        (plan) => plan.plan_id === cosPlan || plan.yearly_plan_id === cosPlan,
      );

      if (currentPlan) {
        setPeriod(
          cosPlan === currentPlan.yearly_plan_id ? 'annually' : 'monthly',
        );
      }
    };

    setInitialPeriod();
  }, [cosPlan]);

  // Update localStorage when period changes
  const updateLocalStorage = (newPeriod: 'monthly' | 'annually') => {
    const cosPlanKey = newPeriod === 'monthly' ? 'plan_id' : 'yearly_plan_id';
    const cosPlan = plans.find((plan) => plan[cosPlanKey]);

    if (cosPlan) {
      set_cos_plan(cosPlan[cosPlanKey as keyof typeof cosPlan].toString());
    }
  };

  const handlePeriodChange = (newPeriod: 'monthly' | 'annually') => {
    setPeriod(newPeriod);
    updateLocalStorage(newPeriod);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedCosPlan = cosPlan;
      const matchingPlan = plans.find(
        (plan) =>
          plan.plan_id === updatedCosPlan ||
          plan.yearly_plan_id === updatedCosPlan,
      );
      if (matchingPlan) {
        setPeriod(
          updatedCosPlan === matchingPlan.yearly_plan_id
            ? 'annually'
            : 'monthly',
        );
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (cosCoupon && !hasAppliedPromo.current) {
      form.setValue('coupon', cosCoupon);

      applyPromo();
      hasAppliedPromo.current = true;
    }
  }, [cosCoupon]);

  const subscribeToPlan = () => {
    if (cardRef.current) {
      cardRef.current
        .tokenize({
          addressLine1: form.getValues('billingAddress.line1'),
          addressLine2: form.getValues('billingAddress.line2'),
          city: form.getValues('billingAddress.city'),
          state: form.getValues('billingAddress.state'),
          zip: form.getValues('billingAddress.zip'),
          countryCode: form.getValues('billingAddress.country'),
          firstName: form.getValues('firstName'),
          lastName: form.getValues('lastName'),
        })
        .then((data: { token: string }) => {
          form.setValue('chargebeeToken', data.token);
        })
        .then(() => {
          setTimeout(async () => {
            try {
              const plan =
                cosPlan || (!annualFlagEnabled && form.getValues('plan'));

              checkoutSubscription({
                plans: plan ? [plan] : [],
                coupons: form.getValues('coupon')
                  ? ([form.getValues('coupon')].filter(Boolean) as string[])
                  : undefined,
                chargebeeToken: form.getValues('chargebeeToken'),
                billingAddress: form.getValues('billingAddress'),
              });
            } catch (e) {
              setIsSubmitting(false);
              if (e instanceof Error) {
                if (e.message.includes('already present')) {
                  toast.error('This account already exists.');
                  navigate({ to: '/feeds/templates' });
                } else {
                  toast.error(e.message); // Show the original error for other cases
                }
              } else {
                toast.error('An unknown error occurred');
              }
            } finally {
              setIsSubmitting(false);
            }
          }, 500);
        })
        .catch((e: TRPCError) => {
          setIsSubmitting(false);
          toast.error(e.message);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      setIsSubmitting(false);
    }
  };

  const onSubmit = () => {
    setIsSubmitting(true);

    if (
      form.getValues('coupon') &&
      form.getValues('coupon')?.length > 0 &&
      !appliedPromo
    ) {
      try {
        return refetchCoupon({ throwOnError: true })
          .then(() => {
            subscribeToPlan();
          })
          .catch((e) => {
            if (e instanceof Error) {
              if (e.message === "Sorry, we couldn't find that resource")
                toast.error('This coupon code is invalid!');
              else toast.error(e.message);
            } else {
              toast.error('An unknown error occurred');
            }
          });
      } catch (e) {
        setIsSubmitting(false);
        if (e instanceof Error) {
          if (e.message === "Sorry, we couldn't find that resource")
            toast.error('This coupon code is invalid!');
          else toast.error(e.message);
        } else {
          toast.error('An unknown error occurred');
        }
      } finally {
        setIsSubmitting(false);
      }
    } else {
      subscribeToPlan();
    }
  };

  const applyPromo = async () => {
    try {
      const response = await refetchCoupon({ throwOnError: true });
      if (response && response.data) {
        setAddPromoOpen(false);
        setAppliedPromo(response.data);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "Sorry, we couldn't find that resource") {
          form.setValue('coupon', '');
          toast.error('This coupon code is invalid!');
        } else toast.error(e.message);
      } else {
        toast.error('An unknown error occurred');
      }
    }
  };

  const onReady = (event: SyntheticEvent) => {
    const element = event.currentTarget as HTMLElement;
    if (element) element.focus();
  };

  const { isPending, mutate: checkoutSubscription } =
    trpc.checkoutSubscription.useMutation({
      onSuccess: (response) => {
        setIsSubmitting(true);
        if (response && response.success) {
          if (selectedPlan) {
            handleTrialStart(
              `${new Date()}`,
              parseInt(selectedPlan?.price.replace('$', ''), 10),
              [
                {
                  item_id: selectedPlan?.plan_id,
                  item_name: selectedPlan?.name,
                  quantity: 1,
                },
              ],
            );
          }
          setCheckoutSuccessful(true);
        } else {
          setIsSubmitting(false);
        }
      },
      onError: (error) => {
        showToastNotification('error', {
          message: error.message,
        });
      },
    });

  useEffect(() => {
    if (subscriptionSuccessful) {
      setIsSubmitting(false);
      navigate({ to: '/feeds/templates' });
    }
  }, [subscriptionSuccessful]);

  const {
    isLoading: isLoadingCoupon,
    isRefetching: isRefetchingCoupon,
    refetch: refetchCoupon,
  } = trpc.getCouponDetails.useQuery(
    {
      couponId: !form.getFieldState('coupon').invalid
        ? form.getValues('coupon')
        : '',
    },
    { enabled: false, retry: false },
  );

  if (cosCoupon && (isLoadingCoupon || isRefetchingCoupon)) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={'bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen'}
    >
      <div className="flex justify-between items-center gap-2">
        <OnboardingHeader />
        <div className="pr-5 lg:pr-10 pt-5 lg:pt-10">
          <LogoutButton />
        </div>
      </div>
      <div
        className={
          'flex-1 flex flex-col justify-center items-center py-[7.75rem]'
        }
      >
        {selectedPlan && (
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className={`bg-themebackground dark:bg-themebackgrounddark rounded-lg p-5 w-11/12 ${annualFlagEnabled ? 'lg:max-w-2xl' : 'lg:max-w-lg'} border `}
            >
              <div className={'flex flex-col gap-6'}>
                <div className={'flex justify-between items-center gap-2'}>
                  <h4 className={'text-xl lg:text-2xl text-left font-semibold'}>
                    Subscribe to CreativeOS
                  </h4>
                  <Badge
                    variant={'secondaryDestructive'}
                    className={'text-nowrap'}
                  >
                    3-Day Trial
                  </Badge>
                </div>
                <div>
                  <div
                    className={
                      'flex flex-col md:flex-row justify-between gap-3 items-center'
                    }
                  >
                    <p className={'font-bold'}>Subscription</p>
                    {annualFlagEnabled ? (
                      <Tabs defaultValue={period}>
                        <TabsList className={'rounded-full'}>
                          <TabsTrigger
                            className={'rounded-full'}
                            value={'monthly'}
                            onClick={() => handlePeriodChange('monthly')}
                          >
                            Pay Monthly
                          </TabsTrigger>
                          <TabsTrigger
                            className={'rounded-full flex gap-1.5'}
                            value={'annually'}
                            onClick={() => handlePeriodChange('annually')}
                          >
                            <span>Pay Yearly</span>{' '}
                            <span className={'text-themedestructive'}>
                              Save 50%
                            </span>
                          </TabsTrigger>
                        </TabsList>
                      </Tabs>
                    ) : null}
                  </div>
                  <div className={'flex flex-col'}>
                    <div
                      className={
                        'flex flex-col gap-3 py-3 border-b border-dashed'
                      }
                    >
                      <div>
                        <div className={'flex gap-3 justify-between text-sm'}>
                          <span className={'flex gap-1 items-center'}>
                            <img src={'/images/icon_no_bg.png'} width={16} height={16} alt={''} />
                            <span>CreativeOS {selectedPlan.name}</span>
                          </span>
                          <span className={'font-semibold'}>
                            {period === 'monthly'
                              ? selectedPlan.price
                              : selectedPlan.annual_price}{' '}
                            / {period === 'monthly' ? 'month' : 'year'}
                          </span>
                        </div>
                        <div
                          className={
                            'flex gap-3 justify-between text-xs font-light text-thememutedforeground'
                          }
                        >
                          <span>
                            {period === 'monthly' ? 'Monthly' : 'Annual'}{' '}
                            Subscription, cancel anytime.
                          </span>
                          <span>
                            {period === 'monthly' ? null : '$42 / mo avg.'}
                          </span>
                        </div>
                      </div>

                      <div className={'flex justify-between gap-3'}>
                        <p className={'text-sm'}>Billing Cycle</p>
                        {annualFlagEnabled ? (
                          <div>
                            <p className={'text-sm font-semibold text-right'}>
                              {period === 'monthly' ? 'Monthly' : 'Yearly'}
                            </p>
                            {period === 'annually' && (
                              <p
                                className={
                                  'text-xs md:text-sm text-right text-green-600'
                                }
                              >
                                Saving {selectedPlan.amount_saved}/year with
                                Annual Plan
                              </p>
                            )}
                          </div>
                        ) : null}
                      </div>

                      <div className={'flex justify-between gap-3'}>
                        <p className={'text-sm'}>First Charge Date</p>
                        <p className={'text-sm font-semibold text-right'}>
                          {nextDueDate}
                        </p>
                      </div>

                      <div>
                        {addPromoOpen ? (
                          <div className={'flex gap-1.5'}>
                            <FormField
                              control={form.control}
                              name="coupon"
                              render={({ field }) => (
                                <FormItem className={'flex-1'}>
                                  <FormControl>
                                    <Input
                                      type={'text'}
                                      placeholder={'Add Promo Code'}
                                      {...field}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                            <Button
                              onClick={() => {
                                applyPromo();
                              }}
                              variant={'secondary'}
                              size={'sm'}
                              type={'button'}
                              disabled={
                                isLoadingCoupon ||
                                isRefetchingCoupon ||
                                form.watch('coupon') === ''
                              }
                              loading={isLoadingCoupon || isRefetchingCoupon}
                            >
                              {isLoadingCoupon || isRefetchingCoupon
                                ? 'Checking...'
                                : 'Apply'}
                            </Button>
                          </div>
                        ) : appliedPromo ? (
                          <div>
                            <div
                              className={'flex gap-3 justify-between text-sm'}
                            >
                              <span className={'flex gap-1 items-center'}>
                                <img src={'/label.png'} alt={''} />
                                <span>{appliedPromo.name}</span>
                                <span
                                  onClick={() => {
                                    setAddPromoOpen(false);
                                    setAppliedPromo(undefined);
                                    form.setValue('coupon', '');
                                  }}
                                  className={
                                    'text-thememutedforeground cursor-pointer'
                                  }
                                >
                                  <XCircle className={'w-4 h-4'} />
                                </span>
                              </span>
                              <span className={'font-semibold text-green-600'}>
                                -
                                {appliedPromo.discountType === 'fixed_amount' &&
                                appliedPromo.currencyCode
                                  ? getCurrencySymbol(appliedPromo.currencyCode)
                                  : ''}
                                {appliedPromo.discountAmount
                                  ? `${(appliedPromo.discountAmount / 100).toFixed(2)}`
                                  : `${appliedPromo.discountPercentage}%`}{' '}
                                {appliedPromo.durationType === 'limited_period'
                                  ? `/ ${appliedPromo.periodUnit}`
                                  : appliedPromo.durationType}
                              </span>
                            </div>
                            <div
                              className={
                                'flex gap-3 justify-between text-xs font-light text-thememutedforeground capitalize'
                              }
                            >
                              <span>
                                {appliedPromo.discountType === 'fixed_amount' &&
                                appliedPromo.currencyCode
                                  ? getCurrencySymbol(appliedPromo.currencyCode)
                                  : ''}
                                {appliedPromo.discountAmount
                                  ? `${(appliedPromo.discountAmount / 100).toFixed(2)}`
                                  : `${appliedPromo.discountPercentage}%`}
                                {' OFF'}{' '}
                                {appliedPromo?.periodUnit === 'month'
                                  ? 'monthly'
                                  : appliedPromo?.periodUnit === 'year'
                                    ? 'yearly'
                                    : ''}{' '}
                                {' Price '}
                                {appliedPromo.periodUnit
                                  ? `for ${appliedPromo.period} ${appliedPromo.periodUnit}`
                                  : appliedPromo.durationType}
                              </span>
                              <span>
                                {appliedPromo.durationType ===
                                  'limited_period' &&
                                  `For ${appliedPromo.period} ${appliedPromo.periodUnit}`}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <span
                            onClick={() => setAddPromoOpen(true)}
                            className={
                              'text-sm underline text-themedestructive font-medium cursor-pointer'
                            }
                          >
                            Add Promo Code
                          </span>
                        )}
                      </div>
                    </div>

                    <div className={'flex justify-between gap-3 py-2'}>
                      <p className={'text-sm'}>Due Today</p>
                      <p className={'text-sm font-semibold text-right'}>
                        $0.00
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className={'flex flex-col gap-6'}>
                    <div>
                      <p className={'font-bold'}>Payment Details</p>
                    </div>
                    <div className={'grid grid-cols-2 gap-3'}>
                      <FormField
                        control={form.control}
                        name="firstName"
                        render={({ field }) => (
                          <FormItem>
                            <Label>
                              First Name{' '}
                              <span className={'text-red-500'}>*</span>
                            </Label>
                            <FormControl>
                              <Input placeholder="First Name" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="lastName"
                        render={({ field }) => (
                          <FormItem>
                            <Label>
                              Last Name{' '}
                              <span className={'text-red-500'}>*</span>
                            </Label>
                            <FormControl>
                              <Input placeholder="Last Name" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <CardComponent
                      className="field"
                      ref={cardRef}
                      onReady={onReady}
                    >
                      <div>
                        <Label>
                          Card Number <span className={'text-red-500'}>*</span>
                        </Label>
                        <CardNumber
                          placeholder="4111 1111 1111 1111"
                          className={cn(inputStyles())}
                        />
                      </div>
                      <div className={'grid lg:grid-cols-2 gap-3 mt-4'}>
                        <div>
                          <Label>
                            Expiration Date{' '}
                            <span className={'text-red-500'}>*</span>
                          </Label>
                          <CardExpiry
                            placeholder="MM / YY"
                            className={cn(inputStyles())}
                          />
                        </div>
                        <div>
                          <Label>
                            CVV <span className={'text-red-500'}>*</span>
                          </Label>
                          <CardCVV
                            placeholder="CVV"
                            className={cn(inputStyles())}
                          />
                        </div>
                      </div>
                    </CardComponent>
                    <div>
                      <p className={'font-medium'}>Billing Details</p>
                    </div>
                    <div className={'flex flex-col gap-3'}>
                      <FormField
                        control={form.control}
                        name="billingAddress.country"
                        render={({ field }) => (
                          <FormItem>
                            <Label>
                              Country <span className={'text-red-500'}>*</span>
                            </Label>
                            <FormControl>
                              <Select
                                onValueChange={field.onChange}
                                defaultValue={field.value}
                              >
                                <FormControl>
                                  <SelectTrigger>
                                    <SelectValue placeholder="Select country" />
                                  </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                  {countries &&
                                    countries.map((item) => (
                                      <SelectItem
                                        key={item.name}
                                        value={item.code2}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                </SelectContent>
                              </Select>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="billingAddress.line1"
                        render={({ field }) => (
                          <FormItem>
                            <Label>
                              Address <span className={'text-red-500'}>*</span>
                            </Label>
                            <FormControl>
                              <Input placeholder="Line 1" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="billingAddress.line2"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input placeholder="Line 2" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="billingAddress.city"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input placeholder="City" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <div className={'grid grid-cols-2 gap-3'}>
                        {countries &&
                        form.getValues('billingAddress.country') &&
                        getStatesByCode2(
                          form.watch('billingAddress.country') ?? '',
                        ).length < 1 ? (
                          <FormField
                            control={form.control}
                            name="billingAddress.state"
                            render={({ field }) => (
                              <FormItem>
                                <FormControl>
                                  <Input placeholder="State" {...field} />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        ) : (
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <FormField
                                control={form.control}
                                name="billingAddress.state"
                                render={({ field }) => (
                                  <FormItem>
                                    <FormControl>
                                      <Select
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        disabled={
                                          !form.getValues(
                                            'billingAddress.country',
                                          )
                                        }
                                      >
                                        <FormControl>
                                          <SelectTrigger>
                                            <SelectValue placeholder="State" />
                                          </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                          {countries &&
                                            form.getValues(
                                              'billingAddress.country',
                                            ) &&
                                            getStatesByCode2(
                                              form.watch(
                                                'billingAddress.country',
                                              ) ?? '',
                                            ).map((item) => (
                                              <SelectItem
                                                key={item.name}
                                                value={item.name}
                                              >
                                                {item.name}
                                              </SelectItem>
                                            ))}
                                        </SelectContent>
                                      </Select>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </TooltipTrigger>
                            {!form.getValues('billingAddress.country') && (
                              <TooltipContent>
                                <p>Select a country first to enable this</p>
                              </TooltipContent>
                            )}
                          </Tooltip>
                        )}
                        <FormField
                          control={form.control}
                          name="billingAddress.zip"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Input placeholder="Zip code" {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={'mt-6'}>
                    <Button
                      disabled={isSubmitting || isPending}
                      type={'submit'}
                      className="w-full"
                      loading={isSubmitting || isPending}
                    >
                      {isSubmitting || isPending
                        ? 'Processing...'
                        : 'Start Trial'}
                    </Button>
                    <div>
                      <span
                        className={
                          'flex justify-center items-center gap-2 text-center mt-1.5 text-sm'
                        }
                      >
                        <Lock className={'w-5'} />
                        <span>Secure Checkout by Chargebee</span>
                      </span>
                    </div>
                  </div>
                  <div className={'mt-6'}>
                    <p
                      className={'text-xs font-light text-thememutedforeground'}
                    >
                      {`After your free trial ends, you will be charged ${selectedPlan.price}
                          per month starting ${nextDueDate}. You can cancel
                          your subscription before your free trial ends.`}
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </Form>
        )}
      </div>
    </div>
  );
}
