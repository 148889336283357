import { ArrowUpRight } from "@mynaui/icons-react";
import { Link } from "@tanstack/react-router";

type BrandCollectionDataProps = {
  data: BrandDataProps[];
};

export type BrandDataProps = {
  brandImage: string | null;
  brandName: string;
  fbPageId: string;
  totalAdCount: number;
  totalEmailCount: number;
  totalLanderCount: number;
  totalActiveAdCount: number;
  brandId?: string;
};

export const BrandsGridView = ({ data }: BrandCollectionDataProps) => {
  return (
    <div className={"grid grid-cols-1 lg:grid-cols-2 gap-x-6"}>
      {data &&
        data.map((item) => (
          <Link
            key={item.brandName}
            to={`/feeds/brands/$brandID`}
            params={{ brandID: item.fbPageId }}
          >
            <div
              className={
                "px-2 py-3 flex justify-between items-start gap-5 w-full border-t border-themeborder dark:border-darkthemeinput hover:bg-themeaccent dark:hover:bg-themeaccentdark"
              }
            >
              <div>
                <div className={"flex gap-3 lg:items-center"}>
                  <img
                    className={"rounded-xl w-12 h-12 bg-themeaccent"}
                    src={item.brandImage ? item.brandImage : "/bg_gradient.jpg"}
                    alt={item.brandName}
                  />
                  <div>
                    <p className={"text-base lg:text-lg"}>{item.brandName}</p>
                    <div className={"flex gap-3"}>
                      {item.totalAdCount > 0 ? (
                        <span className={"text-xs text-thememutedforeground"}>
                          {item.totalAdCount} Total Ads
                        </span>
                      ) : null}
                      {item.totalEmailCount > 0 ? (
                        <span className={"text-xs text-thememutedforeground"}>
                          {item.totalEmailCount} Total Emails
                        </span>
                      ) : null}
                      {item.totalLanderCount > 0 ? (
                        <span className={"text-xs text-thememutedforeground"}>
                          {item.totalLanderCount} Total Landers
                        </span>
                      ) : null}
                      {item.totalActiveAdCount > 0 ? (
                        <span className={"text-xs text-thememutedforeground"}>
                          {item.totalActiveAdCount} Ads Running Now
                        </span>
                      ) : null}
                    </div>
                    <div
                      className={
                        "flex lg:hidden mt-[0.625rem] items-center gap-10"
                      }
                    >
                      <div
                        className={
                          "flex gap-3 items-start text-thememutedforeground hover:underline"
                        }
                      >
                        <span className={"text-xs"}>View Ads</span>
                        <ArrowUpRight className={"w-5 h-5"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"hidden lg:flex items-center gap-2"}>
                <div
                  className={
                    "flex gap-2 items-center text-thememutedforeground hover:underline"
                  }
                >
                  <span className={"text-xs"}>View Ads</span>
                  <ArrowUpRight className={"w-5 h-5"} />
                </div>
              </div>
            </div>
          </Link>
        ))}
    </div>
  );
};
